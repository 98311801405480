<template>
    <v-container fluid>
        <CustomSidebar></CustomSidebar>
        <CustomAppbar></CustomAppbar>
        <v-row class="pl-10">
            <v-col lg="12">
                <H2>Edit Email Template</H2>
            </v-col>
            <ValidationObserver v-slot="{ handleSubmit }" ref="newtemplateform">
                <v-form @submit.prevent="handleSubmit(updateEmailTemplate)" ref="newtemplateform">
                    <v-col cols="12" lg="12">
                        <ValidationProvider name="Subject" rules="required|max:150" v-slot="{ errors }">
                            <v-text-field name="Subject" solo v-model="email_template.subject" label="Subject"
                                :error-messages="errors"></v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col lg="12" md="12" cols="12" class="mt-0">
                        <ValidationProvider name="template" rules="required|max:2000" v-slot="{ errors }">
                            <tiptap-vuetify v-model="email_template.template_content" :extensions="extenstions"
                                label="template" :error-messages="errors" />
                        </ValidationProvider>
                        <v-btn type="submit" class="orange darken-3 white--text font-weight-bold mt-10"
                            :disabled="email_template.template_content.length < 10">Update</v-btn>
                    </v-col>


                </v-form>
            </ValidationObserver>

        </v-row>
        <v-snackbar v-model="display_snackbar" color="primary" top> {{ message }}</v-snackbar>
    </v-container>
</template>
    
<script>
 /* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import axios from 'axios';
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
    mounted() {
        this.getEmailTemplate();
    },
    watch: {

    },
    methods: {
        getEmailTemplate:function(){
            axios.get(`${process.env.VUE_APP_API_URL}/getemailtemplate/`+this.$route.params.id).then((response) => {
                if (response.status == 200) {
                    console.log(response.data);
                    this.email_template = response.data.template;
                }
            }).catch((error) => {
                if (error.response.status == 422) {
                    this.$refs.newtemplateform.setErrors({
                        'template': error.response.data.errors.title

                    });
                    this.message = "Error Occur While Saving Template"
                    this.display_snackbar = true
                }
            }).finally(() => {

            });
        },
        updateEmailTemplate: function () {
            axios.post(`${process.env.VUE_APP_API_URL}/updateEmailTemplate`, this.email_template).then((response) => {
                if (response.status == 200) {
                    this.message = "Template Updated Sucessfully";
                    this.display_snackbar = true;
                }
            }).catch((error) => {
                if (error.response.status == 422) {
                    this.$refs.newtemplateform.setErrors({
                        'template': error.response.data.errors.title

                    });
                    this.message = "Error Occur While Saving Template"
                    this.display_snackbar = true
                }
            }).finally(() => {

            });
        }
    },
    data: () => ({
        message: "",
        display_snackbar: false,
        extenstions: [
            // History,
            // Blockquote,
            // Link,
            // Underline,
            // Strike,
            // Italic,
            // Bold,
            // [Heading, {
            //     options: {
            //         levels: [1, 2, 3]
            //     }
            // }],
            // Paragraph,
            // BulletList,
            // OrderedList,
            // ListItem,
            // HardBreak,
            // HorizontalRule
        ],
        email_template: {
            template_content: '',
            subject:'',
            id:'',
            
        }


    }),
    components: { CustomSidebar, CustomAppbar, TiptapVuetify, ValidationObserver, ValidationProvider }
}

</script>
  
   
  