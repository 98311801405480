<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>
    <v-skeleton-loader elevation="10" class="mb-5" type="card-heading,article, actions"
      v-if="data_loader"></v-skeleton-loader>
    <div v-else>
      <v-row class="pl-10">
        <!-- <v-col lg="12">
          <span class="red--text darken-3 font-weight-bold">Note  </span>
        </v-col> -->
        <v-col>
          <h3>About Us CEO Letter</h3>
        </v-col>
        <v-col lg="12" cols="12">
          <ValidationObserver>
            <v-form @submit.prevent="updateCeoLetter()" ref="ceoletterform">
              <v-row>
                <v-col lg="5" cols="12" md="6" sm="12">
                  <v-card max-height="1000px" class="overflow-auto elevation-3">
                    <ValidationProvider name="Terms and Condition" rules="required" v-slot="{ errors }">
                      <tiptap-vuetify v-model="ceo_letter.body" :extensions="extenstions" label="Terms and Condition"
                        :error-messages="errors" />
                    </ValidationProvider>
                  </v-card>
                  <v-col lg="12" cols="12">
                    <v-btn type="submit" class="orange darken-3 white--text font-weight-bold mt-10"
                      :disabled="false">Update
                      Ceo Letter</v-btn>
                  </v-col>
                </v-col>
                <v-col lg="6" class="" cols="12" md="6" sm="12">
                  <v-row>
                    <v-col lg="6" :class="{ 'pt-14': $vuetify.breakpoint.mdAndUp, 'pt-5': $vuetify.breakpoint.mdAndDown }"
                      cols="6" md="3" sm="6">
                      <v-img :src="ceo_images[0]['uri']" :height="imageHeight" width="400"
                        class="rounded-lg elevation-20"></v-img>
                      <ValidationObserver ref="updateCeoImage">
                        <v-form @submit.prevent="updateCeoImage(ceo_image_one, 'ceo_banner', 0, ceo_images[0])"
                          ref="updateCeoImage">
                          <span class="text-caption red--text text-wrap font-weight-bold"><br>Note: Images should be of minimum 376 * 800 pixel</span>
                          <ValidationProvider name="Upload Banner" rules="required|image" v-slot="{ errors }">
                            <v-file-input class="mt-5" label="Upload Banner" v-model="ceo_image_one" accept="image/*"
                              clearable :error-messages="errors"></v-file-input>
                          </ValidationProvider>
                        
                          <v-btn block class="amber darken-4 font-weight-bold white--text" type="submit">Update</v-btn>
                        </v-form>
                      </ValidationObserver>
                    </v-col>
                    <v-col lg="6" cols="6">
                      <v-row>
                        <v-col lg="12" class="" cols="12">
                          <v-img class="rounded-lg elevation-15" :height="imageHeightOne" width="500"
                            :src="ceo_images[1]['uri']"></v-img>

                          <ValidationObserver ref="updateCeoImage">
                            <v-form @submit.prevent="updateCeoImage(ceo_image_two, 'ceo_banner', 1, ceo_images[1])"
                              ref="updateCeoImage">
                              <span class="text-caption red--text text-wrap font-weight-bold"><br>Note: Images should be of minimum 376 * 400 pixel</span>
                              <ValidationProvider name="Upload Banner" rules="required|image" v-slot="{ errors }">
                                <v-file-input class="mt-5" label="Upload Banner" v-model="ceo_image_two" accept="image/*"
                                  clearable :error-messages="errors"></v-file-input>
                              </ValidationProvider>
                              
                              <v-btn block class="amber darken-4 font-weight-bold white--text"
                                type="submit">Update</v-btn>
                            </v-form>
                          </ValidationObserver>

                        </v-col>
                        <v-col lg="12" class="" cols="12">
                          <v-img class="rounded-lg elevation-15" :height="imageHeightOne" width="500"
                            :src="ceo_images[2]['uri']"></v-img>
                          <ValidationObserver ref="updateCeoImage">
                            <v-form @submit.prevent="updateCeoImage(ceo_image_three, 'ceo_banner', 2, ceo_images[2])"
                              ref="updateCeoImage">
                              <span class="text-caption red--text text-wrap font-weight-bold"><br>Note: Images should be of minimum 376 * 400 pixel</span>
                              <ValidationProvider name="Upload Banner" rules="required|image" v-slot="{ errors }">
                                <v-file-input class="mt-5" label="Upload Banner" v-model="ceo_image_three"
                                  accept="image/*" clearable :error-messages="errors">
                                 
                                </v-file-input>
                                  
                              </ValidationProvider>
                             
                              <v-btn block class="amber darken-4 font-weight-bold white--text"
                                type="submit">Update</v-btn>
                            </v-form>
                          </ValidationObserver>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>

              </v-row>
            </v-form>
          </ValidationObserver>
        </v-col>

      </v-row>
      <v-row class="pl-10">
        <v-col cols="12">
          <h3>About Kesari</h3>
        </v-col>
        <v-col lg="6" class="" cols="12" md="6" sm="12">
          <v-row>
            <v-col lg="6" :class="{ 'pt-14': $vuetify.breakpoint.mdAndUp, 'pt-5': $vuetify.breakpoint.mdAndDown }"
              cols="6" md="3" sm="6">
              <v-img :src="kesari_images[0]['uri']" :height="imageHeight" width="400"
                class="rounded-lg elevation-20"></v-img>
              <ValidationObserver ref="updateKesariImage">
                <v-form @submit.prevent="updateKesariImage(kesari_image_one, 'kesari_banner', 0, kesari_images[0])"
                  ref="updateKesariImage">
                  <span class="text-caption red--text text-wrap font-weight-bold"><br>Note: Images should be of minimum 376 * 800 pixel</span>
                  <ValidationProvider name="Upload Banner" rules="required|image" v-slot="{ errors }">
                    <v-file-input class="mt-5" label="Upload Banner" v-model="kesari_image_one" accept="image/*" clearable
                      :error-messages="errors"></v-file-input>
                  </ValidationProvider>
                  <v-btn block class="amber darken-4 font-weight-bold white--text" type="submit">Update</v-btn>
                </v-form>
              </ValidationObserver>
            </v-col>
            <v-col lg="6" cols="6">
              <v-row>
                <v-col lg="12" class="" cols="12">
                  <v-img class="rounded-lg elevation-15" :height="imageHeightOne" width="500"
                    :src="kesari_images[1]['uri']"></v-img>

                  <ValidationObserver ref="updateKesariImage">
                    <v-form @submit.prevent="updateKesariImage(kesari_image_two, 'kesari_banner', 1, kesari_images[1])"
                      ref="updateKesariImage">
                      <span class="text-caption red--text text-wrap font-weight-bold"><br>Note: Images should be of minimum 376 * 400 pixel</span>
                      <ValidationProvider name="Upload Banner" rules="required|image" v-slot="{ errors }">
                        <v-file-input class="mt-5" label="Upload Banner" v-model="kesari_image_two" accept="image/*"
                          clearable :error-messages="errors"></v-file-input>
                      </ValidationProvider>
                      <v-btn block class="amber darken-4 font-weight-bold white--text" type="submit">Update</v-btn>
                    </v-form>
                  </ValidationObserver>

                </v-col>
                <v-col lg="12" class="" cols="12">
                  <v-img class="rounded-lg elevation-15" :height="imageHeightOne" width="500"
                    :src="kesari_images[2]['uri']"></v-img>
                  <ValidationObserver ref="updateKesariImage">
                    <v-form @submit.prevent="updateKesariImage(kesari_image_three, 'kesari_banner', 2, kesari_images[2])"
                      ref="updateKesariImage">
                      <span class="text-caption red--text text-wrap font-weight-bold"><br>Note: Images should be of minimum 376 * 400 pixel</span>
                      <ValidationProvider name="Upload Banner" rules="required|image" v-slot="{ errors }">
                        <v-file-input class="mt-5" label="Upload Banner" v-model="kesari_image_three" accept="image/*"
                          clearable :error-messages="errors"></v-file-input>
                      </ValidationProvider>
                      <v-btn block class="amber darken-4 font-weight-bold white--text" type="submit">Update</v-btn>
                    </v-form>
                  </ValidationObserver>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="5" cols="12">
          <ValidationObserver>
            <v-form @submit.prevent="updateAboutKesari()" ref="aboutkesariform">
              <v-col lg="12" cols="12">
                <v-card max-height="1000px" class="overflow-auto elevation-3">
                  <ValidationProvider name="About Kesari" rules="required" v-slot="{ errors }">
                    <tiptap-vuetify v-model="about_kesari.body" :extensions="extenstions" label="About Kesari"
                      :error-messages="errors" />
                  </ValidationProvider>
                </v-card>
              </v-col>
              <v-col lg="4" cols="12">
                <v-btn type="submit" class="orange darken-3 white--text font-weight-bold mt-10" :disabled="false">Update
                  About Kesari
                </v-btn>
              </v-col>
            </v-form>
          </ValidationObserver>
        </v-col>
      </v-row>
    </div>


    <!-- Banner Image Dialogs -->
    <v-snackbar v-model="display_snackbar" color="primary" class="font-weight-bold"> {{ message }}</v-snackbar>
  </v-container>
</template>
    
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TiptapVuetify } from 'tiptap-vuetify'
import axios from 'axios';


export default {
  computed: {
    imageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": return "400px";
        case "sm": return "400px";
        case "md": return "600px";
        case "lg": return "800px";
        case "xl": return "800px";
        default: return "800px";
      }
    },
    imageHeightOne() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": return "200px";
        case "sm": return "200px";
        case "md": return "300px";
        case "lg": return "400px";
        case "xl": return "400px";
        default: return "400px";
      }
    },
  },
  components: {
    CustomAppbar,
    CustomSidebar,
    ValidationObserver,
    ValidationProvider,
    TiptapVuetify
  },
  mounted() {
    this.getAboutUsPageContent();
  },
  methods: {
    getAboutUsPageContent: function () {
      this.data_loader = true;
      axios.get(`${process.env.VUE_APP_API_URL}/getaboutuspagecontent`).then((response) => {
        if (response.status == 200) {
          this.ceo_letter = response.data.ceo_letter;
          this.about_kesari = response.data.about_kesari;
          this.ceo_images = response.data.ceo_images;
          this.kesari_images = response.data.kesari_images;
        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message = "Error Occur While Getting About Us Page Content"
          this.display_snackbar = true
        }
      }).finally(() => {
        this.data_loader = false;
      });
    },
    updateCeoLetter: function () {
      axios.post(`${process.env.VUE_APP_API_URL}/updateceoletter`, this.ceo_letter).then((response) => {
        if (response.status == 200) {
          this.message = "CEO Letter Updated Successfully";
          this.display_snackbar = true;
        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message = "Error Occur While Saving CEO Letter"
          this.display_snackbar = true
        }
      }).finally(() => {

      });
    },
    updateAboutKesari: function () {
      axios.post(`${process.env.VUE_APP_API_URL}/updateaboutkesari`, this.about_kesari).then((response) => {
        if (response.status == 200) {
          this.message = "About Kesari Updated Successfully";
          this.display_snackbar = true;
        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message = "Error Occur While Saving About Kesari"
          this.display_snackbar = true
        }
      }).finally(() => {

      });
    },

    updateCeoImage: function (image, type, position, current_image) {
      this.ceo_image_uploader = true;
      this.ceo_image_form.append('image', image);
      this.ceo_image_form.append('position', position)
      this.ceo_image_form.append('type', type)
      this.ceo_image_form.append('current_image', current_image);

      axios.post(`${process.env.VUE_APP_API_URL}/updateaboutusimages`, this.ceo_image_form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.status = 200) {
          this.ceo_image_one = "";
          this.ceo_image_two = "";
          this.ceo_image_three = "";
          this.message = "Image Uploaded Successfully",
            this.display_snackbar = true;
          this.ceo_image_uploader = false;
          this.getAboutUsPageContent();
        }
      }).catch((error) => {
        console.log(error);
        if (error.response.status == 422) {
          this.$refs.updateCeoImage.setErrors({
            "Upload Banner": error.response.data.errors.image,
          });
        }
        if (error.response.status == 419) {

          this.ceo_image_form.delete('image', image);
          this.ceo_image_form.delete('position', position)
          this.ceo_image_form.delete('type', type)
          this.ceo_image_form.delete('current_image', current_image);
          this.ceo_image_uploader = false;

        }
        this.message = "Error Occur while uploading Images";
      }).finally(() => {
        this.ceo_image_uploader = false;
      })

    },
    updateKesariImage: function (image, type, position, current_image) {
      this.ceo_image_uploader = true;
      this.kesari_image_form.append('image', image);
      this.kesari_image_form.append('position', position)
      this.kesari_image_form.append('type', type)
      this.kesari_image_form.append('current_image', current_image);

      axios.post(`${process.env.VUE_APP_API_URL}/updateaboutusimages`, this.kesari_image_form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.status = 200) {
          this.kesari_image_one = "";
          this.kesari_image_two = "";
          this.kesari_image_three = "";
          this.message = "Image Uploaded Successfully",
            this.display_snackbar = true;
          this.ceo_image_uploader = false;
          this.getAboutUsPageContent();
        }
      }).catch((error) => {
        console.log(error);
        if (error.response.status == 422) {
          this.$refs.updateCeoImage.setErrors({
            "Upload Banner": error.response.data.errors.image,
          });
        }
        if (error.response.status == 419) {

          this.ceo_image_form.delete('image', image);
          this.ceo_image_form.delete('position', position)
          this.ceo_image_form.delete('type', type)
          this.ceo_image_form.delete('current_image', current_image);
          this.ceo_image_uploader = false;

        }
        this.message = "Error Occur while uploading Images";
      }).finally(() => {
        this.ceo_image_uploader = false;
      })

    }

  },
  data: () => ({
    data_loader: false,
    ceo_image_uploader: false,
    ceo_image_one: {},
    ceo_image_two: {},
    ceo_image_three: {},
    kesari_image_one:{},
    kesari_image_two:{},
    kesari_image_three:{},
    ceo_images: [],
    kesari_images: [],
    banner_upload_loader: false,
    ceo_image_form: new FormData,
    kesari_image_form: new FormData,

    update_loader: false,
    get_loader: false,
    extenstions: [],
    message: "",
    display_snackbar: false,
    ceo_letter: {
      id: '',
      page: 'about_us',
      module: '',
      title: '',
      body: '',
      is_publish: false,
    },
    about_kesari: {
      id: '',
      page: 'about_us',
      module: '',
      title: '',
      body: '',
      is_publish: false,
    }

  }),
}

</script>