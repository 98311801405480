<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>
    <v-row>
      <v-col lg="12">
        <H2>MotherTongue</H2>
      </v-col>
      <!--Filters-->
      <v-col lg="9">
        <v-card>
          <v-card-title>
            Add New Religions
          </v-card-title>
          <v-card-text>

            <ValidationObserver ref="newmothertongueform">
              <v-form @submit.prevent="addNewMotherTongue" ref="newmothertongueform">
                <v-row >
                  <v-col lg="4" cols="12">
                    <ValidationProvider name="Mother Tongue Name" rules="required|max:300" v-slot="{ errors }">
                      <v-text-field name="Mother Tongue Name" v-model="new_mother_tongue.name" label="Mother Tongue Name" outlined
                        :error-messages="errors"></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col lg="4" cols="12" >
                    <ValidationProvider name="Enable Mother Tongue" rules="required" v-slot="{ errors }">
                      <v-checkbox :error-messages="errors" v-model="new_mother_tongue.is_active"
                        label="Enable Mother Tongue" class="ma-auto pt-4"></v-checkbox>
                    </ValidationProvider>
                  </v-col>
                  <v-col lg="4" cols="12">
                            <v-btn type="submit" class="orange darken-3 white--text font-weight-bold mt-1">Add New Mother TOngue</v-btn>
                        </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>

          </v-card-text>
        </v-card>

      </v-col>
      <v-col lg="12">
        <v-row justify="end">
          <!-- <v-btn color="red darken-5" class="white--text ma-2"
            @click="(dialogs.upload_city = !dialogs.upload_city)">
            <v-icon left dark>
              mdi-cloud-upload
            </v-icon>
            Upload MotherTongue
          </v-btn> -->
          <v-btn color="red darken-5" class="white--text ma-2" @click="dialogs.filter_city = true">
            <v-icon v-if="this.$route.query.filters" left dark>mdi-filter-check</v-icon>
            <v-icon v-else left dark>mdi-filter</v-icon>
            <span>Filter List</span>
          </v-btn>

        </v-row>
      </v-col>
      <v-col lg="12">
        <v-card>
          <v-data-table :headers="members_headers" :items="mother_tongue_list" class="index_header"
            :loading="loaders.list_progress" :server-items-length="total_items" :footer-props="{
  showFirstLastPage: false,
  firstIcon: 'mdi-arrow-collapse-left',
  itemsPerPageOptions: [5,10,15,20,50,100],
}" :options.sync="pagination" >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn link text icon :href="item+'#'">
                <v-icon medium color="primary" class="ma-2">
                mdi-eye
              </v-icon>
              </v-btn>
              <v-btn link text icon >
                <v-icon medium color="primary" class="ma-2">
                mdi-pencil
              </v-icon>
            </v-btn>
            </template>
            <template v-slot:[`item.is_active`]="{ item }">
              <v-chip v-if="item.is_active == 1" small color="green" class="white--text font-weight-bold" @click="updateStatus(item)">Active</v-chip>
              <v-chip v-else small color="red" class="white--text font-weight-bold" @click="updateStatus(item)">Disabled</v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!--Dialogs-->

    <!--Filters Members Diaglog-->
    <v-dialog width="500" v-model="dialogs.filter_city">
      <v-form id="filterList" data-vv-scope="filterList" @submit.prevent="filterList">
        <v-card>
          <v-card-title class="text-h5 indigo white--text">
            Filters MotherTongue
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col lg="12">
                <v-text-field dense outlined label="Name" v-model="filters.name"></v-text-field>
                <v-select dense outlined label="Status" v-model="filters.status" :items="status"></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="button ma-1" color="primary" type="submit" :loading="loaders.get_filter_data"
               :disabled="loaders.get_filter_data">Apply Filters</v-btn>
            <v-btn class="button ma-1" color="primary" @click="dialogs.filter_city = false">cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- upload Members dialog-->
    <v-dialog width="500" v-model="dialogs.upload_city">
      <v-card loading>
        <v-card-title class="text-h5 indigo white--text">
          Upload MotherTongue
        </v-card-title>

        <v-card-text>
          <v-file-input accept="image/*" label="Upload File"></v-file-input>
          <span class="pa-4">Click Here Download Template</span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false">
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="display_snackbar" color="primary"> {{ message }}</v-snackbar>
  </v-container>
</template>
  
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';


export default {
  components: {
    CustomAppbar,
    CustomSidebar,
    ValidationObserver,
    ValidationProvider
},
  watch: {
    pagination: {
      handler() {
        this.getAllMotherTongues();
      }
    }
  },
  mounted() {
    console.log("allmembers")
  },
  data: () => ({
    display_snackbar:false,
    message:'',
    new_mother_tongue: {
      name: '',
      is_active: false,
    },
    dialogs: {
      upload_city: false,
      filter_city: false,
      get_filter_data: false,
    },
    members_headers: [
      {
        text: 'Mother Tongue',
        align: 'start',
        sortable: true,
        value: 'text'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: true,
        value: 'is_active'
      },
      // {
      //   text: 'Actions',
      //   align: 'start',
      //   sortable: false,
      //   value: 'actions'
      // }
    ],
    status: ['All','Active', 'Disabled'],
    mother_tongue_list: [],
    total_items: 10,
    pagination: {},
    filters: {
      name: "",
      status: "",
    },
    loaders: {
      list_progress: false,
    }

  }),
  methods: {
    addNewMotherTongue:function(){
      axios.post(`${process.env.VUE_APP_API_URL}/addnewreligion`, this.new_mother_tongue).then((response) => {
        if (response.status == 200) {
          this.message = "Mother Tongue Added Successfully"
          this.display_snackbar = true;
          this.getAllMotherTongues();
        }
      }).catch((error) => {
        console.log(error);
        if (error.response.status == 422) {
                    console.log(error.response);
                    this.$refs.newmothertongueform.setErrors({
                        "Mother Tongue Name": error.response.data.errors.name,
                        "Enable Mother Tongue": error.response.data.errors.is_active, 
                    });
                  
                }
      }).finally(() => {
        this.new_mother_tongue.is_active = false;
        this.new_mother_tongue.name = '';
      });
    },
    updateStatus: function (mother_tongue) {
    
    axios.post(`${process.env.VUE_APP_API_URL}/updatemothertonguestatus`, mother_tongue).then((response) => {
      if (response.status == 200) {
      
        this.message = "Mother Tongue Status Updated Successfully"
        this.display_snackbar = true;
        this.getAllMotherTongues();
      }
    }).catch((error) => {
      console.log(error);
      this.message = "Mother Tongue Status Updating Failed"
        this.display_snackbar = true;
    }).finally(() => {
    
    });
  },
    getAllMotherTongues: function () {
      this.loaders.list_progress = true;
      this.pagination.filters = Object.assign({}, this.filters);
      axios.get(`${process.env.VUE_APP_API_URL}/GetAllMotherTongues`, { params: this.pagination }).then((response) => {
        if (response.status == 200) {
          
          this.total_items = response.data.meta.total;
          this.mother_tongue_list = response.data.data;
        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loaders.list_progress = false;
      });
    },
    filterList: function () {
     this.getAllMotherTongues();
     this.dialogs.filter_city = false;
    },
  }
}

</script>

 
