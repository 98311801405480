<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>

    <v-row>
      <v-col lg="12">
        <H2>Blogs</H2>
      </v-col>
      <!-- <v-col lg="7">
          <v-card rounded elevation="1">
            <v-card-title>
              Blog Statistics
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col lg="3">
                  <v-list-item>
                    <v-list-avatar>
                      <v-avatar color="red darken-5" size="42" class="elevation-3 me-3 rounded" tile>
                        <v-icon dark>
                          mdi-chart-timeline-variant-shimmer
                        </v-icon>
                      </v-avatar>
                    </v-list-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-caption">
                        Total Blog View Count
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-h6">
                        245K
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col lg="3">
                  <v-list-item>
                    <v-list-avatar>
                      <v-avatar color="red darken-5" size="42" class="elevation-3 ma-3 rounded" tile>
                        <v-icon dark>
                          mdi-chart-timeline-variant-shimmer
                        </v-icon>
                      </v-avatar>
                    </v-list-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-caption">
                        Published Blogs
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-h6">
                        24K
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col lg="3">
                  <v-list-item>
                    <v-list-avatar>
                      <v-avatar color="red darken-5" size="42" class="elevation-3 ma-3 rounded" tile>
                        <v-icon dark>
                          mdi-chart-timeline-variant-shimmer
                        </v-icon>
                      </v-avatar>
                    </v-list-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-caption">
                        Avg. Blog View Count
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-h6">
                        245K
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col lg="3">
                  <v-list-item>
                    <v-list-avatar>
                      <v-avatar color="red darken-5" size="42" class="elevation-3 ma-3 rounded" tile>
                        <v-icon dark>
                          mdi-chart-timeline-variant-shimmer
                        </v-icon>
                      </v-avatar>
                    </v-list-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-caption">
                        Daily Blog View Count
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-h6">
                        245K
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col> -->
      <!-- <v-col lg="8">
          <v-card class="pa-4">
            <v-row>
              <v-col lg="12">
                <h3>Filters</h3>
              </v-col>
              <v-col lg="4">
                <v-text-field outlined label="Title"></v-text-field>
              </v-col>
              <v-col lg="4">
                <v-text-field outlined label="published Date"></v-text-field>
              </v-col>
              <v-col lg="4">
                <v-text-field outlined label="Status"></v-text-field>
              </v-col>
            </v-row>
          </v-card>
        </v-col> -->
      <v-col lg="12">
        <v-row justify="end">

          <router-link :to="{ name: 'newBlog' }">
            <v-btn color="red darken-5" class="white--text ma-2">
              <v-icon left dark>
                mdi-account-plus
              </v-icon>
              Add New Blog
            </v-btn>
          </router-link>
          <!-- <v-btn color="red darken-5" class="white--text ma-2">
            <v-icon left dark>
              mdi-file-export
            </v-icon>
            Export
          </v-btn> -->

        </v-row>
      </v-col>
      <v-col lg="12">
        <v-card>
          <v-data-table :headers="members_headers" :items="blogs">
            <template v-slot:[`item.published`]="{ item }">
              <v-chip small color="green" class="white--text font-weight-bold" v-if="item.published">Published</v-chip>
              <v-chip small color="red" class="white--text font-weight-bold" v-else>Archive</v-chip>
            </template>
            <!-- <template v-slot:[`item.created_at`]="{ item }">
               {{ moment(item.created_at).format("DD-MM-YYYY")}}
            </template> -->
            <template v-slot:[`item.actions`]="{ item }">
              <router-link :to="{ name: 'editblog', params:{blog_id:item.id}}">
                <v-btn link  text icon>
                  <v-icon small color="primary" class="">
                    mdi-pencil
                  </v-icon>

                </v-btn>

              </router-link>
              <v-dialog v-model="delete_blog_dialog" width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn link text icon v-on="on" v-bind="attrs" @click="delete_blog_dialog = true">
                    <v-icon small color="red" class="ma-1">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline lighten-2" primary-title>
                    Confirmation
                  </v-card-title>

                  <v-card-text>
                    Are you sure you want to delete Blog. Once deleted it cannot be revert.
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="deleteBlog(item)" :loading="delete_blog_loader">
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="display_snackbar" color="primary" top> {{ message }}</v-snackbar>

  </v-container>
</template>
    
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import axios from 'axios';
import moment from 'moment';
export default {
  mounted() {

  },

  created() {
    this.getAllBlogs();
  },
  methods: {
    getAllBlogs: function () {
      this.blog_loader = true;
      axios.get(`${process.env.VUE_APP_API_URL}/GetAllBlogs`).then((response) => {

        if (response.status == 200) {
          this.blogs = response.data.blogs;

        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.blog_loader = false;
      });
    },

    deleteBlog: function (blog) {
      this.delete_blog_loader = true;
      axios.post(`${process.env.VUE_APP_API_URL}/deleteblog`, blog).then((response) => {

        if (response.status == 200) {
          this.message = "Blog Deleted Successfully";
          this.display_snackbar = true;

          this.getAllBlogs();
        }
      }).catch((error) => {
        console.log(error);
        this.message = "Error Occur While Saving Blog"
        this.display_snackbar = true
      }).finally(() => {
        this.delete_blog_loader = false;
        this.delete_blog_dialog = false;
      });



    }
  },
  data: () => ({
    delete_blog_dialog: false,
    delete_blog_loader: false,
    message: "",
    display_snackbar: false,
    members_headers: [

      {
        text: "Title",
        align: "start",
        filterable: true,
        value: "title"
      },
      {
        text: "Quote",
        align: "start",
        filterable: true,
        value: "quote"
      },
      {
        text: "Status",
        align: "start",
        filterable: true,
        value: "published"
      },
      {
        text: "Date Created",
        align: "start",
        filterable: true,
        value: "created_at"
      },
      {
        text: "Actions",
        align: "start",
        filterable: false,
        value: "actions"
      }
    ],
    blogs: [],
    blog_loader: false,
  }),
  components: { CustomAppbar, CustomSidebar }
}

</script>
  
   
  