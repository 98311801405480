<template>
    <v-container fluid>
        <CustomAppbar></CustomAppbar>
        <CustomSidebar></CustomSidebar>
        <v-row class="pa-10">
            <v-col lg="12">
                <h3>Update Employee</h3>
            </v-col>
            <v-col lg="9" cols="12">
                <v-card :loading="update_loading">
                    <ValidationObserver ref="updateEmployee" v-slot="{ handleSubmit }">
                        <v-form @submit.prevent="handleSubmit(updateEmployee)">
                            <v-card-text>
                                <v-row>
                                    <v-col cols="2">
                                        <ValidationProvider name="Role" rules="required" v-slot="{ errors }">
                                            <v-select name="Role" outlined v-model="employee.role" :items="boolean_status" item-text="text" item-value="value" label="Role"
                                                :error-messages="errors"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="5">
                                        <ValidationProvider name="First Name" rules="required|max:50" v-slot="{ errors }">
                                            <v-text-field name="First Name" outlined v-model="employee.first_name" label="First Name" 
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="5">
                                        <ValidationProvider name="Last Name" rules="required|max:50" v-slot="{ errors }">
                                            <v-text-field name="Last Name" outlined v-model="employee.last_name" label="Last Name" 
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="4">
                                        <ValidationProvider name="Email Address" rules="required|email" v-slot="{ errors }">
                                            <v-text-field name="Email Address" outlined v-model="employee.email" label="Email Address" 
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>   
                                    <v-col cols="4">
                                        <ValidationProvider name="Mobile Number" rules="required|numeric" v-slot="{ errors }">
                                            <v-text-field name="Mobile Number" outlined v-model="employee.mobile_number" label="Mobile Number" 
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>             
                                    <v-col cols="4">
                                        <ValidationProvider name="Password" rules="min:8|max:30" v-slot="{ errors }">
                                            <v-text-field name="Password" outlined v-model="employee.password" label="Password" 
                                                :error-messages="errors" type="password"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn type="submit" class="orange darken-3 white--text font-weight-bold">Submit</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar v-model="display_snackbar" color="primary"> {{ message }}</v-snackbar>
    </v-container>
</template>
<script>
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
export default {
    mounted() {
      this.getEmployeeDetails();
    },
    data: () => ({
       employee:{
        first_name:'',
        last_name:'',
        password:'',
        role:'staff',
        email:'',
        mobile_number:'',
       },
        boolean_status: [{ "text": "Staff", value: 'staff' },{ "text": "Admin", value: 'super-admin' }],

        message: "",
        display_snackbar: false,
        plan_loading: false,
        update_loading: false,
    }),
    methods: {

        getEmployeeDetails:function(){

            this.update_loading = true;
            axios.get(`${process.env.VUE_APP_API_URL}/getemployeedetails/`+this.$route.params.staff_id).then((response)=>{
                if(response.status == 200){
                    this.employee = response.data.employee;
                }
            }).catch((error)=>{
                console.log(error);
                this.message = "Error Occur while fetching employee details";
                this.display_snackbar = true;
            }).finally(()=>{
                this.update_loading = false;
            });
        },


        updateEmployee:function(){
            this.update_loading = true;
            axios.post(`${process.env.VUE_APP_API_URL}/updateemployee`,this.employee).then((response)=>{
                if(response.status == 200){
                    this.employee.first_name = '';
                this.employee.last_name = '';
                this.employee.role = 'staff';
                this.employee.password = '';
                this.employee.email = '';
                this.employee.mobile_number = '';
                    this.message = "Employee Updated Successfully"
                    this.display_snackbar = true;
                    this.getEmployeeDetails();
                }
            }).catch((error)=>{
                console.log(error);
                if (error.response.status == 422) {
                        this.$refs.updateEmployee.setErrors({
                            'First Name': error.response.data.errors.first_name,
                            'Last Name': error.response.data.errors.last_name,
                            'password': error.response.data.errors.password,
                            'Email Address': error.response.data.errors.email,
                            'role': error.response.data.errors.role,
                            'Mobile Number':error.response.data.errors.mobile_number,
                           
                            
                        });
                    }
            }).finally(()=>{
              
                this.update_loading = false;

            })
        },


    },
    components: { CustomAppbar, CustomSidebar, ValidationObserver, ValidationProvider }
}

</script>