<template>
    <v-container fluid>
        <CustomAppbar></CustomAppbar>
        <CustomSidebar></CustomSidebar>
        <v-row class="pl-10">
            <v-col lg="12">
                <H2>New Blog</H2>
            </v-col>
            <v-col lg="12">
                <ValidationObserver  ref="editBlogForm">
                    <!-- <v-form @submit.prevent="handleSubmit(addNewBlog)" ref="editBlogForm"> -->
                        <v-form @submit.prevent="addNewBlog" ref="editBlogForm">
                        <v-col lg="8" cols="12">
                            <ValidationProvider name="Title" rules="required|max:300" v-slot="{ errors }">
                                <v-text-field name="Title" solo v-model="blog.title" label="Title"
                                    :error-messages="errors"></v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col lg="8" cols="12">
                            <ValidationProvider name="Quote" rules="max:300" v-slot="{ errors }">
                                <v-text-field name="Quote" solo v-model="blog.quote" label="Quote"
                                    :error-messages="errors"></v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col lg="8" cols="12">
                            <ValidationProvider name="Author" rules="required|max:300" v-slot="{ errors }">
                                <v-text-field name="Author" v-model="blog.author_name" solo label="Author" :error-messages="errors"></v-text-field>
                            </ValidationProvider>
                        </v-col>
                        <v-col lg="10" cols="12">
                            <ValidationProvider name="Blog" rules="required" v-slot="{ errors }">
                                <tiptap-vuetify :extensions="extenstions" label="Blog" v-model="blog.blog"
                                    :error-messages="errors"></tiptap-vuetify>
                            </ValidationProvider>
                        </v-col>
                        <v-col lg="8" cols="12">
                            <ValidationProvider name="Publish This Blog" rules="required" v-slot="{ errors }">
                                <v-checkbox :error-messages="errors"  v-model="blog.published" label="Publish This Blog"></v-checkbox>
                            </ValidationProvider>
                        </v-col>
                        <v-col lg="8" cols="12">
                            <v-btn type="submit" class="orange darken-3 white--text font-weight-bold mt-10" :loading="blog_loader">Update</v-btn>
                        </v-col>
                    </v-form>
                </ValidationObserver>
            </v-col>
        </v-row>
        <v-snackbar v-model="display_snackbar" color="primary" top> {{ message }}</v-snackbar>
    </v-container>
</template>
<script>
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TiptapVuetify } from 'tiptap-vuetify'
import axios from 'axios';
export default {
    mounted() {
      
    },
    created() {
    },
    methods: {

      

        addNewBlog: function () {
            console.log("d");
            this.blog_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/addnewblog`, this.blog).then((response) => {
                if (response.status == 200) {
                    this.message = "Blog Added Successfully";
                    this.display_snackbar = true;
                    this.$router.push({name:'blogs'})
                }
            }).catch((error) => {
                if (error.response.status == 422) {
                    console.log(error.response);
                    this.$refs.editBlogForm.setErrors({
                        
                        "Title": error.response.data.errors.title,
                        "Quote": error.response.data.errors.quote,
                        "Author": error.response.data.errors.author_name,
                        "Blog": error.response.data.errors.blog,
                        "Publish This Blog": error.response.data.errors.published,
                    });
                    this.message = "Error Occur While Adding New Blog"
                    this.display_snackbar = true
                }
            }).finally(() => {
                this.blog_loader = false;
            });
        },
    },
    data: () => ({
        blog_loader:false,
        message: "",
        display_snackbar: false,
        extenstions: [],
        blog: {
            id: '',
            author_name: '',
            quote: '',
            blog: '',
            title: '',
            published: false,
        }
    }),
    components: { CustomAppbar, CustomSidebar, ValidationObserver, ValidationProvider, TiptapVuetify }
}


</script>