<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>
    <v-row>
      <v-col lg="12">
        <H2>Religions</H2>
      </v-col>
      <!--Filters-->
      <v-col lg="8">
        <v-card>
          <v-card-title>
            Add New Religions
          </v-card-title>
          <v-card-text>

            <ValidationObserver ref="newreligionform">
              <v-form @submit.prevent="addNewReligion" ref="newreligionform">
                <v-row >
                  <v-col lg="4" cols="12">
                    <ValidationProvider name="Religion Name" rules="required|max:300" v-slot="{ errors }">
                      <v-text-field name="Religion Name" v-model="new_religion.name" label="Religion Name" outlined
                        :error-messages="errors"></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col lg="3" cols="12" >
                    <ValidationProvider name="Enable Religion" rules="required" v-slot="{ errors }">
                      <v-checkbox :error-messages="errors" v-model="new_religion.is_active"
                        label="Enable Religion" class="ma-auto pt-4"></v-checkbox>
                    </ValidationProvider>
                  </v-col>
                  <v-col lg="4" cols="12">
                            <v-btn type="submit" class="orange darken-3 white--text font-weight-bold mt-1">Add New Religion</v-btn>
                        </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>

          </v-card-text>
        </v-card>

      </v-col>
      <v-col lg="12">
        <v-row justify="end">
          <!-- <v-btn color="red darken-5" class="white--text ma-2"
            @click="(dialogs.upload_religion = !dialogs.upload_religion)">
            <v-icon left dark>
              mdi-cloud-upload
            </v-icon>
            Upload Religions
          </v-btn> -->
          <v-btn color="red darken-5" class="white--text ma-2" @click="dialogs.filter_relgions = true">
            <v-icon v-if="this.$route.query.filters" left dark>mdi-filter-check</v-icon>
            <v-icon v-else left dark>mdi-filter</v-icon>
            <span>Filter List</span>
          </v-btn>

        </v-row>
      </v-col>
      <v-col lg="12">
        <v-card>
          <v-data-table :headers="members_headers" :items="religion_list" class="index_header"
            :loading="loaders.list_progress" :server-items-length="total_items" :footer-props="{
                showFirstLastPage: false,
                firstIcon: 'mdi-arrow-collapse-left',
                itemsPerPageOptions: [5, 10, 15, 20, 50, 100],
              }" :options.sync="pagination">
            <template v-slot:[`item.actions`]="{ item }">
              <!-- <v-dialog v-model="disable_religion_dialog" width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn link text icon v-on="on" v-bind="attrs" @click="disable_religion_dialog = true">
                    <v-icon small color="red" class="mr-2" v-if="item.is_active">
                  mdi-eye-remove
                </v-icon>
                <v-icon small color="red" class="mr-2" v-else>
                  mdi-eye
                </v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline lighten-2" primary-title>
                    Confirmation
                  </v-card-title>

                  <v-card-text v-if="item.is_active">
                    Are you sure you want to Disable Religion. It will not be Visible on Frontend along with subsequent castes. 
                  </v-card-text>
                  <v-card-text v-else>
                    Are you sure you want to Enable Religion. It will be Visible on Frontend along with subsequent castes. 
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="updateReligionStatus(item)" :loading="religion_status_loader">
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
              <v-btn link text icon v-if="item.registered_members == 0">
                <v-icon small color="red" class="">
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.is_active`]="{ item }">
              <v-chip v-if="item.is_active == 1" small color="green" class="white--text font-weight-bold"
                @click="updateReligionStatus(item)">Active</v-chip>
              <v-chip v-else small color="red" class="white--text font-weight-bold"
                @click="updateReligionStatus(item)">Disabled</v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!--Dialogs-->

    <!--Filters Members Diaglog-->
    <v-dialog width="500" v-model="dialogs.filter_relgions">
      <v-form id="filterList" data-vv-scope="filterList" @submit.prevent="filterList">
        <v-card>
          <v-card-title class="text-h5 indigo white--text">
            Filters Religions
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col lg="12">
                <v-text-field dense outlined label="Name" v-model="filters.name"></v-text-field>
                <v-select dense outlined label="Status" v-model="filters.status" :items="status"></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="button ma-1" color="primary" type="submit" :loading="loaders.get_filter_data"
              :disabled="loaders.get_filter_data">Apply Filters</v-btn>
            <v-btn class="button ma-1" color="primary" @click="dialogs.filter_relgions = false">cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- upload Members dialog-->
    <v-dialog width="500" v-model="dialogs.upload_religion">
      <v-card loading>
        <v-card-title class="text-h5 indigo white--text">
          Upload Religions
        </v-card-title>

        <v-card-text>
          <v-file-input accept="image/*" label="Upload File"></v-file-input>
          <span class="pa-4">Click Here Download Template</span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false">
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="display_snackbar" color="primary"> {{ message }}</v-snackbar>
  </v-container>
</template>
  
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';


export default {
  components: {
    CustomAppbar,
    CustomSidebar,
    ValidationObserver,
    ValidationProvider
  },
  watch: {
    pagination: {
      handler() {
        this.getAllReligions();
      }
    }
  },
  mounted() {

  },
  data: () => ({
    display_snackbar:false,
    message:'',
    new_religion: {
      name: '',
      is_active: false,
    },
    disable_religion_dialog: false,
    religion_status_loader: false,
    dialogs: {
      upload_religion: false,
      filter_relgions: false,
      get_filter_data: false,
    },
    members_headers: [
      {
        text: 'Religion Name',
        align: 'start',
        sortable: true,
        value: 'text'
      },
      {
        text: 'Total Castes',
        align: 'start',
        sortable: true,
        value: 'castes'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: true,
        value: 'is_active'
      },
      {
        text: 'Registered Member',
        align: 'start',
        sortable: true,
        value: 'registered_members'
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions'
      }
    ],
    status: ['All', 'Active', 'Disabled'],
    religion_list: [],
    total_items: 10,
    pagination: {},
    filters: {
      name: "",
      status: "",
    },
    loaders: {
      list_progress: false,
    }

  }),
  methods: {
    addNewReligion:function(){
      axios.post(`${process.env.VUE_APP_API_URL}/addnewreligion`, this.new_religion).then((response) => {
        if (response.status == 200) {
          this.message = "Religion Added Successfully"
          this.display_snackbar = true;
          this.getAllReligions();
        }
      }).catch((error) => {
        console.log(error);
        if (error.response.status == 422) {
                    console.log(error.response);
                    this.$refs.newreligionform.setErrors({
                        "Religion Name": error.response.data.errors.name,
                        "Enable Religion": error.response.data.errors.is_active, 
                    });
                  
                }
      }).finally(() => {
        this.new_religion.name= '';
        this.new_religion.is_active = false;
      });
    },
    updateReligionStatus: function (religion) {
    
      axios.post(`${process.env.VUE_APP_API_URL}/updatereligionstatus`, religion).then((response) => {
        if (response.status == 200) {
          this.disable_religion_dialog = false;
          this.message = "Religion Status Updated Successfully"
          this.display_snackbar = true;
          this.getAllReligions();
        }
      }).catch((error) => {
        console.log(error);
        this.message = "Religion Status Updation Failed"
          this.display_snackbar = true;
      }).finally(() => {
        this.disable_religion_dialog = false;
      });
    },
    getAllReligions: function () {
      this.loaders.list_progress = true;
      this.pagination.filters = Object.assign({}, this.filters);
      axios.get(`${process.env.VUE_APP_API_URL}/GetAllReligions`, { params: this.pagination }).then((response) => {
        if (response.status == 200) {
          this.total_items = response.data.meta.total;
          this.religion_list = response.data.data;

        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loaders.list_progress = false;
      });
    },
    filterList: function () {
      this.getAllReligions();
      this.dialogs.filter_relgions = false;
    },

  }
}

</script>

 
