<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>
    <v-row>
      <v-col lg="12">
        <H2>Education</H2>
      </v-col>
      <v-col lg="12">
        <v-card>
          <v-card-title>
            Add New Education
          </v-card-title>
          <v-card-text>
            <ValidationObserver ref="newcasteform">
              <v-form ref="neweducationform" @submit.prevent="addNewEducation()">
                <v-row>
                  <v-col lg="3">
                    
                    <ValidationProvider name="Degree Name" rules="required|max:200" v-slot="{ errors }">
                      <v-text-field  name="Degree Name" v-model="new_education.degree" label="Degree Name"
                        outlined :error-messages="errors"></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col lg="3">
                    <ValidationProvider name="Category Name" rules="required|max:300" v-slot="{ errors }">
                      <v-text-field name="Category Name" v-model="new_education.category" label="Category Name" outlined
                        :error-messages="errors"></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col lg="3" cols="12">
                    <ValidationProvider name="Enable Education" rules="required" v-slot="{ errors }">
                      <v-checkbox :error-messages="errors" v-model="new_education.is_active" label="Enable Education"
                        class="ma-auto pt-4"></v-checkbox>
                    </ValidationProvider>
                  </v-col>
                  <v-col lg="3" cols="12">
                    <v-btn type="submit" class="orange darken-3 white--text font-weight-bold mt-1">Add New Education</v-btn>
                  </v-col>

                </v-row>
              </v-form>
            </ValidationObserver>
          </v-card-text>


        </v-card>
      </v-col>
      <!-- <v-col lg="12">
        <v-card class="pa-4">
          <v-row>
            <v-col lg="12">
              <h3>Filters</h3>
            </v-col>
            <v-col lg="3">
              <v-text-field outlined label="Name"></v-text-field>
            </v-col>
            <v-col lg="3">
              <v-text-field outlined label="Status"></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-col> -->
      <!-- <v-col lg="12">
        <v-row justify="end">
          <v-btn color="red darken-5" class="white--text ma-2">
            <v-icon left dark>
              mdi-account-plus
            </v-icon>
            Add New Education
          </v-btn>
          <v-btn color="red darken-5" class="white--text ma-2">
            <v-icon left dark>
              mdi-cloud-upload
            </v-icon>
            Upload Education
          </v-btn>
          <v-btn color="red darken-5" class="white--text ma-2">
            <v-icon left dark>
              mdi-file-export
            </v-icon>
            Export
          </v-btn>
        </v-row>
      </v-col> -->
      <v-col lg="12" cols="12">
        <v-row justify="end">
          <!-- <v-btn color="red darken-5" class="white--text ma-2"
            @click="(dialogs.upload_caste = !dialogs.upload_caste)">
            <v-icon left dark>
              mdi-cloud-upload
            </v-icon>
            Upload Caste
          </v-btn> -->
          <v-btn color="red darken-5" class="white--text ma-2" @click="dialogs.filter_education = true">
            <v-icon v-if="this.$route.query.filters" left dark>mdi-filter-check</v-icon>
            <v-icon v-else left dark>mdi-filter</v-icon>
            <span>Filter List</span>
          </v-btn>

        </v-row>
      </v-col>
      <v-col lg="12" cols="12">
        <v-card>
          <v-data-table :headers="education_headers" :items="education_list" class="index_header" :loading="list_loader"
            :server-items-length="total_items" :footer-props="{
                showFirstLastPage: false,
                firstIcon: 'mdi-arrow-collapse-left',
                itemsPerPageOptions: [5, 10, 15, 20, 50, 100],
              }" :options.sync="pagination">
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn link text icon :href="item + '#'">
                <v-icon medium color="primary" class="ma-2">
                  mdi-eye
                </v-icon>
              </v-btn>
              <v-btn link text icon>
                <v-icon medium color="primary" class="ma-2">
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.is_active`]="{ item }">
              <v-chip v-if="item.is_active == 1" small color="green" class="white--text font-weight-bold"
                @click="updateStatus(item)">Active</v-chip>
              <v-chip v-else small color="red" class="white--text font-weight-bold"
                @click="updateStatus(item)">Disabled</v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!--Filters Members Diaglog-->
    <v-dialog width="500" v-model="dialogs.filter_education">
      <v-form id="filterList" data-vv-scope="filterList" @submit.prevent="filterList()">
        <v-card>
          <v-card-title class="text-h5 indigo white--text">
            Filters Caste
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col lg="12">
                <v-text-field dense outlined label="Name" v-model="filters.Degree"></v-text-field>
                <v-select dense outlined label="Status" v-model="filters.status" :items="status"></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="button ma-1" color="primary" type="submit" :loading="loaders.get_filter_data"
              :disabled="loaders.get_filter_data">Apply Filters</v-btn>
            <v-btn class="button ma-1" color="primary" @click="dialogs.filter_education = false">cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar v-model="display_snackbar" color="primary"> {{ message }}</v-snackbar>
  </v-container>
</template>
  
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';


export default {
  components: {
    CustomAppbar,
    CustomSidebar,
    ValidationObserver,
    ValidationProvider
},
  watch: {
    pagination: {
      handler() {
        this.getAllEducationsList();
      }
    }
  },
  mounted() {
    this.getAllEducationsList();
  },
  methods: {
    addNewEducation : function(){
      axios.post(`${process.env.VUE_APP_API_URL}/addneweducation`, this.new_education).then((response) => {
        if (response.status == 200) {
          this.message = "Education Added Successfully"
          this.display_snackbar = true;
         
           
          this.getAllEducationsList();
        }
      }).catch((error) => {
        console.log(error);
        if (error.response.status == 422) {
                    console.log(error.response);
                    this.$refs.newcasteform.setErrors({
                        "Degree Name": error.response.data.errors.degree,
                        "Enable Education": error.response.data.errors.is_active, 
                        "Category Name":error.response.data.errors.category,
                    });
                  
                }
      }).finally(() => {
        this.new_education.degree = '';
          this.new_education.category = '';
          this.new_education.is_active = false;
      });
    },
    getAllEducationsList: function () {
      this.list_loader = true;
      this.pagination.filters = Object.assign({}, this.filters);
      axios.get(`${process.env.VUE_APP_API_URL}/getAllEducations`, { params: this.pagination }).then((response) => {
        if (response.status == 200) {
          this.total_items = response.data.meta.total;
          this.education_list = response.data.data;

        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.list_loader = false;
      });
    },
    updateStatus: function (education) {

      axios.post(`${process.env.VUE_APP_API_URL}/updateeducationstatus`, education).then((response) => {
        if (response.status == 200) {

          this.message = "Education Status Updated Successfully"
          this.display_snackbar = true;
          this.getAllEducationsList();
        }
      }).catch((error) => {
        console.log(error);
        this.message = "Education Status Updating Failed"
        this.display_snackbar = true;
      }).finally(() => {

      });
    },
    filterList: function () {
      this.getAllEducationsList();
      this.dialogs.filter_education = false;
    },
  },


  data: () => ({
    status: ['All', 'Active', 'Disabled'],
    dialogs: {
      filter_education: false,
    },
    loaders: {
      get_filter_data: false,
    },
    new_education: {
      degree: '',
      is_active: '',
      category: '',
    },
    display_snackbar: false,
    message: '',
    education_list: [],
    total_items: 10,
    pagination: {},
    filters: {
      Degree: "",
      status: "",
    },
    list_loader: false,
    education_headers: [
      {
        text: 'Sr.No',
        align: 'start',
        filterable: true,
        value: 'id'
      },
      {
        text: 'Name',
        align: 'start',
        filterable: true,
        value: 'degree'
      },
      {
        text: 'Stauts',
        align: 'start',
        filterable: true,
        value: 'is_active'
      },
      {
        text: 'Category',
        align: 'start',
        filterable: true,
        value: 'category'
      },
      // {
      //   text: 'Actions',
      //   align: 'start',
      //   filterable: false,
      //   value: 'actions'
      // }
    ]



  }),
}

</script>

 
