<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>
    <v-row>
      <v-col lg="12">
        <H2>Vouchers</H2>
      </v-col>
      <!--Filters-->
      <v-col lg="12">

      </v-col>
      <v-col lg="12">
        <v-row justify="end">

          <router-link :to="{ name: 'addvoucher' }">
            <v-btn color="red darken-5" class="white--text ma-2">
              <v-icon left dark>
                mdi-ticket-percent
              </v-icon>
              Add Voucher
            </v-btn>
          </router-link>
        </v-row>
      </v-col>
      <v-col lg="12">
        <v-card>
          <v-data-table :headers="voucher_headers" :items="voucher_list" class="index_header"
            :loading="loaders.list_progress" :server-items-length="total_items" :footer-props="{
                showFirstLastPage: false,
                firstIcon: 'mdi-arrow-collapse-left',
                itemsPerPageOptions: [5, 10, 15, 20, 50, 100],
              }" :options.sync="pagination">
            <template v-slot:[`item.is_active`]="{ item }">
              <span v-if="item.is_active">Active</span>
              <span v-else>Disabled</span>
            </template>
            <template v-slot:[`item.is_percentage`]="{ item }">
              <span v-if="item.is_percentage">Percentage</span>
              <span v-else>Rupees</span>
            </template>
            <template v-slot:[`item.is_fixed`]="{ item }">
              <span v-if="item.is_fixed">Fixed</span>
              <span v-else>Upto Max</span>
            </template>
            
            <template v-slot:[`item.actions`]="{ item }">
              <v-dialog v-model="delete_voucher" width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn link text icon v-on="on" v-bind="attrs" @click="delete_voucher = true">
                    <v-icon small color="red" class="ma-1">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline lighten-2" primary-title>
                    Confirmation
                  </v-card-title>

                  <v-card-text>
                    Are you sure you want to delete Voucher. Once deleted it cannot be revert.
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="deleteVoucher(item)">
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              
              <router-link :to="{ name: 'editvoucher', params: { 'voucher_id': item.code } }">
                <v-icon medium color="primary" class="mr-2" small>
                  mdi-pencil
                </v-icon>
              </router-link>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!--Dialogs-->

    <!--Filters Members Diaglog-->
    <v-dialog width="500" v-model="dialogs.filter_members">
      <v-form id="filterList" data-vv-scope="filterList" @submit.prevent="filterList">
        <v-card>
          <v-card-title class="text-h5 indigo white--text">
            Filters Members
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col lg="12">
                <v-text-field dense outlined label="Name" v-model="filters.name"></v-text-field>
                <v-select dense outlined label="Gender" v-model="filters.gender" :items="genders"></v-select>
                <v-text-field dense outlined label="Email Address" v-model="filters.email"></v-text-field>
                <v-text-field dense outlined label="Mobile Number" v-model="filters.mobile_number"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="button ma-1" color="primary" type="submit" :loading="loaders.get_filter_data"
              :disabled="loaders.get_filter_data">Apply Filters</v-btn>
            <v-btn class="button ma-1" color="primary" @click="dialogs.filter_members = false">cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar v-model="display_snackbar" color="primary" top> {{ message }}</v-snackbar>
  </v-container>

</template>
    
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import axios from 'axios';


export default {
  components: {
    CustomAppbar,
    CustomSidebar
  },

  watch: {
    pagination: {
      handler() {
        this.getAllVouchers();
      }
    }
  },
  mounted() {

  },
  data: () => ({
    upload_form: new FormData,
    dialogs: {
      upload_member: false,
      filter_members: false,
      get_filter_data: false,
    },
    display_snackbar:false,
    message:'',
    voucher_headers: [
      {
        text: 'Code',
        align: 'start',
        sortable: false,
        value: 'code'
      },
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Description',
        align: 'start',
        sortable: true,
        value: 'description'
      },
      {
        text: 'Start Date',
        align: 'start',
        sortable: true,
        value: 'start_date'
      },
      {
        text: 'End Date',
        align: 'start',
        sortable: true,
        value: 'end_date'
      },

      {
        text: 'Status',
        align: 'start',
        sortable: true,
        value: 'is_active'
      },
      {
        text: 'Type',
        align: 'start',
        sortable: true,
        value: 'is_percentage'
      },
      {
        text: 'Fixed',
        align: 'start',
        sortable: true,
        value: 'is_fixed'
      },
      {
        text: 'Min. Discount Amt.',
        align: 'start',
        sortable: true,
        value: 'min_discount_amount'
      },
      {
        text: 'Max Discount Amt.',
        align: 'start',
        sortable: true,
        value: 'max_discount_amount'
      },
      {
        text: 'Min. Discount %',
        align: 'start',
        sortable: true,
        value: 'min_discount_percentage'
      },
      {
        text: 'Max Discount %',
        align: 'start',
        sortable: true,
        value: 'max_discount_percentage'
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions'
      }
    ],
    genders: ['Bride', 'Groom'],
    voucher_list: [],
    total_items: 10,
    pagination: {},
    filters: {
    
    },
    loaders: {
      list_progress: false,
      export_loader: false,
      upload_loader: false,
    },
    delete_voucher:false,
    upload_user_file: '',

  }),
  methods: {

    
    deleteVoucher:function(voucher){
      axios.post(`${process.env.VUE_APP_API_URL}/deletevoucher`, voucher).then((response) => {
        if (response.status == 200) {
          this.message = "Voucher Deleted Successfully";
        this.display_snackbar = true;
        this.getAllVouchers();


        }
      }).catch((error) => {
        console.log(error);
        this.message = "Error Occur while deleting voucher";
        this.display_snackbar = true;
      }).finally(() => {
        this.loaders.list_progress = false;
      });
    },
    getAllVouchers: function () {
      this.loaders.list_progress = true;
      this.pagination.filters = Object.assign({}, this.filters);
      axios.get(`${process.env.VUE_APP_API_URL}/getallvouchers`, { params: this.pagination }).then((response) => {
        if (response.status == 200) {
        
          this.total_items = response.data.vouchers.total;
          this.voucher_list = response.data.vouchers.data;

        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loaders.list_progress = false;
      });
    },
  }
}

</script>
  
   
  