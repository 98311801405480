<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>
    <v-skeleton-loader elevation="10" class="mb-5" type="card-heading,article, actions"
      v-if="data_loader"></v-skeleton-loader>
    <v-row class="pl-10" v-else>
      <v-col lg="12" cols="12">
        <v-card>
          <v-card-text>
            <h3>Kesari Elite Introduction Section</h3>
          </v-card-text>
          <v-card-text>
            <ValidationObserver refs="updatekesarieliteintro">
              <v-form @submit.prevent="updatekesarieliteintro()" refs="updatekesarieliteintro">
                <v-row>
                  <v-col lg="12" cols="12">
                    <ValidationProvider name="Title" rules="max:100" v-slot="{ errors }">
                      <v-text-field outlined label="Title" v-model="why_elite.title"
                        :error-messages="errors"></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <!-- <v-col lg="12" cols="12">
                    <ValidationProvider name="Caption" rules="max:100" v-slot="{ errors }">
                      <v-text-field outlined label="Caption" v-model="why_elite.caption"
                        :error-messages="errors"></v-text-field>
                    </ValidationProvider>
                  </v-col> -->
                  <v-col lg="6" cols="12">
                    <v-img :src="why_kesari_banner.uri" min-height="450" contain></v-img>
                    <span class="text-caption red--text text-wrap font-weight-bold"><br>Note: Images should be of minimum 760 * 480 pixel</span>
                    <ValidationProvider name="Upload Banner" rules="required|image" v-slot="{ errors }">
                      <v-file-input class="mt-5" label="Upload Banner" v-model="new_why_kesari_banner" accept="image/*"
                        clearable :error-messages="errors"></v-file-input>
                    </ValidationProvider>


                  </v-col>

                  <v-col lg="6" cols="12">

                    <tiptap-vuetify :extensions="extenstions" placeholder="Body" v-model="why_elite.body" required />
                  </v-col>
                  <v-col lg="12" cols="12">
                    <v-btn class="orange darken-3 font-weight-bold white--text" type="submit">Update Section</v-btn>
                  </v-col>

                </v-row>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="12" cols="12">
        <v-card>
          <v-card-text>
            <h3>Kesari Elite Packages Section</h3>
          </v-card-text>
          <v-card-text>
            <ValidationObserver refs="updateKesariElitePackage">
              <v-form @submit.prevent="updateKesariElitePackage()" refs="updateKesariElitePackage">
                <v-row>
                  <v-col lg="12" cols="12">
                    <ValidationProvider name="Title" rules="max:100" v-slot="{ errors }">
                      <v-text-field outlined label="Title" v-model="kesari_package.title"
                        :error-messages="errors"></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col lg="12" cols="12">

                    <tiptap-vuetify :extensions="extenstions" placeholder="Body" v-model="kesari_package.body" required />
                  </v-col>
                  <v-col lg="12" cols="12">
                    <v-btn class="orange darken-3 font-weight-bold white--text" type="submit">Update Section</v-btn>
                  </v-col>

                </v-row>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="12">
        <v-card>
          <v-card-text>
            <h3>Kesari Elite Feedbacks</h3>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col lg="6" cols="6">
                <v-carousel hide-delimiter-background>
                  <v-carousel-item v-for="kesari_feedback, index in kesari_feedback_banners" :key="index"
                    :src="kesari_feedback.uri">
                    <v-sheet class="font-weight-bold body pb-5">
                      <h5>Feedback :</h5>
                      <div v-html="kesari_feedback.alternative_text">
                      </div>
                    </v-sheet>
                    <v-btn icon class="ma-3 float-right red darken-2 white--text rounded-lg elevation-10" tile small
                      @click="deleteImage(kesari_feedback)"> <v-icon small>mdi-delete</v-icon></v-btn>
                    <v-btn class="ma-3 float-right indigo darken-3 white--text font-weight-bold elevation-10" small
                      v-if="kesari_feedback.is_active" @click="updateDisplayStatus(kesari_feedback)">Unpublished
                      This</v-btn>
                    <v-btn class="ma-3 float-right indigo darken-3 white--text font-weight-bold elevation-10" small v-else
                      @click="updateDisplayStatus(kesari_feedback)">Published This</v-btn>
                  </v-carousel-item>
                </v-carousel>
              </v-col>
              <v-col lg="6" cols="6" class="">
                <h3 class="pt-10 pb-10">Add New Feedbacks</h3>
                <ValidationObserver>
                  <v-form @submit.prevent="addNewFeedback()">
                    <v-row>
                      <v-col lg="12" cols="12">

                        <tiptap-vuetify :extensions="extenstions" placeholder="FeedBack" v-model="new_feedback.feedback"
                          required />

                      </v-col>
                      <v-col lg="12" cols="12">
                        <ValidationProvider name="Publish This Image" rules="" v-slot="{ errors }">
                          <v-checkbox outlined label="Publish This Image" :error-messages="errors"
                            v-model="new_feedback.is_active"></v-checkbox>
                        </ValidationProvider>
                      </v-col>
                      <v-col lg="12" cols="12">
                        <span class="text-caption red--text text-wrap font-weight-bold"><br>Note: Images should be of minimum 800 * 780 pixel</span>
                        <ValidationProvider name="Upload Image" rules="required|image" v-slot="{ errors }">
                          <v-file-input class="mt-5" label="Upload Image" accept="image/*" clearable
                            :error-messages="errors" v-model="new_feedback.image"></v-file-input>
                        </ValidationProvider>
                      </v-col>
                      <v-col lg="12" cols="12">
                        <v-btn class="orange darken-3 font-weight-bold white--text" type="submit" :loading="data_loader"
                          :disabled="data_loader">Add Feedback</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </ValidationObserver>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="display_snackbar" color="primary" class="font-weight-bold"> {{ message }}</v-snackbar>
  </v-container>
</template>
    
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TiptapVuetify } from 'tiptap-vuetify'

export default {
  components: {
    CustomAppbar,
    CustomSidebar,
    ValidationObserver,
    ValidationProvider,
    TiptapVuetify,
  },
  mounted() {
    this.getKesariEliteContent();
  },
  methods: {
    updateDisplayStatus: function (feedback) {
      this.data_loader = true;
      axios.post(`${process.env.VUE_APP_API_URL}/updatebannerstatus`, feedback).then((response) => {
        if (response.status == 200) {
          this.message = "Feedback Status Has been updated",
            this.display_snackbar = true;
          this.getKesariEliteContent();
        }

      }).catch((error) => {
        console.log(error);
        this.message = "Feedback Status updating failed",
          this.display_snackbar = true;

      }).finally(() => {
        this.data_loader = false;
      });
    },
    addNewFeedback: function () {
      this.data_loader = true;
      this.kesari_feedback_data.append('feedback', this.new_feedback.feedback);
      this.kesari_feedback_data.append('is_active', this.new_feedback.is_active);
      this.kesari_feedback_data.append('image', this.new_feedback.image);
      axios.post(`${process.env.VUE_APP_API_URL}/addnewelitefeedback`, this.kesari_feedback_data).then((response) => {
        if (response.status == 200) {
          this.message = "Feedback Status Has been updated",
            this.display_snackbar = true;

          this.getKesariEliteContent();
        }

      }).catch((error) => {
        console.log(error);
        this.message = "Feedback Status updating failed",
          this.display_snackbar = true;
        this.kesari_feedback_data.delete('feedback');
        this.kesari_feedback_data.delete('is_active');
        this.kesari_feedback_data.delete('image');

      }).finally(() => {
        this.data_loader = false;
        this.kesari_feedback_data.delete('feedback');
        this.kesari_feedback_data.delete('is_active');
        this.kesari_feedback_data.delete('image');
      });
    },
    getKesariEliteContent: function () {
      this.data_loader = true
      axios.get(`${process.env.VUE_APP_API_URL}/getelitepagecontent`).then((response) => {
        if (response.status == 200) {
          this.why_elite = response.data.why_elite
          this.why_kesari_banner = response.data.why_kesari_banner
          this.kesari_feedbacks = response.data.kesari_feedbacks
          this.kesari_package = response.data.kesari_package
          this.kesari_feedback_banners = response.data.kesari_feedback_banners
        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message = "Error Occur While Getting Home Page Content"
          this.display_snackbar = true
        }
      }).finally(() => {
        this.data_loader = false;
      });
    },
    deleteImage: function (feedback) {
      this.delete_loader = true;
      axios.post(`${process.env.VUE_APP_API_URL}/deleteimage`, feedback).then((response) => {
        if (response.status == 200) {
          this.message = "Feedback Has been deleted",
            this.display_snackbar = true;
          this.getHomePageContent();
        }

      }).catch((error) => {
        console.log(error);
        this.message = "Feedback deletion failed",
          this.display_snackbar = true;

      }).finally(() => {
        this.delete_loader = false;
      });
    },
    updatekesarieliteintro: function () {
      this.data_loader = true;
      this.kesari_elite_data.append('title', this.why_elite.title);
      this.kesari_elite_data.append('body', this.why_elite.body);
      this.kesari_elite_data.append('banner', this.new_why_kesari_banner);
      axios.post(`${process.env.VUE_APP_API_URL}/updatekesarieliteintro`, this.kesari_elite_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.status == 200) {
          this.message = "Section updated Successfully";
          this.display_snackbar = true;


          this.getKesariEliteContent()
        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message = "Error Occur while updating Section"
          this.display_snackbar = true
        }
      }).finally(() => {
        this.kesari_elite_data.delete('title')
        this.kesari_elite_data.delete('body')
        this.kesari_elite_data.delete('banner')
        this.data_loader = false;
      });
    },
    updateKesariElitePackage: function () {
      this.data_loader = true;
      axios.post(`${process.env.VUE_APP_API_URL}/updatekesaripackages`, this.kesari_package, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.status == 200) {
          this.message = "Section updated Successfully";
          this.display_snackbar = true;


          this.getKesariEliteContent()
        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message = "Error Occur while updating Section"
          this.display_snackbar = true
        }
      }).finally(() => {

        this.data_loader = false;
      });
    }
  },


  data: () => ({
    kesari_elite_data: new FormData,
    kesari_package_data: new FormData,
    kesari_feedback_data: new FormData,
    message: "",
    display_snackbar: false,
    data_loader: false,
    extenstions: [],
    why_elite: {
      id: '',
      title: '',
      caption: '',
      body: '',
    },
    kesari_package: {
      id: '',
      title: '',
      body: '',
      caption: '',
      is_published: '',
    },
    new_feedback: {
      feedback: '',
      is_active: false,
      image: {},
    },
    kesari_feedbacks: [],
    new_why_kesari_banner: {

    },
    kesari_feedback_banners: [],
    why_kesari_banner: {
      id: '',
      uri: '',

    }

  }),
}

</script>
  
   
  