<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>
    <v-row>
      <v-col lg="12">
        <H2>Members</H2>
      </v-col>
      <!--Filters-->
      <v-col lg="12">

      </v-col>
      <v-col lg="12">
        <v-row justify="end">
          <v-btn color="red darken-5" class="white--text ma-2" @click="(dialogs.upload_member = !dialogs.upload_member)">
            <v-icon left dark>
              mdi-cloud-upload
            </v-icon>
            Upload Members
          </v-btn>
          <v-btn color="red darken-5" class="white--text ma-2" @click="exportEliteUsers" :loading="loaders.export_loader">
            <v-icon left dark>
              mdi-file-export
            </v-icon>
            Export
          </v-btn>
          <v-btn color="red darken-5" class="white--text ma-2" @click="dialogs.filter_members = true">
            <v-icon v-if="this.$route.query.filters" left dark>mdi-filter-check</v-icon>
            <v-icon v-else left dark>mdi-filter</v-icon>
            <span>Filter List</span>
          </v-btn>

        </v-row>
      </v-col>
      <v-col lg="12">
        <v-card>
          <v-data-table :headers="members_headers" :items="members_list" class="index_header"
            :loading="loaders.list_progress" :server-items-length="total_items" :footer-props="{
              showFirstLastPage: false,
              firstIcon: 'mdi-arrow-collapse-left',
              itemsPerPageOptions: [5, 10, 15, 20, 50, 100],
            }" :options.sync="pagination">
            <template v-slot:[`item.first_name`]="{ item }">
              {{ item.first_name + " " + item.middle_name + " " + item.last_name }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <router-link :to="{name:'viewmember', params:{member_id:item.id}}">
                <v-icon medium color="primary" class="mr-2">
                  mdi-eye
                </v-icon>
              </router-link>
              <router-link :to="{name:'editmember', params:{member_id:item.id}}">
                <v-icon medium color="primary" class="mr-2">
                  mdi-pencil
                </v-icon>
              </router-link>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!--Dialogs-->

    <!--Filters Members Diaglog-->
    <v-dialog width="500" v-model="dialogs.filter_members">
      <v-form id="filterList" data-vv-scope="filterList" @submit.prevent="filterList">
        <v-card>
          <v-card-title class="text-h5 indigo white--text">
            Filters Members
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col lg="12">
                <v-text-field dense outlined label="Name" v-model="filters.name"></v-text-field>
                <v-select dense outlined label="Gender" v-model="filters.gender" :items="genders"></v-select>
                <v-text-field dense outlined label="Email Address" v-model="filters.email"></v-text-field>
                <v-text-field dense outlined label="Mobile Number" v-model="filters.mobile_number"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="button ma-1" color="primary" type="submit" :loading="loaders.get_filter_data"
              :disabled="loaders.get_filter_data">Apply Filters</v-btn>
            <v-btn class="button ma-1" color="primary" @click="dialogs.filter_members = false">cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- upload Members dialog-->
    <v-dialog width="500" v-model="dialogs.upload_member" persistent>
      <v-form @submit.prevent="uploadUsers">
      <v-card :loading="loaders.upload_loader">
        <v-card-title class="text-h5 indigo white--text">
          Upload Members
        </v-card-title>

        <v-card-text>
          <v-file-input  label="Upload File" v-model="upload_user_file"></v-file-input>
          <span class="pa-4">Click Here Download Template</span>
        </v-card-text>

        <v-card-text v-if="file_upload_errors.length > 0">
            <ul>
              <li class="font-weight-bold green--text">All Data Imported Successfully Except for below Rows:</li>
              <li v-for="error, index in file_upload_errors" :key="index" class="red--text font-weight-bold">
                {{ "Row No: " + error.row + " Value: " + error.attribute + " Has Error: " + error.errors[0] }}
              </li>
            </ul>
          </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" type="submit" :disabled="loaders.upload_loader">
              Upload
            </v-btn>
            <v-btn @click="dialogs.upload_member = false" color="blue-grey darken-3"  class="white--text">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    </v-dialog>
  </v-container>
</template>
  
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';


export default {
  components: {
    CustomAppbar,
    CustomSidebar
  },
  watch: {
    pagination: {
      handler() {
        this.getAllEliteMembers();
      }
    }
  },
  mounted() {
    console.log("allmembers")
  },
  data: () => ({

    dialogs: {
      upload_member: false,
      filter_members: false,
      get_filter_data: false,
    },
    members_headers: [
      {
        text: 'Member ID',
        align: 'start',
        sortable: false,
        value: 'profile_id'
      },
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'first_name'
      },
      {
        text: 'Gender',
        align: 'start',
        sortable: true,
        value: 'gender'
      },
      {
        text: 'Date of Birth',
        align: 'start',
        sortable: true,
        value: 'date_of_birth'
      },
      {
        text: 'Email',
        align: 'start',
        sortable: true,
        value: 'email'
      },
      {
        text: 'Mobile Number',
        align: 'start',
        sortable: true,
        value: 'mobile_number'
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions'
      }
    ],
    genders: ['Bride', 'Groom'],
    members_list: [],
    total_items: 10,
    pagination: {},
    file_upload_errors: [],
    filters: {
      name: "",
      email: "",
      mobile_number: "",
      gender: ""
    },
    loaders: {
      list_progress: false,
      export_loader:false,
      upload_loader: false,
    },
    upload_user_file: '',
    upload_form: new FormData,

  }),
  methods: {
    uploadUsers: function () {
      this.loaders.upload_loader = true;
      this.upload_form.append('document', this.upload_user_file);
      axios.post(`${process.env.VUE_APP_API_URL}/importEliteUsers`, this.upload_form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response)=>{
        if(response.status== 200){
          this.loaders.upload_loader = false;  
        }
      }).catch((error)=>{
        if (error.response.status == 422) {
            this.file_upload_errors = error.response.data.failures;
          }else{
            console.log(error);
          }
      }).finally(()=>{
        this.upload_user_file = '';
        this.loaders.upload_loader = false;  
        this.getAllEliteMembers();
      })

    },
    getAllEliteMembers: function () {
      this.loaders.list_progress = true;
      this.pagination.filters = Object.assign({}, this.filters);
      axios.get(`${process.env.VUE_APP_API_URL}/GetAllEliteMembers`, { params: this.pagination }).then((response) => {
        if (response.status == 200) {
          this.total_items = response.data.meta.total;
          this.members_list = response.data.data;

        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loaders.list_progress = false;
      });
    },
    filterList: function () {
      this.getAllEliteMembers();
      this.dialogs.filter_members = false;
    },

    exportEliteUsers: function () {
      this.loaders.export_loader = true;
      axios.get(`${process.env.VUE_APP_API_URL}/exportEliteUsers`, {
        responseType: 'blob'
      }).then((response) => {
        if (response.status == 200) {
          let fileUrl = window.URL.createObjectURL(response.data);
          let fileLink = document.createElement('a');

          fileLink.href = fileUrl;
          fileLink.setAttribute('download', 'users.xlsx');
          document.body.appendChild(fileLink)

          fileLink.click();

        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loaders.export_loader = false;
      });
    }

  }
}

</script>

 
