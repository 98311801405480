<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>
    <v-row>
      <v-col lg="12">
        <H2>Contact Queries</H2>
      </v-col>
      <!--Filters-->
      <v-col lg="12">

      </v-col>
      <v-col lg="12">
        <v-row justify="end">
          
          <v-btn color="red darken-5" class="white--text ma-2" @click="dialogs.filter_queries = true">
            <v-icon v-if="this.$route.query.filters" left dark>mdi-filter-check</v-icon>
            <v-icon v-else left dark>mdi-filter</v-icon>
            <span>Filter List</span>
          </v-btn>

        </v-row>
      </v-col>
      <v-col lg="12">
        <v-card>
          <v-data-table :headers="members_headers" :items="query_list" class="index_header"
            :loading="loaders.list_progress" :server-items-length="total_items" :footer-props="{
  showFirstLastPage: false,
  firstIcon: 'mdi-arrow-collapse-left',
  itemsPerPageOptions: [5,10,15,20,50,100],
}" :options.sync="pagination" >
            <template v-slot:[`item.actions`]>
              <v-btn link text icon>
                <v-icon medium color="primary" class="ma-2">
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn link text icon >
                <v-icon medium color="primary" class="ma-2">
                mdi-email-outline
              </v-icon>
            </v-btn>
            </template>
            <template v-slot:[`item.is_resolved`]="{ item }">
              <v-chip v-if="item.is_resolved" small color="green" class="white--text font-weight-bold">Resolved</v-chip>
              <v-chip v-else small color="red" class="white--text font-weight-bold">Not Resolved</v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!--Dialogs-->

    <!--Filters Members Diaglog-->
    <v-dialog width="500" v-model="dialogs.filter_queries">
      <v-form id="filterList" data-vv-scope="filterList" @submit.prevent="filterList">
        <v-card>
          <v-card-title class="text-h5 indigo white--text">
            Filters Caste
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col lg="12">
                <v-text-field dense outlined label="Name" v-model="filters.name"></v-text-field>
                <v-text-field dense outlined label="Email Address" v-model="filters.email_address"></v-text-field>
                <v-text-field dense outlined label="Mobile Number" v-model="filters.mobile_number"></v-text-field>
                <v-select dense outlined label="Status" v-model="filters.is_resolved" :items="status"></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="button ma-1" color="primary" type="submit" :loading="loaders.get_filter_data"
               :disabled="loaders.get_filter_data">Apply Filters</v-btn>
            <v-btn class="button ma-1" color="primary" @click="dialogs.filter_queries = false">cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>
  
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';


export default {
  components: {
    CustomAppbar,
    CustomSidebar
  },
  watch: {
    pagination: {
      handler() {
        this.getAllQueries();
      }
    }
  },
  mounted() {
    
  },
  data: () => ({

    dialogs: {
      filter_queries: false,
      get_filter_data: false,
    },
    members_headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Email',
        align: 'start',
        sortable: true,
        value: 'email_address'
      },
      {
        text: 'Contact Details',
        align: 'start',
        sortable: true,
        value: 'mobile_number'
      },
      {
        text: 'Query',
        align: 'left',
        sortable: true,
        value: 'query',
        width:"600"
      },
      
      {
        text: 'Status',
        align: 'start',
        sortable: true,
        value: 'is_resolved'
      },
      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions'
      }
    ],
    status: ['All','Resolved', 'Not Resolved'],
    query_list: [],
    total_items: 10,
    pagination: {},
    filters: {
      name: "",
      email_address: "",
      is_resolved:"",
      mobile_number:"",
    },
    loaders: {
      list_progress: false,
    }

  }),
  methods: {

    getAllQueries: function () {
      this.loaders.list_progress = true;
      this.pagination.filters = Object.assign({}, this.filters);
      axios.get(`${process.env.VUE_APP_API_URL}/GetAllQueries`, { params: this.pagination }).then((response) => {
        if (response.status == 200) {
        
          this.total_items = response.data.meta.total;
          this.query_list = response.data.data;
          console.log(this.query_list);

        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loaders.list_progress = false;
      });
    },
    filterList: function () {
     this.getAllQueries();
     this.dialogs.filter_queries = false;
    },

  }
}

</script>

 
