<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>
    <v-row>
      <v-col lg="12" cols="12">
        <ValidationObserver>
          <v-form @submit.prevent="updateTermsandCondition()" ref="termsandconditionform">
            <v-col lg="12" cols="12">
              <v-card max-height="500px" class="overflow-auto elevation-3">
                <ValidationProvider name="Terms and Condition" rules="required" v-slot="{ errors }">
                            <tiptap-vuetify v-model="terms_and_conditions.body" :extensions="extenstions"
                                label="Terms and Condition" :error-messages="errors" />
                        </ValidationProvider>
              </v-card>
             
            </v-col>
            <v-col lg="4" cols="12">
              <v-btn type="submit" class="orange darken-3 white--text font-weight-bold mt-10"
                :disabled="false">Update Terms and Condition</v-btn>
            </v-col>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
    <v-snackbar v-model="display_snackbar" color="primary"> {{ message }}</v-snackbar>
  </v-container>
</template>
    
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TiptapVuetify } from 'tiptap-vuetify'


export default {
  components: {
    CustomAppbar,
    CustomSidebar,
    ValidationObserver,
    ValidationProvider,
    TiptapVuetify
  },
  mounted() {
    this.getTermsAndConditions();
  },
  methods: {
    getTermsAndConditions: function(){
      axios.get(`${process.env.VUE_APP_API_URL}/gettermsandconditions`).then((response) => {
        if (response.status == 200) {
          this.terms_and_conditions = response.data.terms_and_conditions;
        }
      }).catch((error) => {
        if (error.response.status == 422) {
          this.$refs.newtemplateform.setErrors({
            'template': error.response.data.errors.title

          });
          this.message = "Error Occur While Saving Template"
          this.display_snackbar = true
        }
      }).finally(() => {

      });
    },
    updateTermsandCondition: function () {
      axios.post(`${process.env.VUE_APP_API_URL}/updatetermsandconditions`, this.terms_and_conditions).then((response) => {
        if (response.status == 200) {
          this.message = "Terms and Conditions updated Successfully";
          this.display_snackbar = true;
        }
      }).catch((error) => {
        if (error.response.status == 422) {
          
          this.message = "Error Occur While Saving Terms and Conditions"
          this.display_snackbar = true
        }
      }).finally(() => {

      });


    }
  },
  data: () => ({
    update_loader:false,
    get_loader:false,
    extenstions:[],
    message: "",
        display_snackbar: false,
        terms_and_conditions:{
      id:'',
      module:'terms_and_conditions',
      title:'',
      body:'',
      is_publish:false,
    }

  }),
}

</script>
  
   
  