/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import '../node_modules/vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify'
import '@/assets/css/style.css'
import axios from 'axios';
import moment from 'moment';
import './validations/veevalidate.js';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import VueApexCharts from 'vue-apexcharts'


// global components
import '@/layout'

Vue.config.productionTip = false
Vue.use(Vuetify)
Vue.use(moment);
Vue.use(VueApexCharts);

Vue.component('apexchart', VueApexCharts)
window.axios = axios;
window.moment = moment;
Vue.prototype.moment = moment

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
})

Vue.use(TiptapVuetifyPlugin, {
    // the next line is important! You need to provide the Vuetify Object to this place.
    vuetify: new Vuetify(), // same as "vuetify: vuetify"
    // optional, default to 'md' (default vuetify icons before v2.0.0)
    iconsGroup: 'mdi'
})
Vue.use(TiptapVuetifyPlugin)


axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }

);

axios.interceptors.response.use(null, function(error) {
    switch (error.response.status) {
        case 401:
            store.dispatch('auth/logout');
            router.push({ name: 'home' });
            break;
        case 403:
            router.push({ name: '403' });
            break;
        case 404:
            router.push({ name: '404' });
            break;
        case 429:
            router.push({ name: '429' });
            break;
        case 500:
            // store.dispatch('auth/logout');
            router.push({ name: '500' });
            break;
        default:
            break;
    }
    return Promise.reject(error);
});

new Vue({
    vuetify: new Vuetify(),
    store,
    router,
    moment,
    render: h => h(App)
}).$mount('#app')