<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>
    <v-skeleton-loader elevation="10" class="mb-5" type="card-heading,article, actions"
      v-if="data_loader"></v-skeleton-loader>
    <v-row class="pl-10" v-else>
      <v-col lg="12" cols="12">
        <v-card>
          <v-card-text>
            <h3>Home Page Hero Section</h3>
          </v-card-text>
          <v-card-text>
            <ValidationObserver refs="updateHomePageHeroSection">
              <v-form @submit.prevent="updateHomePageHeroSection()" refs="updateHomePageHeroSection">
                <v-col lg="12" cols="12">
                  <ValidationProvider name="Title" rules="max:100" v-slot="{ errors }">
                    <v-text-field outlined label="Title" v-model="hero_section.title"
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col lg="12" cols="12">
                  <ValidationProvider name="Caption" rules="max:100" v-slot="{ errors }">
                    <v-text-field outlined label="Caption" v-model="hero_section.caption"
                      :error-messages="errors"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col lg="12" cols="12">
                  <v-btn class="orange darken-3 font-weight-bold white--text" type="submit">Update Section</v-btn>
                </v-col>
                <v-col lg="12" cols="12" v-if="current_banners.length > 0">
                  <h3 class="mt-3 mb-3">Current Hero Section Banners</h3>
                  <v-carousel cycle interval="10000" continuous hide-delimiter-background>
                    <v-carousel-item v-for="banner, index in current_banners" :key="index" :src="banner.uri"
                      reverse-transition="fade-transition" transition="fade-transition">
                      <v-btn icon class="ma-3 float-right red darken-2 white--text rounded-lg elevation-10" tile small
                        @click="deleteImage(banner)" v-if="index != 0"> <v-icon small>mdi-delete</v-icon></v-btn>
                      <v-btn class="ma-3 float-right indigo darken-3 white--text font-weight-bold elevation-10" small
                        v-if="banner.is_active" @click="updateDisplayStatus(banner)">Unpublished This Banner</v-btn>
                      <v-btn class="ma-3 float-right indigo darken-3 white--text font-weight-bold elevation-10" small
                        v-else @click="updateDisplayStatus(banner)">Published This Banner</v-btn>

                    </v-carousel-item>
                  </v-carousel>
                </v-col>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="8" cols="12">
        <v-card>
          <v-card-text>
            <h3>Update Hero Banners</h3>
          </v-card-text>
          <v-card-text>
            <ValidationObserver>
              <v-form @submit.prevent="updateHomePageHeroSectionBanners()">
                <v-col lg="12" cols="12" v-if="preview_urls.length > 0">
                  <v-carousel>
                    <v-carousel-item v-for="imageurl, index in preview_urls" :key="index" :src="imageurl"
                      reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
                  </v-carousel>
                </v-col>
                <v-col lg="12">
                  <ValidationProvider name="Upload Banners" rules="required|image" v-slot="{ errors }">
                    <v-file-input counter multiple show-size @click:clear="clearImages" accept="image/*"
                      label="Upload Banners" @change="onFileChange" clearable :error-messages="errors"
                      v-model="banners"></v-file-input>
                  </ValidationProvider>
                  <span class="text-caption red--text text-wrap">Note: Images should be of .jpg,.png,.jpeg format are
                    accepted.Any changes to Default Image cannot be reverted</span>
                    <span class="text-caption red--text text-wrap font-weight-bold"><br>Note: Images should be of minimum 1920 * 1080 pixel</span>
                </v-col>
                <v-col lg="12" cols="12">
                  <v-btn class="orange darken-3 font-weight-bold white--text" type="submit"
                    :disabled="banner_upload_loader" :loading="banner_upload_loader">Update Section</v-btn>
                </v-col>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="12" cols="12">
        <v-card>
          <v-card-text>
            <h3>Kesari Introduction Section</h3>
          </v-card-text>
          <v-card-text>
            <ValidationObserver refs="updateKesariIntroSection">
              <v-form @submit.prevent="updateKesariIntroSection()" refs="updateKesariIntroSection">
                <v-row>
                  <v-col lg="12" cols="12">
                    <ValidationProvider name="Title" rules="max:100" v-slot="{ errors }">
                      <v-text-field outlined label="Title" v-model="kesari_intro_section.title"
                        :error-messages="errors"></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <!-- <v-col lg="12" cols="12">
                    <ValidationProvider name="Caption" rules="max:100" v-slot="{ errors }">
                      <v-text-field outlined label="Caption" v-model="kesari_intro_section.caption"
                        :error-messages="errors"></v-text-field>
                    </ValidationProvider>
                  </v-col> -->
                  <v-col lg="6" cols="12">
                    <v-img :src="current_kesari_intro_banner.uri" min-height="450" contain></v-img>

                    <ValidationProvider name="Upload Banner" rules="required|image" v-slot="{ errors }">
                      <v-file-input class="mt-5" label="Upload Banner" v-model="kesari_intro_banner" accept="image/*"
                        clearable :error-messages="errors"></v-file-input>
                    </ValidationProvider>
                    <span class="text-caption red--text text-wrap font-weight-bold"><br>Note: Images should be of minimum 600 * 500 pixel</span>
                    


                  </v-col>
                  <v-col lg="6" cols="12">
                    <tiptap-vuetify :extensions="extenstions" placeholder="Body" v-model="kesari_intro_section.body"
                      required />
                  </v-col>
                  <v-col lg="12" cols="12">
                    <v-btn class="orange darken-3 font-weight-bold white--text" type="submit">Update Section</v-btn>
                  </v-col>

                </v-row>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="12">
        <v-card>
          <v-card-text>
            <h3>Marriage By Kesari Section</h3>
          </v-card-text>
          <v-card-text>

            <ValidationObserver>
              <v-form @submit.prevent="updateMarriageByKesari()">
                <v-row>
                  <v-col lg="7" cols="12">
                    <ValidationProvider name="Title" rules="max:100" v-slot="{ errors }">
                      <v-text-field outlined label="Title" v-model="marriage_by_kesari_section.title"
                        :error-messages="errors"></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col lg="7" cols="12">
                    <ValidationProvider name="Caption" rules="max:100" v-slot="{ errors }">
                      <v-text-field outlined label="Caption" v-model="marriage_by_kesari_section.caption"
                        :error-messages="errors"></v-text-field>
                    </ValidationProvider>
                  </v-col>
                  <v-col lg="12" cols="12">
                    <ValidationProvider name="Publish This Section" rules="required" v-slot="{ errors }">
                      <v-checkbox outlined label="Publish This Section" :error-messages="errors" v-model="marriage_by_kesari_section.is_published"></v-checkbox>
                    </ValidationProvider>
                  </v-col>
                  <v-col lg="12" cols="12">
                    <v-btn class="orange darken-3 font-weight-bold white--text" type="submit">Update Section</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col lg="6" cols="6">
                <v-carousel hide-delimiter-background>
                  <v-carousel-item v-for="happy_couple, index in happy_couples" :key="index" :src="happy_couple.uri" contain>
                    <v-sheet class="font-weight-bold body pb-5">
                     {{ happy_couple.alternative_text }}
                    </v-sheet>
                    <v-btn icon class="ma-3 float-right red darken-2 white--text rounded-lg elevation-10" tile small
                      @click="deleteCoupleImage(happy_couple)"> <v-icon small>mdi-delete</v-icon></v-btn>
                    <v-btn class="ma-3 float-right indigo darken-3 white--text font-weight-bold elevation-10" small
                      v-if="happy_couple.is_active" @click="updateDisplayStatus(happy_couple)">Unpublished This</v-btn>
                    <v-btn class="ma-3 float-right indigo darken-3 white--text font-weight-bold elevation-10" small v-else
                      @click="updateDisplayStatus(happy_couple)">Published This</v-btn>
                  </v-carousel-item>
                </v-carousel>
              </v-col>
              <v-col lg="6" cols="6" class="">
                <h3 class="pt-10 pb-10">Add New Couple</h3>
                <ValidationObserver>
                  <v-form @submit.prevent="addNewCouple()">
                    <v-row>
                      <v-col lg="12" cols="12">
                        <ValidationProvider name="Couple Name" rules="required|max:100" v-slot="{ errors }">
                          <v-text-field outlined label="Couple Name" :error-messages="errors"
                            v-model="new_couple.couple_name"></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col lg="12" cols="12">
                        <ValidationProvider name="Publish This Image" rules="" v-slot="{ errors }">
                          <v-checkbox outlined label="Publish This Image" :error-messages="errors"
                            v-model="new_couple.publish"></v-checkbox>
                        </ValidationProvider>
                      </v-col>
                      <v-col lg="12" cols="12">
                        <ValidationProvider name="Upload Banner" rules="required|image" v-slot="{ errors }">
                          <v-file-input class="mt-5" label="Upload Banner" accept="image/*" clearable
                            :error-messages="errors" v-model="new_couple.image"></v-file-input>
                        </ValidationProvider>
                        <span class="text-caption red--text text-wrap font-weight-bold"><br>Note: Images should be of minimum 736px * 858px pixel</span>
                      </v-col>
                      <v-col lg="12" cols="12">
                        <v-btn class="orange darken-3 font-weight-bold white--text" type="submit"
                          :loading="add_couple_loader" :disabled="add_couple_loader">Add Couple</v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </ValidationObserver>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
    <v-snackbar v-model="display_snackbar" color="primary" class="font-weight-bold"> {{ message }}</v-snackbar>
  </v-container>
</template>
    
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { TiptapVuetify } from 'tiptap-vuetify'


export default {
  components: {
    CustomAppbar,
    CustomSidebar,
    ValidationObserver,
    ValidationProvider,
    TiptapVuetify
  },
  mounted() {
    this.getHomePageContent();
  },
  methods: {
    getHomePageContent: function () {
      this.data_loader = true;
      axios.get(`${process.env.VUE_APP_API_URL}/gethomepagecontent`).then((response) => {
        if (response.status == 200) {
          this.hero_section = response.data.hero_section;
          this.kesari_intro_section = response.data.kesari_intro;
          this.current_banners = response.data.current_banners;
          this.current_kesari_intro_banner = response.data.why_kesari_banner;
          this.marriage_by_kesari_section = response.data.marriage_by_kesari;
          this.happy_couples = response.data.happy_couples;
        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message = "Error Occur While Getting Home Page Content"
          this.display_snackbar = true
        }
      }).finally(() => {
        this.data_loader = false;
      });
    },
    updateHomePageHeroSection: function () {
      axios.post(`${process.env.VUE_APP_API_URL}/updatehomepageherosection`, this.hero_section).then((response) => {
        if (response.status == 200) {
          this.message = "Hero Section Updated Successfully";
          this.display_snackbar = true;
          this.getHomePageContent()
        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message = "Error Occur While Updating Hero page Content"
          this.display_snackbar = true
        }
      }).finally(() => {

      });
    },
    updateMarriageByKesari:function(){
      axios.post(`${process.env.VUE_APP_API_URL}/updatemarriagebykesari`, this.marriage_by_kesari_section).then((response) => {
        if (response.status == 200) {
          this.message = "Section Updated Successfully";
          this.display_snackbar = true;
          this.getHomePageContent()
        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message = "Error Occur While Updating page Content"
          this.display_snackbar = true
        }
      }).finally(() => {

      });
    },
    updateHomePageHeroSectionBanners() {
      this.banner_upload_loader = true;
      this.banners.forEach(banner => {
        this.upload_form.append('banners[]', banner);
      });
      this.preview_urls = [];
      axios.post(`${process.env.VUE_APP_API_URL}/updateherosectionbanners`, this.upload_form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.status == 200) {
          this.message = "Banner Image Updated Successfully";
          this.display_snackbar = true;
          this.getHomePageContent();
          this.banner_upload_loader = false;
        }

      }).catch((error) => {
        if (error.response) {
          if (error.response.status == 422) {
            this.$refs.imageuploadform.setErrors({
              "Upload Banners": error.response.data.errors.banners,
            });
          }
          if (error.response.status == 419) {

            this.banners = [];
            this.preview_urls = [];
            this.upload_form.delete('banners[]');
            this.banner_upload_loader = false;

          }
          this.message = "Error Occur while uploading Banner Image";
          this.display_snackbar = true;
        }

      }).finally(() => {
        this.banner_upload_loader = false;
        this.banners = [];
        this.preview_urls = [];
        this.upload_form.delete('banners[]');


      });
    },
    updateDisplayStatus: function (banner) {
      axios.post(`${process.env.VUE_APP_API_URL}/updatebannerstatus`, banner).then((response) => {
        if (response.status == 200) {
          this.message = "Image Status Has been updated",
            this.display_snackbar = true;
          this.getHomePageContent();
        }

      }).catch((error) => {
        console.log(error);
        this.message = "Image Status updating failed",
          this.display_snackbar = true;

      }).finally(() => {

      });
    },
    deleteImage: function (banner) {
      this.delete_loader = true;
      axios.post(`${process.env.VUE_APP_API_URL}/deleteimage`, banner).then((response) => {
        if (response.status == 200) {
          this.message = "Image Has been deleted",
            this.display_snackbar = true;
          this.getHomePageContent();
        }

      }).catch((error) => {
        console.log(error);
        this.message = "Image deletion failed",
          this.display_snackbar = true;

      }).finally(() => {
        this.delete_loader = false;
      });
    },
    deleteCoupleImage: function (couple) {
      this.delete_loader = true;
      axios.post(`${process.env.VUE_APP_API_URL}/deletecoupleimage`, couple).then((response) => {
        if (response.status == 200) {
          this.message = "Image Has been deleted",
            this.display_snackbar = true;
          this.getHomePageContent();
        }

      }).catch((error) => {
        console.log(error);
        this.message = "Image deletion failed",
          this.display_snackbar = true;

      }).finally(() => {
        this.delete_loader = false;
      });
    },
    addNewCouple: function () {
      this.add_couple_loader = true;
      this.new_couple_form.append('couple_name', this.new_couple.couple_name);
      this.new_couple_form.append('image', this.new_couple.image);
      this.new_couple_form.append('published', this.new_couple.publish);

      axios.post(`${process.env.VUE_APP_API_URL}/addnewmarriagebykesaricouple`, this.new_couple_form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.status == 200) {
          this.message = "New Couple Added Successfully";
          this.display_snackbar = true;
          this.new_couple_form.delete('couple_name')
          this.new_couple_form.delete('image')
          this.new_couple_form.delete('published')

          this.getHomePageContent()
        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message = "Error Occur while Adding New Couple"
          this.display_snackbar = true
        }
      }).finally(() => {
        this.new_couple_form.delete('couple_name')
        this.new_couple_form.delete('image')
        this.new_couple_form.delete('published')
        this.add_couple_loader = false;
      });

    },
    updateKesariIntroSection: function () {
      this.intro_update_loader = true;
      this.kesari_intro_form_data.append('banner', this.kesari_intro_banner);
      this.kesari_intro_form_data.append('title', this.kesari_intro_section.title);
      this.kesari_intro_form_data.append('caption', this.kesari_intro_section.caption);
      this.kesari_intro_form_data.append('body', this.kesari_intro_section.body);
      axios.post(`${process.env.VUE_APP_API_URL}/updatekesariintrosection`, this.kesari_intro_form_data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        if (response.status == 200) {
          this.message = "Kesari Intro Section Updated Successfully";
          this.display_snackbar = true;
          this.kesari_intro_form_data.delete('banner')
          this.kesari_intro_form_data.delete('title')
          this.kesari_intro_form_data.delete('caption')
          this.kesari_intro_form_data.delete('body')
          this.getHomePageContent()
        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message = "Error Occur while Updating Kesari Introduction"
          this.display_snackbar = true
        }
      }).finally(() => {
        this.intro_update_loader = false;
        this.kesari_intro_form_data.delete('banner')
        this.kesari_intro_form_data.delete('title')
        this.kesari_intro_form_data.delete('caption')
        this.kesari_intro_form_data.delete('body')
      });
    },
    onFileChange: function (images) {
      images.forEach(image => {
        this.preview_urls.push(URL.createObjectURL(image));

      });
    },
    clearImages: function () {

      this.banners = [],
        this.preview_urls = [];
    },
  },
  data: () => ({
    add_couple_loader: false,
    new_couple: {
      couple_name: '',
      publish: false,
      image: {},
    },
    happy_couples: [],
    new_couple_form: new FormData,
    intro_update_loader: false,
    kesari_intro_form_data: new FormData,
    kesari_intro_banner: {},
    current_kesari_intro_banner: {},
    delete_loader: false,
    data_loader: false,
    banner_upload_loader: false,
    preview_urls: [],
    upload_form: new FormData,
    banners: [],
    message: "",
    display_snackbar: false,
    extenstions: [],
    current_banners: [],
    hero_section: {
      id: '',
      body: '',
      title: '',
      caption: '',
      banners: [],
    },
    kesari_intro_section: {
      id: '',
      body: '',
      title: '',
      caption: '',
      default_image: '',
    },
    marriage_by_kesari_section: {
      id: '',
      body: '',
      title: '',
      caption: '',
      default_image: '',
      is_published:false,
    }
  }),
}

</script>
  
   
  