<template>
    <v-container fluid>
        <CustomAppbar></CustomAppbar>
        <CustomSidebar></CustomSidebar>
        <v-row class="pa-10">
            <v-col lg="12">
                <h3>Edit Voucher</h3>
            </v-col>
            <v-col lg="9" cols="12">
                <v-card :loading="update_loading">
                    <ValidationObserver ref="addvoucher" v-slot="{ handleSubmit }">

                        <v-form @submit.prevent="handleSubmit(updateVoucher)">
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <ValidationProvider name="Name" rules="required|max:30" v-slot="{ errors }">
                                            <v-text-field name="Name" outlined v-model="new_voucher.name" label="Name" disabled
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12">
                                        <ValidationProvider name="Description" rules="required|max:5000" v-slot="{ errors }">
                                            <v-textarea name="Description" outlined v-model="new_voucher.description" label="Description"
                                                :error-messages="errors"></v-textarea>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="4">
                                        <ValidationProvider name="Start Date" rules="required" v-slot="{ errors }">
                                            <v-text-field name="Start Date" outlined v-model="new_voucher.start_date" label="Start Date" type="date"
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="4">
                                        <ValidationProvider name="End Date" rules="required" v-slot="{ errors }">
                                            <v-text-field name="End Date" outlined v-model="new_voucher.end_date" label="End Date" type="date"
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="4">
                                        <ValidationProvider name="Max Uses User" rules="required|integer|max:100" v-slot="{ errors }">
                                            <v-text-field name="Max Uses User" outlined v-model="new_voucher.max_uses_user" label="Max Uses User"
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3">
                                        <ValidationProvider name="Active" rules="required" v-slot="{ errors }">
                                            <v-select name="Active" outlined v-model="new_voucher.is_active" :items="boolean_status" item-text="text" item-value="value" label="Active"
                                                :error-messages="errors"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3">
                                        <ValidationProvider name="Is Elite" rules="required" v-slot="{ errors }">
                                            <v-select name="Is Elite" outlined v-model="new_voucher.is_elite" :items="boolean_status" item-text="text" item-value="value" label="Is Elite"
                                                :error-messages="errors"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3">
                                        <ValidationProvider name="Discount Type" rules="required" v-slot="{ errors }">
                                            <v-select name="Discount Type" outlined v-model="new_voucher.is_fixed" :items="discount_type" item-text="text" item-value="value" label="Discount Type"
                                                :error-messages="errors"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3">
                                        <ValidationProvider name="Discount In Percentage" rules="required" v-slot="{ errors }">
                                            <v-select name="Discount In Percentage" outlined v-model="new_voucher.is_percentage" :items="boolean_status" item-text="text" item-value="value" label="Discount In Percentage"
                                                :error-messages="errors"></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="6" v-if="!new_voucher.is_percentage && !new_voucher.is_fixed" >
                                        <ValidationProvider name="Min Discount Amount" rules="required" v-slot="{ errors }">
                                            <v-text-field name="Min Discount Amount" outlined v-model="new_voucher.min_discount_amount" label="Min Discount Amount"
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="6" v-if="!new_voucher.is_percentage">
                                        <ValidationProvider name="Max Discount Amount" rules="required" v-slot="{ errors }">
                                            <v-text-field name="Max Discount Amount" outlined v-model="new_voucher.max_discount_amount" label="Max Discount Amount"
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="6" v-if="new_voucher.is_percentage && !new_voucher.is_fixed">
                                        <ValidationProvider name="Min Discount Percent" rules="required" v-slot="{ errors }">
                                            <v-text-field name="Min Discount Percent" outlined v-model="new_voucher.min_discount_percentage" label="Min Discount Percent"
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="6" v-if="new_voucher.is_percentage">
                                        <ValidationProvider name="Max Discount Percent" rules="required" v-slot="{ errors }">
                                            <v-text-field name="Max Discount Percent" outlined v-model="new_voucher.max_discount_percentage" label="Max Discount Percent"
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                   
                                    <v-col cols="12">
                                        <ValidationProvider name="Code" rules="required|max:30" v-slot="{ errors }">
                                            <v-text-field name="Code" outlined v-model="new_voucher.code" label="Code" disabled
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn type="submit" class="orange darken-3 white--text font-weight-bold">Submit</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>

            </v-col>
        </v-row>
        <v-snackbar v-model="display_snackbar" color="primary"> {{ message }}</v-snackbar>
    </v-container>
</template>
<script>
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
export default {
    mounted() {
        // this.getMembershipPlanDetails();
        this.getVoucherDetails();
    },
    data: () => ({
        new_voucher: {
        
            code:'',
            name:'',
            description:'',
            start_date:'',
            end_date:'',
            is_active:'',
            is_elite_voucher:'',
            max_uses_user:1,
            is_fixed:'',
            is_percentage:'',
            max_discount_amount:0,
            min_discount_amount:0,
            min_discount_percentage:0,
            max_discount_percentage:0,
        },
        boolean_status: [{ "text": "Yes", value: 1 },{ "text": "No", value: 0 }],
        discount_type: [{ "text": "Fixed", value: 1 },{ "text": "Upto Between Min and Max", value: 0 }],
        message: "",
        display_snackbar: false,
        plan_loading: false,
        update_loading: false,
    }),
    methods: {

        getVoucherDetails:function(){
            this.update_loading = true;
            axios.get(`${process.env.VUE_APP_API_URL}/getvoucherdetails/`+this.$route.params.voucher_id).then((response)=>{
                if(response.status == 200){
                    this.new_voucher = response.data.voucher;
                }
            }).catch((error)=>{
                console.log(error);
                this.message = "Error Occur while getting voucher details.";
                    this.display_snackbar = true;
            }).finally(()=>{
                this.update_loading = false;
            })
        },


        updateVoucher: function () {
            this.update_loading = true;
            axios.post(`${process.env.VUE_APP_API_URL}/updatevoucher`, this.new_voucher).then((response) => {
                if (response.status == 200) {
                    this.message = "Voucher Updated Sucessfully";
                    this.display_snackbar = true;
                    this.$router.push({ name: 'vouchers' });

                }
            }).catch((error) => {
                if (error.response) {

                    if (error.response.status == 422) {
                        this.$refs.addvoucher.setErrors({
                            'code': error.response.data.errors.code,
                            'Name': error.response.data.errors.name,
                            'description': error.response.data.errors.description,
                            'Is Elite': error.response.data.errors.is_elite,
                            'Start Date': error.response.data.errors.start_date,
                            'End Date': error.response.data.errors.end_date,
                            'Active': error.response.data.errors.is_active,
                            'Max Uses User': error.response.data.errors.max_uses_user,
                            'Discount Type': error.response.data.errors.is_fixed,
                            'Discount In Percentage': error.response.data.errors.is_percentage,
                            'Min Discount Amount': error.response.data.errors.min_discount_amount,
                            'Max Discount Amount': error.response.data.errors.max_discount_amount,
                            'Min Discount In Percentage': error.response.data.errors.min_discount_percentage,
                            'Max Discount In Percentage': error.response.data.error.max_discount_percentage,
                        });
                    }
                }
            }).finally(() => {
                this.update_loading = false;
            });
        }
    },
    components: { CustomAppbar, CustomSidebar, ValidationObserver, ValidationProvider }
}

</script>