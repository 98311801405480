<template>
    <div class="background_image">

        <v-container fluid fill-height justify-center>
            <v-col lg="3">
                <transition mode="out-in" name="slide-fade">
                    <v-card class="elevation-5  rounded-lg pt-1" max-width="448" v-if="!forget_password">
                        <v-img :src=logo height="100" width="200" class="mx-auto mt-5 pa-0"></v-img>
                        <v-card-text class="pt-2  pl-8">
                            <h5 class="text-h5 font-weight-semibold mb-1"> Welcome to Kesari Matrimony </h5>
                            <p class="mb-0 pt-4">Please Sign-in to your account</p>
                        </v-card-text>
                        <v-card-text class="pl-8 pr-8">
                            <v-form @submit.prevent="login">
                                <v-text-field outlined color="red" label="Email or Mobile Number" required
                                    v-model="user_auth.user_id"></v-text-field>
                                <v-text-field outlined color="red" label="Password" required v-model="user_auth.password"
                                    :type="show_password ? 'text' : 'password'"
                                    :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="show_password = !show_password"></v-text-field>
                                <!-- <v-switch inset color="red" label="Remember Me" class="pt-0 mt-n3" dense flat></v-switch> -->
                                <v-alert v-if="error_message" class="text-center font-weight-bold " color="red lighten-4">
                                    {{ error_message }}
                                </v-alert>


                                <v-btn block color="#FF4C51" class="white--text font-weight-bold mt-5 rounded-md"
                                    type="submit">Login</v-btn>
                                <p class="text-center mt-3">
                                    <v-btn link text class="text-capitalize mb-8" href="" color="#FF4C51" depressed
                                        @click="(forget_password = !forget_password)">Forget Password ?</v-btn>
                                </p>
                            </v-form>
                        </v-card-text>
                    </v-card>
                    <v-card class="elevation-5  rounded-lg pt-1" max-width="448" v-else>
                        <v-img :src=logo height="100" width="200" class="mx-auto mt-5 pa-0"></v-img>
                        <v-card-text class="pt-2  pl-8">
                            <h5 class="text-h5 font-weight-semibold mb-1 text-center">Forget password ? </h5>
                            <p class="mb-0 text-center">Please enter your registered Email Address</p>
                        </v-card-text>
                        <v-card-text class="pl-8 pr-8">
                            <v-form>
                                <v-text-field outlined color="red" label="Email Address"></v-text-field>
                                <v-btn block color="#FF4C51" class="white--text font-weight-bold mt-2 rounded-md">Verify
                                    Email</v-btn>
                                <p class="text-center mt-3">
                                    <v-btn link text class="text-capitalize mb-8" href="" color="#FF4C51" depressed
                                        @click="(forget_password = !forget_password)">Sign In</v-btn>
                                </p>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </transition>
            </v-col>
        </v-container>
    </div>
</template>
<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
export default {
    /* eslint-disable */
    mounted() {
      
    },
    computed: {
        ...mapGetters({
            userAction: 'auth/userAction'
        })
    },
    data: () => ({
        forget_password: false,
        show_password: false,
        logo: require('../assets/kesari_logo.png'),
        user_auth: {
            user_id: '',
            password: '',
        },
        error_message: "",
    }),
    methods: {
        ...mapActions('auth', [
            'login',
            "logout"]
        ),


        async login() {
            await axios.get(`${process.env.VUE_APP_URL}/sanctum/csrf-cookie`);
            await axios.post(`${process.env.VUE_APP_API_URL}/login`, this.user_auth).then((response) => {
                if (response.status == 200) {
                    this.$store.dispatch('auth/login', response.data);

                    // if (this.$store.getters.auth.isLoggedIn == "true") {
                    //     this.$router.push({ name: "allmembers" })
                    // }
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status == 401) {
                        this.error_message = error.response.data.message;
                    }
                    // this.signOut();
                }
            }).finally(() => {

            })
        },
    }





}
</script>
<style scoped>
.text-h5 {
    font-size: 1.3rem !important;
    font-weight: 500;
    line-height: 2rem;
    letter-spacing: normal !important;
}

.background_image {

    /* background-color: orangered !important; */
    height: 100vh !important;
    opacity: 1 !important;
}
</style>