<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>
    <v-row class="pl-10">
      <v-col lg="7">
        <v-card>
          <v-card-title>
            <h3>Email Details</h3>
          </v-card-title>
          <v-card-text>
            <ValidationObserver>
              <v-form @submit.prevent="updateEmailDetails()">
                <v-col lg="12" cols="12">
                  <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                    <v-text-field outlined label="Title" :error-messages="errors" v-model="email_details.title"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col lg="12" cols="12">
                  <ValidationProvider name="Caption" rules="required" v-slot="{ errors }">
                    <v-text-field outlined label="Caption" :error-messages="errors" v-model="email_details.caption"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col lg="12" cols="12">
                 
                    <tiptap-vuetify v-model="email_details.body" :extensions="extenstions"
                                label="Terms and Condition" />
                </v-col>
                <v-col lg="3">
                  <v-btn class="orange darken-3 white--text font-weight-bold mt-5" :loading="email_loader" :disabled="email_loader" type="submit">Update Email Details</v-btn>
                </v-col>
              </v-form>
            </ValidationObserver>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="7">
        <v-card>
          <v-card-title>
            <h3>Office Details</h3>
          </v-card-title>
          <v-card-text>
            <ValidationObserver>
              <v-form @submit.prevent="updateOfficeDetails()">
                <v-col lg="12" cols="12">
                  <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                    <v-text-field outlined label="Title" :error-messages="errors" v-model="office_details.title"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col lg="12" cols="12">
                  <ValidationProvider name="Caption" rules="required" v-slot="{ errors }">
                    <v-text-field outlined label="Caption" :error-messages="errors" v-model="office_details.caption"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col lg="12" cols="12">
                 
                    <tiptap-vuetify v-model="office_details.body" :extensions="extenstions"
                                label="Terms and Condition" />
                </v-col>
                <v-col lg="3">
                  <v-btn class="orange darken-3 white--text font-weight-bold mt-5" :loading="office_loader" :disabled="office_loader" type="submit">Update Office Details</v-btn>
                </v-col>
              </v-form>
            </ValidationObserver>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="7">
        <v-card>
          <v-card-title>
            <h3>Contact Details</h3>
          </v-card-title>
          <v-card-text>
            <ValidationObserver>
              <v-form @submit.prevent="updateContactDetails()" ref="phoneform">
                <v-col lg="12" cols="12">
                  <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
                    <v-text-field outlined label="Title" :error-messages="errors" v-model="phone_details.title"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col lg="12" cols="12">
                  <ValidationProvider name="Caption" rules="required" v-slot="{ errors }">
                    <v-text-field outlined label="Caption" :error-messages="errors" v-model="phone_details.caption"></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col lg="12" cols="12">
                 
                    <tiptap-vuetify v-model="phone_details.body" :extensions="extenstions"
                                label="Terms and Condition" />
                </v-col>
                <v-col lg="3">
                  <v-btn class="orange darken-3 white--text font-weight-bold mt-5" :loading="phone_loader" :disabled="phone_loader" type="submit">Update Contact Details</v-btn>
                </v-col>
              </v-form>
            </ValidationObserver>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="display_snackbar" color="primary" class="font-weight-bold"> {{ message }}</v-snackbar>
  </v-container>
</template>
    
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';
import { TiptapVuetify } from 'tiptap-vuetify'


export default {
  components: {
    CustomAppbar,
    CustomSidebar,
    ValidationProvider,
    ValidationObserver,
    TiptapVuetify
  },
  mounted() {
    this.getContactUsPageDetails();
  },
  methods: {

    getContactUsPageDetails: function () {
      axios.get(`${process.env.VUE_APP_API_URL}/getcontactuspagedetails`).then((response) => {
        if (response.status == 200) {
          this.phone_details = response.data.phone_details;
          this.office_details = response.data.office_details;
          this.email_details = response.data.email_details;
        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message = "Error Occur While Getting About Us Page Content"
          this.display_snackbar = true
        }
      }).finally(() => {

      });
    },
    updateContactDetails: function () {
      this.phone_loader =  true;
      axios.post(`${process.env.VUE_APP_API_URL}/updatecontactdetails`,this.phone_details).then((response) => {
        if (response.status == 200) {
          this.message="Contact Details Updated Successfully.";
          this.display_snackbar =  true;

        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message="Error Occur while updating Contact Details.";
          this.display_snackbar =  true;
        }
      }).finally(() => {
        this.phone_loader =  false;
      });
    },
    updateOfficeDetails: function () {
      this.office_loader =  true;
      axios.post(`${process.env.VUE_APP_API_URL}/updateofficedetails`,this.office_details).then((response) => {
        if (response.status == 200) {
          this.message="Office Details Updated Successfully.";
          this.display_snackbar =  true;

        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message="Error Occur while updating Office Details.";
          this.display_snackbar =  true;
        }
      }).finally(() => {
        this.office_loader =  false;
      });
    },
    updateEmailDetails: function () {
      this.email_loader =  true;
      axios.post(`${process.env.VUE_APP_API_URL}/updateemaildetails`,this.email_details).then((response) => {
        if (response.status == 200) {
          this.message="Email Details Updated Successfully.";
          this.display_snackbar =  true;

        }
      }).catch((error) => {
        if (error.response.status == 422) {

          this.message="Error Occur while updating Email Details.";
          this.display_snackbar =  true;
        }
      }).finally(() => {
        this.email_loader =  false;
      });
    }
  },


  data: () => ({
    message: "",
    display_snackbar: false,
    email_loader: false,
    extenstions: [],
    phone_loader: false,
    office_loader: false,
    phone_details: {
      id: '',
      body: '',
      title: '',
      caption: ''
    },
    office_details: {
      id: '',
      body: '',
      title: '',
      caption: ''
    },
    email_details: {
      id: '',
      body: '',
      title: '',
      caption: ''
    }



  }),
}

</script>
  
   
  