import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index';
/* eslint-disable */
Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        component: require('../views/AdminLogin.vue').default,
        meta: {
            auth: false,
        },
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: require('../views/dashboard/dashboardPage.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: require('../views/AdminLogin.vue').default,
        meta: {
            auth: false,
        },
    },
    {
        path: '/allmembers',
        name: 'allmembers',
        component: require('../views/members/allMembers.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/paidmembers',
        name: 'paidmembers',
        component: require('../views/members/paidMembers.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/elitemembers',
        name: 'elitemembers',
        component: require('../views/members/eliteMembers.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/member/:member_id',
        name: 'viewmember',
        component: require('../views/members/memberView.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/member/edit/:member_id',
        name: 'editmember',
        component: require('../views/members/editMember.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/shortlisted',
        name: 'shortlisted',
        component: require('../views/memberActivities/shortlistedActivities.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/membershipplans',
        name: 'membershipplans',
        component: require('../views/membershipPlans/allPlans.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/editplan/:plan_id',
        name: 'editplan',
        component: require('../views/membershipPlans/editPlan.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/newplan',
        name: 'newplan',
        component: require('../views/membershipPlans/newPlan.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/blogs',
        name: 'blogs',
        component: require('../views/blogs/allBlogs.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/blog/edit/:blog_id',
        name: 'editblog',
        component: require('../views/blogs/editBlog.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/blog/new',
        name: 'newBlog',
        component: require('../views/blogs/newBlog.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/religion',
        name: 'religion',
        component: require('../views/newDetails/religion/allReligion.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/caste',
        name: 'caste',
        component: require('../views/newDetails/caste/allCaste.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/subcaste',
        name: 'subcaste',
        component: require('../views/newDetails/subcaste/allSubcaste.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/country',
        name: 'country',
        component: require('../views/newDetails/country/allCountry.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/states',
        name: 'states',
        component: require('../views/newDetails/states/allStates.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/cities',
        name: 'cities',
        component: require('../views/newDetails/cities/allCities.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/education',
        name: 'education',
        component: require('../views/newDetails/education/allEducation.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/mothertongue',
        name: 'mothertongue',
        component: require('../views/newDetails/motherTongue/allMotherTongue.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/emailtemplates',
        name: 'emailtemplates',
        component: require('../views/emailTemplates/allEmailTemplates.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/newemailtemplate',
        name: 'newemailtemplate',
        component: require('../views/emailTemplates/newEmailTemplate.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/editemailtemplate/:id',
        name: 'editemailtemplate',
        component: require('../views/emailTemplates/editEmailTemplate.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/smstemplates',
        name: 'smstemplates',
        component: require('../views/smsTemplates/allSMSTemplates.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/contactqueries',
        name: 'contactqueries',
        component: require('../views/queries/contactQueries.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/elitequeries',
        name: 'elitequeries',
        component: require('../views/queries/eliteQueries.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: require('../views/feedback/allFeedback.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/homepage',
        name: 'homepage',
        component: require('../views/siteSettings/homePage.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/aboutuspage',
        name: 'aboutuspage',
        component: require('../views/siteSettings/aboutUsPage.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/elitepage',
        name: 'elitepage',
        component: require('../views/siteSettings/elitePage.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/contactus',
        name: 'contactus',
        component: require('../views/siteSettings/contactUsPage.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/termsandconditions',
        name: 'termsandconditions',
        component: require('../views/siteSettings/termsAndConditionPage.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/privacypolicies',
        name: 'privacypolicies',
        component: require('../views/siteSettings/privacyPolicyPage.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/staff',
        name: 'staff',
        component: require('../views/staff/allStaff.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/editstaff/:staff_id',
        name: 'editstaff',
        component: require('../views/staff/editStaff.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/newstaff',
        name: 'newstaff',
        component: require('../views/staff/newStaff.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/vouchers',
        name: 'vouchers',
        component: require('../views/discountVouchers/allVouchers.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/addvoucher',
        name: 'addvoucher',
        component: require('../views/discountVouchers/addVoucher.vue').default,
        meta: {
            auth: true,
        },
    },
    {
        path: '/editvoucher/:voucher_id',
        name: 'editvoucher',
        component: require('../views/discountVouchers/editVoucher.vue').default,
        meta: {
            auth: true,
        },
    },

    {
        path: '/404',
        name: '404',
        component: require('../views/static/404Page.vue').default,
        meta: {
            auth: false,
        }
    },
    {
        path: '/500',
        name: '500',
        component: require('../views/static/500Page.vue').default,
        meta: {
            auth: false,
        }
    },
    {
        path: '/429',
        name: '429',
        component: require('../views/static/429Page.vue').default,
        meta: {
            auth: false,
        }
    },
    {
        path: '/403',
        name: '403',
        component: require('../views/static/403Page.vue').default,
        meta: {
            auth: false,
        }
    },



]

const router = new VueRouter({
    mode: 'history',
    routes,

})


// routing guard
// router.beforeEach((to, from, next) => {

//   if(to.meta.auth == false){
//     next()
//   }else{
//     if(store.state.auth.isLoggedIn){
//       next()
//     }else{
//       next({name:"Home"})
//     }
//   }

// });

router.beforeEach((to, from, next) => {
    if (to.meta.auth == true) {
        if (store.state.auth.isLoggedIn) {
            next();
        } else {
            next({ name: "Home" });
        }
    } else {
        if (to.name == "home" || to.name == "login") {
            if (store.state.auth.isLoggedIn) {
                next({ name: "dashboard" });
            } else {
                next();
            }
        }
    }
});


export default router