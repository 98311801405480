<template>
    <v-container fluid class="pa-10">
        <CustomSidebar></CustomSidebar>
        <CustomAppbar></CustomAppbar>
        <v-row>
            <!-- <v-col lg="12" cols="12">
                <v-spacer></v-spacer>
                <v-btn class="elevation-4 font-weight-bold white--text" color="orange darken-4">Suspend</v-btn>
            </v-col> -->
            <v-col xs="12" cols="12">
                <h3 class="title">Member Basic Information</h3>
            </v-col>
            <v-col xl="11" lg="12" sm="12" cols="12" v-if="user_basic_information.is_verified" class="ma-auto">
                <v-alert color="green" dismissible elevation="16" type="success">
                    User Has been Verified
                </v-alert>
            </v-col>
            <v-col xl="8" lg="10" sm="12" cols="12" v-if="user_basic_information.is_suspended" class="">
                <v-alert type="error">{{ "User Has Been Suspend For : " + user_basic_information.suspend_reason }}</v-alert>
            </v-col>
            <v-col xl="12" lg="12" sm="12" cols="12" >
                <v-dialog v-model="suspend_dialog" width="500" v-if="user_basic_information.is_suspended">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn elevation="4" v-on="on" v-bind="attrs" medium 
                            class="font-weight-bold text-right white--text  text-capitalize ma-4 ml-0" color="green darken-2">

                            Unlock User
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="headline lighten-2" primary-title>
                            Confirmation
                        </v-card-title>

                        <v-card-text>
                            Are you sure you want to unlock user
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red" text @click="unlockUser" :loading="loaders.suspend_loader"
                                :disabled="loaders.suspend_loader">
                                Confirm
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="suspend_dialog" width="500" v-else>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn elevation="4" v-on="on" v-bind="attrs" medium 
                            class="font-weight-bold text-right white--text  text-capitalize ma-4" color="red">

                            Suspend User
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="headline lighten-2" primary-title>
                            Confirmation
                        </v-card-title>

                        <v-card-text>
                            Please provide reason suspending User
                        </v-card-text>
                        <v-card-text>
                            <v-form @submit.prevent="suspendUser">
                                <v-row>
                                    <v-col cols="12">
                                        <v-textarea solo outlined v-model="user_basic_information.suspend_reason" required>

                                        </v-textarea>
                                    </v-col>
                                    <v-col cols="12" v-if="user_basic_information.suspend_reason != null">
                                        <v-btn :disabled="user_basic_information.suspend_reason.length < 10" type="submit"
                                            :laoding="loaders.suspend_loader" color="primary">Suspend</v-btn>
                                    </v-col>
                                </v-row>


                            </v-form>
                        </v-card-text>
                        <v-divider></v-divider>

                        <!-- <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red" text @click="sendExpressInterest" :loading="loaders.send_interest_loader"
                                :disabled="loaders.send_interest_loader">
                                Confirm
                            </v-btn>
                        </v-card-actions> -->
                    </v-card>
                </v-dialog>
                <v-dialog v-model="delete_dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn elevation="4" v-on="on" v-bind="attrs" medium
                            class="font-weight-bold text-right white--text  text-capitalize ma-4" color="red">

                            Delete User
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="headline lighten-2" primary-title>
                            Confirmation
                        </v-card-title>

                        <v-card-text>
                            Are you sure you want to delete user. Once Deleted it cannot be Restored
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red" text @click="deleteUser" :loading="loaders.delete_loader"
                                :disabled="loaders.delete_loader">
                                Delete
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>

            <v-col xl="3" lg="4" sm="5" md="4">
                <v-card class="rounded-lg">
                    <v-carousel cycle hide-delimiter-background show-arrows-on-hover interval="3000" continuous
                        delimiter-icon="mdi-minus" height="345">
                        <v-carousel-item v-for="(image, i) in profile_images" :key="i">
                            <v-img :src="image.uri" height="100%" width="100%" contain>
                                <v-avatar color="orange darken-2" class=" ma-3 font-weight-bold">{{ i + 1 }}</v-avatar>
                            </v-img>
                        </v-carousel-item>
                    </v-carousel>
                    <v-card-text>
                        <v-list>
                            <v-list-item dense class="pa-0">
                                <v-list-item-subtitle>
                                    <h5 class="text-sm">Prospec: <span class="text-body-2 text-wrap text-justify">{{
                                        user_basic_information.about_prospect }}</span>
                                    </h5>
                                </v-list-item-subtitle>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col xl="9" sm="12" class="">

                <v-tabs show-arrows grow background-color="transparent" align-with-title class="font-weight-bold"
                    slider-color="red" slider-size="1" active-class="red lighten-5 font-weight-bold red--text"
                    v-model="current_tab">
                    <v-tab class="ma-1 pa-2 ">
                        <v-icon color="" left>
                            mdi-account
                        </v-icon>Personal
                    </v-tab>
                    <v-tab class="ma-1 pa-2 ">
                        <v-icon color="" left>
                            mdi-family-tree
                        </v-icon>Family
                    </v-tab>
                    <v-tab class="ma-1 pa-2 ">
                        <v-icon color="" left>
                            mdi-dharmachakra
                        </v-icon>Religion
                    </v-tab>
                    <v-tab class="ma-1 pa-2 ">
                        <v-icon color="" left>
                            mdi-briefcase
                        </v-icon>Employee
                    </v-tab>
                    <v-tab class="ma-1 pa-2 ">
                        <v-icon color="" left>
                            mdi-link-variant
                        </v-icon>Social
                    </v-tab>
                    <v-tab class="ma-1 pa-2 ">
                        <v-icon color="" left>
                            mdi-file-document
                        </v-icon>Documents
                    </v-tab>
                    <v-tab class="ma-1 pa-2 ">
                        <v-icon color="" left>
                            mdi-account-eye
                        </v-icon>Preferences
                    </v-tab>
                    <v-tab class="ma-1 pa-2 ">
                        <v-icon color="" left>
                            mdi-currency-rupee
                        </v-icon>Billing & Plan
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="current_tab" class="transparent rounded-lg">
                    <v-tab-item class="mt-4 ">
                        <v-card class="elevation-3 rounded-lg" outlined rounded="lg">
                            <v-card-text>
                                <v-row>
                                    <v-col lg="6">
                                        <v-list>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Name: <span class="text-body-2">{{
                                                        user_basic_information.first_name
                                                        + " " + user_basic_information.middle_name + " " +
                                                        user_basic_information.last_name }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Mother Name: <span class="text-body-2">{{
                                                        user_basic_information.mother_name }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Gender: <span class="text-body-2">{{
                                                        user_basic_information.gender
                                                    }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Date of Birth: <span class="text-body-2">{{
                                                        user_basic_information.date_of_birth }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Maritial Status: <span class="text-body-2">{{
                                                        user_basic_information.maritial_status }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Mobile Number: <span class="text-body-2">{{
                                                        user_basic_information.mobile_number }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Email Address: <span class="text-body-2">{{
                                                        user_basic_information.email }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col lg="6">
                                        <v-list>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Height: <span class="text-body-2">{{
                                                        user_personal_information.height }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Body Type: <span class="text-body-2">{{
                                                        user_personal_information.body_type }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Skin Complexity: <span class="text-body-2">{{
                                                        user_personal_information.skin_complexity
                                                    }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Blood Group: <span class="text-body-2">{{
                                                        user_personal_information.blood_group }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Health Information: <span class="text-body-2"
                                                            v-if="user_personal_information.health_information !== undefined && user_personal_information.health_information !== null">{{
                                                                user_personal_information.health_information.toString()
                                                            }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Is Physically Disabled: <span class="text-body-2"
                                                            v-if="user_personal_information.is_physically_disabled">Yes</span>
                                                        <span class="text-body-2" v-else>No</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Hobbies: <span class="text-body-2"
                                                            v-if="user_personal_information.hobbies !== undefined && user_personal_information.hobbies !== null">{{
                                                                user_personal_information.hobbies.toString() }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>

                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- family information-->
                    <v-tab-item class="mt-4 ">
                        <v-card class="elevation-3 rounded-lg" outlined rounded="lg">
                            <v-card-text>
                                <v-row>
                                    <v-col lg="6">
                                        <v-list>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Family Type: <span class="text-body-2">{{
                                                        user_family_information.family_type }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Family Value: <span class="text-body-2">{{
                                                        user_family_information.family_value }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Family Affluence: <span class="text-body-2">{{
                                                        user_family_information.family_affluence }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Father Living Status: <span class="text-body-2">{{
                                                        user_family_information.father_living_status }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Mother Living Status: <span class="text-body-2">{{
                                                        user_family_information.mother_living_status }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Married Brothers: <span class="text-body-2">{{
                                                        user_family_information.no_of_married_brothers }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Married Sisters:<span class="text-body-2">{{
                                                        user_family_information.no_of_married_sisters }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Unmarried Brothers: <span class="text-body-2">{{
                                                        user_family_information.no_of_unmarried_brothers }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Unmarried Sisters:<span class="text-body-2">{{
                                                        user_family_information.no_of_unmarried_sisters }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>

                                        </v-list>
                                    </v-col>
                                    <v-col lg="6">
                                        <v-list>
                                            <v-list-item dense class=""
                                                v-if="user_family_information.father_living_status == 'Employed'">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Father Employeed As: <span
                                                            class="text-body-2 text-wrap">{{
                                                                user_family_information.father_employed_as }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class=""
                                                v-if="user_family_information.father_living_status == 'Employed'">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Father Employeed With: <span
                                                            class="text-body-2 text-wrap">{{
                                                                user_family_information.father_employed_with }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class=""
                                                v-if="user_family_information.father_living_status == 'Retired'">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Father Retired As: <span
                                                            class="text-body-2 text-wrap">{{
                                                                user_family_information.father_retired_as }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class=""
                                                v-if="user_family_information.father_living_status == 'Retired'">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Father Retired With: <span
                                                            class="text-body-2 text-wrap">{{
                                                                user_family_information.father_retired_from }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class=""
                                                v-if="user_family_information.father_living_status == 'Business'">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Father Nature of Business: <span
                                                            class="text-body-2 text-wrap">{{
                                                                user_family_information.father_nature_of_business }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class=""
                                                v-if="user_family_information.mother_living_status == 'Employed'">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Mother Employeed As: <span
                                                            class="text-body-2 text-wrap">{{
                                                                user_family_information.mother_employed_as }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class=""
                                                v-if="user_family_information.mother_living_status == 'Employed'">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Mother Employeed With: <span
                                                            class="text-body-2 text-wrap">{{
                                                                user_family_information.mother_employed_with }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class=""
                                                v-if="user_family_information.mother_living_status == 'Retired'">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Mother Retired As: <span
                                                            class="text-body-2 text-wrap">{{
                                                                user_family_information.mother_retired_as }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class=""
                                                v-if="user_family_information.mother_living_status == 'Retired'">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Mother Retired With: <span
                                                            class="text-body-2 text-wrap">{{
                                                                user_family_information.mother_retired_from }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class=""
                                                v-if="user_family_information.mother_living_status == 'Business'">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Mother Nature of Business: <span
                                                            class="text-body-2 text-wrap">{{
                                                                user_family_information.mother_nature_of_business }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>

                                            <v-list-item dense class="">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Family Location: <span
                                                            class="text-body-2 text-wrap">{{
                                                                user_family_information.family_location }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Native Location: <span
                                                            class="text-body-2 text-wrap">{{
                                                                user_family_information.native_place }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- religion information-->
                    <v-tab-item class="mt-4 ">
                        <v-card class="elevation-3 rounded-lg" outlined rounded="lg">
                            <v-card-text>
                                <v-row>
                                    <v-col lg="6">
                                        <v-list>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Religon: <span class="text-body-2">{{
                                                        user_religion_information.religion }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Caste: <span class="text-body-2">{{
                                                        user_religion_information.caste }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>

                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Gotra: <span class="text-body-2">{{
                                                        user_religion_information.gotra }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>

                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Mother Tongue: <span class="text-body-2">{{
                                                        user_religion_information.mother_tongue }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Caste No Bar: <span class="text-body-2"
                                                            v-if="user_religion_information.is_caste_nobar">Yes</span>
                                                        <span class="text-body-2" v-else>No</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!--Employee information-->
                    <v-tab-item class="mt-4 ">
                        <v-card class="elevation-3 rounded-lg" outlined rounded="lg">
                            <v-card-text>
                                <v-row>
                                    <v-col lg="6" cols="12" md="6">
                                        <v-list>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Employee Name: <span class="text-body-2">{{
                                                        user_employee_information.employee_with }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Employee Location: <span class="text-body-2">{{
                                                        user_employee_information.employee_location }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Annual Income: <span class="text-body-2">{{
                                                        user_employee_information.annual_income }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Highest Education: <span class="text-body-2">{{
                                                        user_employee_information.highest_education }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Speciailized In: <span class="text-body-2">{{
                                                        user_employee_information.specialized_in }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">College Name: <span class="text-body-2">{{
                                                        user_employee_information.college_name }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col lg="6" cols="12" md="6">
                                        <v-list>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Occupation: <span class="text-body-2">{{
                                                        user_employee_information.occupation }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Keep Income Private: <span class="text-body-2"
                                                            v-if="user_employee_information.keep_income_private">Yes</span>
                                                        <span class="text-body-2" v-else>No</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- social information-->
                    <v-tab-item class="mt-4 ">
                        <v-card class="elevation-3 rounded-lg" outlined rounded="lg">
                            <v-card-text>
                                <v-row>
                                    <v-col lg="6">
                                        <v-list>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Eating Habbits: <span class="text-body-2">{{
                                                        user_social_information.eating_habbit }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Drinking Habbit: <span class="text-body-2">{{
                                                        user_social_information.drinking_habbit }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>

                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Smoking Habbit: <span class="text-body-2">{{
                                                        user_social_information.smoking_habbit }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>


                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                    <!--Documents-->
                    <v-tab-item class="mt-4 ">
                        <v-card class="elevation-3 rounded-lg" outlined rounded="lg">
                            <v-data-table :headers="interested_headers" :items="user_documents"
                                :loading="loaders.list_loader">
                                <template v-slot:[`item.uri`]="{ item }">
                                    <v-btn :href="item.uri" link icon target="_top"><v-icon
                                            color="primary">mdi-shield-link-variant</v-icon></v-btn>
                                </template>
                                <template v-slot:[`item.status`]="{ }">
                                    <v-chip color="warning" small class="elevation-4 font-weight-bold">Pending
                                        Approval</v-chip>
                                </template>


                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-dialog v-model="dialog" width="300">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn link text icon v-on="on" v-bind="attrs" @click="dialog = true">
                                                <v-icon medium color="green" class="ma-1">
                                                    mdi-check
                                                </v-icon>
                                            </v-btn>
                                        </template>

                                        <v-card>
                                            <v-card-title class="headline lighten-2" primary-title>
                                                Confirmation
                                            </v-card-title>

                                            <v-card-text>
                                                Are you sure you want to Approve document. Once Approve it cannot be revert.
                                            </v-card-text>

                                            <v-divider></v-divider>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="red" text @click="approveDocument(item.id)"
                                                    :loading="loaders.reject_loader">
                                                    Confirm
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                    <v-dialog v-model="reject_dialog" width="300">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn link text icon v-on="on" v-bind="attrs" @click="reject_dialog = true">
                                                <v-icon medium color="red" class="ma-1">
                                                    mdi-window-close
                                                </v-icon>
                                            </v-btn>
                                        </template>

                                        <v-card>
                                            <v-card-title class="headline lighten-2" primary-title>
                                                Confirmation
                                            </v-card-title>

                                            <v-card-text>
                                                Are you sure you want to Reject document.
                                            </v-card-text>

                                            <v-divider></v-divider>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="red" text @click="rejectDocument(item.id)"
                                                    :loading="loaders.reject_loader">
                                                    Confirm
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-tab-item>
                    <!-- family information-->
                    <v-tab-item class="mt-4 ">
                        <v-card class="elevation-3 rounded-lg" outlined rounded="lg">
                            <v-card-text>
                                <v-row>
                                    <v-col lg="6">
                                        <v-list>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Expected Height: <span class="text-body-2">{{
                                                        user_preferences.expected_height }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Caste Preference: <span class="text-body-2"
                                                            v-if="user_preferences.caste_preferences !== undefined && user_preferences.caste_preferences !== null">{{
                                                                user_preferences.caste_preferences.toString() }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Age From: <span class="text-body-2">{{
                                                        user_preferences.age_from }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Age Upto: <span class="text-body-2">{{
                                                        user_preferences.age_upto }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Eating Habbit: <span class="text-body-2">{{
                                                        user_preferences.eating_habbit }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Drinking Habbit: <span class="text-body-2">{{
                                                        user_preferences.drinking_habbit }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Smoking Habbit:<span class="text-body-2">{{
                                                        user_preferences.smoking_habbit }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Highest Education: <span class="text-body-2">{{
                                                        user_preferences.highest_education }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>

                                        </v-list>
                                    </v-col>
                                    <v-col lg="6">
                                        <v-list>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Expected Income: <span class="text-body-2">{{
                                                        user_preferences.annual_income }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Family Type: <span class="text-body-2">{{
                                                        user_preferences.family_type }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Family Value: <span class="text-body-2">{{
                                                        user_preferences.family_value }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Family Affluence: <span class="text-body-2">{{
                                                        user_preferences.family_affluence }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Allow Dosh In Patrika: <span
                                                            v-if="user_preferences.is_dosh_present"
                                                            class="text-body-2">Yes</span>
                                                        <span class="text-body-2" v-else>NO</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Manglik Acceptable: <span
                                                            v-if="user_preferences.is_manglik"
                                                            class="text-body-2">Yes</span>
                                                        <span class="text-body-2" v-else>NO</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>

                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <!-- billing and plan-->
                    <v-tab-item class="mt-4 ">
                        <v-card class="elevation-3 rounded-lg" outlined rounded="lg">
                            <v-card-text>
                                <v-row>

                                    <v-col lg="6">
                                        <v-list>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h2 class="">Current Plan:
                                                    </h2>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Current Plan: <span class="text-body-2"
                                                            v-if="this.user_subscription !== undefined">{{
                                                                this.user_subscription.membership_plan.title }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Purchased Date: <span class="text-body-2"
                                                            v-if="this.user_subscription !== undefined"> {{
                                                                this.user_subscription.transaction.created_at }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Invoice Number: <span class="text-body-2"
                                                            v-if="this.user_subscription !== undefined">{{
                                                                this.user_subscription.transaction.order_id }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Amount Paid: <span class="text-body-2"
                                                            v-if="this.user_subscription !== undefined">{{
                                                                this.user_subscription.transaction.final_amount }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>

                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Valid Till: <span class="text-body-2"
                                                            v-if="this.user_subscription !== undefined">{{
                                                                this.user_subscription.expire_on }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Plan Status:
                                                        <span
                                                            v-if="this.user_subscription !== undefined && this.user_subscription.is_active"
                                                            class="text-body-2">
                                                            Active
                                                        </span>
                                                        <span v-else class="text-body-2">
                                                            Not Active
                                                        </span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col lg="6" class="">
                                        <v-list>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">HighLight Profile: <span
                                                            v-if="this.user_subscription !== undefined && this.user_subscription.highlight_profile"
                                                            class="text-body-2">
                                                            Yes
                                                        </span>
                                                        <span v-else class="text-body-2">
                                                            No
                                                        </span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Current Views Allowed: <span class="text-body-2"
                                                            v-if="this.user_subscription !== undefined">
                                                            {{ this.user_subscription.pending_profileview_allowed }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Current Contact View Allowed: <span
                                                            class="text-body-2"
                                                            v-if="this.user_subscription !== undefined">{{
                                                                this.user_subscription.pending_contactview_allowed }}
                                                        </span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Current Express Interest Allowed: <span
                                                            class="text-body-2 "
                                                            v-if="this.user_subscription !== undefined">{{
                                                                this.user_subscription.pending_express_interest_allowed
                                                            }}</span></h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Personal Consulting: <span
                                                            v-if="this.user_subscription !== undefined && this.user_subscription.personal_consulting"
                                                            class="text-body-2">
                                                            Activated
                                                        </span>
                                                        <span v-else class="text-body-2">
                                                            Not Activated
                                                        </span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Personal Consulting Status:
                                                        <span
                                                            v-if="this.user_subscription !== undefined && this.user_subscription.personal_consulting_completion_status"
                                                            class="text-body-2">
                                                            Completed
                                                        </span>
                                                        <span v-else class="text-body-2">
                                                            Not Completed
                                                        </span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item dense class="pa-0">
                                                <v-list-item-subtitle>
                                                    <h5 class="text-sm">Personal Consulting Durations (Hours): <span
                                                            class="text-body-2"
                                                            v-if="this.user_subscription !== undefined">{{
                                                                this.user_subscription.personal_consulting_duration_hours
                                                            }}</span>
                                                    </h5>
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                        </v-list>

                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>

                </v-tabs-items>
            </v-col>
            <v-col xs="12">
                <h3 class="title">Member Subscription Information</h3>
                <v-btn elevation="4" medium class="font-weight-bold text-right white--text  text-capitalize ma-3 ml-0"
                    color="red" @click="subscription_dialog = true">
                    Add New Subscription
                </v-btn>
                <v-dialog v-model="cancel_subscription_dialog" width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn elevation="4" v-on="on" v-bind="attrs" medium
                            class="font-weight-bold text-right white--text  text-capitalize ma-4" color="red">

                            Cancel Subscription
                        </v-btn>
                    </template>

                    <v-card>
                        <v-card-title class="headline lighten-2" primary-title>
                            Confirmation
                        </v-card-title>

                        <v-card-text>
                            Are you sure you want to cancel user subscription. Once cancelled it cannot be restored.
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="red" text @click="cancelSubscription" :loading="loaders.cancel_subscription_loader"
                                :disabled="loaders.cancel_subscription_loader">
                                Cancel Subscription
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-col xl="12" sm="12" class="mb-5 mt-1">
                <v-row>
                    <v-card class="ma-1 red darken-3  elevation-2" min-width="250">
                        <v-card-text class="text-center white--text font-weight-bold naruto_font">
                            <v-icon color="white" size="30">mdi-phone-classic</v-icon>
                            <h4 class="pt-2 pb-3 text-h5">{{ active_normal_plan.pending_contactview_allowed }}</h4>
                            Remaining Contact View
                        </v-card-text>
                    </v-card>
                    <v-card class="ma-1 red darken-3  elevation-2 " min-width="250">
                        <v-card-text class="text-center white--text font-weight-bold naruto_font">
                            <v-icon color="white" size="30">mdi-hand-heart-outline</v-icon>
                            <h4 class="pt-2 pb-3 text-h5">{{ active_normal_plan.pending_express_interest_allowed }}</h4>
                            Remaining Interest
                        </v-card-text>
                    </v-card>
                    <v-card class="ma-1 red darken-3  elevation-2 " min-width="250">
                        <v-card-text class="text-center white--text font-weight-bold naruto_font">
                            <v-icon color="white" size="30">mdi-update</v-icon>
                            <h4 class="pt-2 pb-3 text-h5" v-if="active_normal_plan.expire_on">{{
                                moment(active_normal_plan.expire_on).format("DD-MMM-YYYY") }}</h4>
                            <h4 class="pt-2 pb-3 text-h5" v-else>NA</h4>
                            Membership Expire On
                        </v-card-text>
                    </v-card>
                    <v-card class="ma-1 red darken-3  elevation-2" min-width="250" v-if="highlight_profile_plan != null">
                        <v-card-text class="text-center white--text font-weight-bold naruto_font">
                            <v-icon color="white" size="30">mdi-account-star</v-icon>
                            <h4 class="pt-2 pb-3 text-h5" v-if="highlight_profile_plan.expire_on">{{
                                moment(highlight_profile_plan.expire_on).format("DD-MMM-YYYY") }}</h4>
                            <h4 class="pt-2 pb-3 text-h5" v-else>NA</h4>

                            Highlight Profile Expire On
                        </v-card-text>
                    </v-card>

                    <v-card class="ma-1 red darken-3  elevation-2" min-width="250" v-if="assisted_service_plan != null">
                        <v-card-text class="text-center white--text font-weight-bold naruto_font">
                            <v-icon color="white" size="30">mdi-assistant</v-icon>
                            <h4 class="pt-2 pb-3 text-h5" v-if="assisted_service_plan.expire_on">{{
                                moment(assisted_service_plan.expire_on).format("DD-MMM-YYYY") }}</h4>
                            <h4 class="pt-2 pb-3 text-h5" v-else>NA</h4>
                            Assissted Service Duration
                        </v-card-text>
                    </v-card>

                    <v-card class="ma-1 red darken-3  elevation-2" min-width="250" v-if="personal_consulting_plan != null">
                        <v-card-text class="text-center white--text font-weight-bold naruto_font">
                            <v-icon color="white" size="30">mdi-assistant</v-icon>
                            <h4 class="pt-2 pb-3 text-h5"
                                v-if="personal_consulting_plan.personal_consulting_duration_hours > 0">{{
                                    personal_consulting_plan.personal_consulting_duration_hours }}</h4>
                            <h4 class="pt-2 pb-3 text-h5" v-else>NA</h4>
                            Personal Consulting Pending Duration
                        </v-card-text>
                    </v-card>
                    <v-col cols="12" class="ma-0 pa-0 mt-5">
                        <v-card class="elevation-1">
                            <v-card-text>
                                <h3>Member Subscriptions</h3>
                            </v-card-text>
                            <v-card-text>
                                <v-data-table :headers="subscription_header" :items="subscriptions"
                                    :loading="loaders.list_loader">
                                    <template v-slot:[`item.plan_name`]="{ item }">
                                        {{ item.membership_plan.title }}
                                    </template>

                                    <template v-slot:[`item.plan_type`]="{ item }">
                                        <span v-if="item.bonus_plan">Bonus Plan</span>
                                        <span v-if="item.is_normal_plan">Normal Plan</span>
                                        <span v-if="item.is_elite_plan">Elite Plan</span>
                                    </template>
                                    <template v-slot:[`item.is_active`]="{ item }">
                                        <v-chip small v-if="item.is_active" color="green"
                                            class="white--text elevation-2">Active</v-chip>
                                        <v-chip small v-else color="red" class="white--text elevation-2">Expired</v-chip>
                                    </template>
                                    <template v-slot:[`item.payment_status`]="{ item }">
                                        <span v-if="item.transaction == null">Not Available</span>
                                        <span v-else>{{ item.transaction.pg_order_status }}</span>
                                    </template>
                                    <template v-slot:[`item.expire_on`]="{ item }">
                                        {{ moment(item.expire_on).format("DD-MMM-YYYY") }}
                                    </template>
                                    <!-- <template v-slot:[`item.actions`]="{  }">
                    <v-btn link text icon>
                        <v-icon small color="primary" class="ma-1">
                            mdi-eye
                        </v-icon>
                    </v-btn>
                    <v-btn link text icon>
                        <v-icon small color="red" class="ma-1">
                            mdi-delete
                        </v-icon>
                    </v-btn>
                </template> -->
                                </v-data-table>
                            </v-card-text>

                        </v-card>

                    </v-col>
                </v-row>


            </v-col>

            <v-col xs="12" sm="12" lg="12" cols="12" class="mb-7">
                <h3 class="title">Member Activity Information</h3>
            </v-col>

            <v-col lg="3">
                <v-card class="" max-width="400">
                    <v-card-title class="white--text orange darken-3 font-weight-bold">
                        Profile View History
                        <v-spacer></v-spacer>
                        <v-btn color="white" class="text--primary" fab small>
                            <v-icon>mdi-account-clock</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-divider></v-divider>
                    <v-virtual-scroll :items="user_view_history" :item-height="90" height="400">

                        <template v-slot:default="{ item }">
                            <v-list>
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-avatar color="orange darken-3" size="56" class="white--text font-weight-bold">
                                            {{ item.viewed_user.initials }}
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title> {{ item.viewed_user.initials + " " +
                                            item.viewed_user.first_name + " " + item.viewed_user.middle_name + " " +
                                            item.viewed_user.last_name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{ item.viewed_user.date_of_birth }} / {{
                                            "Age:" + item.viewed_user.age }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="blue darken-1" small :href="item.viewed_user.id">
                                            <v-icon color="" size="18">
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-col>
            <v-col lg="3">
                <v-card class="" max-width="400">
                    <v-card-title class="white--text orange darken-3 font-weight-bold">
                        Shortlisted Profile
                        <v-spacer></v-spacer>
                        <v-btn color="white" class="text--primary" fab small>
                            <v-icon>mdi-book-account-outline</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-divider></v-divider>
                    <v-virtual-scroll :items="user_shortlist" :item-height="90" height="400">

                        <template v-slot:default="{ item }">
                            <v-list>
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-avatar color="orange darken-3" size="56" class="white--text font-weight-bold">
                                            {{ item.shortlisted.initials }}
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title> {{ item.shortlisted.initials + " " + item.shortlisted.first_name
                                            + " " + item.shortlisted.middle_name + " " + item.shortlisted.last_name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{ item.shortlisted.date_of_birth }} / {{
                                            "Age:" + item.shortlisted.age }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="blue darken-1" small :href="item.shortlisted.id">
                                            <v-icon color="" size="18">
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-col>
            <v-col lg="3">
                <v-card class="" max-width="400">
                    <v-card-title class="white--text orange darken-3 font-weight-bold">
                        Express Interest Profiles
                        <v-spacer></v-spacer>
                        <v-btn color="white" class="text--primary" fab small>
                            <v-icon>mdi-account-box-multiple-outline</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-divider></v-divider>
                    <v-virtual-scroll :items="user_interest" :item-height="90" height="400">

                        <template v-slot:default="{ item }">
                            <v-list>
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-avatar color="orange darken-3" size="56" class="white--text font-weight-bold">
                                            {{ item.interested_user.initials }}
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title> {{ item.interested_user.initials + " " +
                                            item.interested_user.first_name + " " + item.interested_user.middle_name + " " +
                                            item.interested_user.last_name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{ item.interested_user.date_of_birth }} /
                                            {{ "Age:" + item.interested_user.age }}</v-list-item-subtitle>
                                        <v-list-item-subtitle class="font-weight-bold text-capitalize green--text">{{
                                            "Response: " + item.interested_user_response }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="blue darken-1" small :href="item.interested_user.id">
                                            <v-icon color="" size="18">
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-col>
            <v-col lg="3">
                <v-card class="" max-width="400">
                    <v-card-title class="white--text orange darken-3 font-weight-bold">
                        Contact Viewed Profiles
                        <v-spacer></v-spacer>
                        <v-btn color="white" class="text--primary" fab small>
                            <v-icon>mdi-bookmark-outline</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-virtual-scroll :items="user_view_contacts" :item-height="90" height="400">

                        <template v-slot:default="{ item }">
                            <v-list>
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-avatar color="orange darken-3" size="56" class="white--text font-weight-bold">
                                            {{ item.contacteduser.initials }}
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title> {{ item.contacteduser.initials + " " +
                                            item.contacteduser.first_name + " " + item.contacteduser.middle_name + " " +
                                            item.contacteduser.last_name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{ item.contacteduser.date_of_birth }} / {{
                                            "Age:" + item.contacteduser.age }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="blue darken-1" small :href="item.contacteduser.id">
                                            <v-icon color="" size="18">
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar v-model="show_snackbar" color="red darken-3" class="text--white font-weight-bold" top>{{ display_message }}</v-snackbar>

        <v-bottom-sheet v-model="subscription_dialog" inset persistent class="mb-5">
            <v-card min-height="500">
                <ValidationObserver ref="subscriptionaddform" v-slot="{ handleSubmit }">
                    <v-form @submit.prevent="handleSubmit(addSubscription)">
                        <v-card-title>
                            <h3 class="title">Add New Membership</h3>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <ValidationProvider name="Plans" rules="required" v-slot="{ errors }">
                                        <v-autocomplete :items="all_plans" outlined dense label="Plans"
                                            v-model="new_plan_form.plan_id" :error-messages="errors" item-value="id">
                                            <template v-slot:item="data">
                                                <v-list-item-content>
                                                    <v-list-item-title class="font-weight-bold">{{
                                                        data.item.title
                                                    }}
                                                        <span v-if="data.item.elite_plan">
                                                            - Elite Plan
                                                        </span>
                                                        <span v-if="data.item.bonus_plan"> - Bonus Plan
                                                        </span>
                                                        <span v-if="data.item.normal_plan"> - Normal Plan
                                                        </span>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle class="">{{
                                                        data.item.price
                                                    }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                            <template v-slot:selection="data">
                                                {{
                                                    data.item.title + " Price: " + data.item.price
                                                }}
                                            </template>
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="orange darken-3 white--text text-capitalize font-weight-bold pl-3 pr-3"
                                type="submit" medium :loading="loaders.add_subscription_loader"
                                :disabled="loaders.add_subscription_loader" dense>Add Subscription</v-btn>
                                <v-btn class="blue-grey darken-3 white--text text-capitalize font-weight-bold pl-3 pr-3"
                                @click="subscription_dialog = false" dense>Close</v-btn>


                        </v-card-actions>
                    </v-form>
                </ValidationObserver>
            </v-card>

        </v-bottom-sheet>

    </v-container>
</template>
    
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import axios from 'axios';
import moment from 'moment';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
    components: {
        CustomAppbar,
        CustomSidebar,
        ValidationObserver,
        ValidationProvider
    },
    computed: {
        items() {
            const namesLength = this.names.length
            const surnamesLength = this.surnames.length
            const colorsLength = this.colors.length

            return Array.from({ length: 10000 }, (k, v) => {
                const name = this.names[this.genRandomIndex(namesLength)]
                const surname = this.surnames[this.genRandomIndex(surnamesLength)]

                return {
                    color: this.colors[this.genRandomIndex(colorsLength)],
                    fullName: `${name} ${surname}`,
                    initials: `${name[0]} ${surname[0]}`,
                }
            })
        },
    },
    mounted() {
        this.getMembersDetails();
        this.getUserSubscriptions();
        this.getMembershipPlans();

    },
    methods: {
        addSubscription: function () {
            this.loaders.add_subscription_loader = true;
            this.new_plan_form.member_id = this.$route.params.member_id;

            axios.post(`${process.env.VUE_APP_API_URL}/addSubscriptionToMember`, this.new_plan_form).then((response) => {
                if(response.status == 200){
                    this.display_message = "Subscription Created Successfully";
                    this.show_snackbar = true;
                    this.getUserSubscriptions();
                    this.getMembersDetails();

                }
            }).catch((error) => {
                console.log(error);
                this.display_message = "Failed To Add Subscription.";
                this.show_snackbar = true;
            }).finally(() => {
                this.subscription_dialog = false;
                this.new_plan_form.plan_id = ''
                this.new_plan_form.member_id = '';
                this.new_plan_form.discount_amount = 0;
                this.loaders.add_subscription_loader = false;
            });
        },
        cancelSubscription: function(){
            this.loaders.cancel_subscription_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/cancelUserSubscription/`+ this.$route.params.member_id).then((response) => {
                if(response.status == 200){
                    this.display_message = "Subscription Cancelled Successfully";
                    this.show_snackbar = true;
                    this.$router.go()
                  
                }
            }).catch((error) => {
                console.log(error);
                this.display_message = "Failed To Cancel Subscription.";
                this.show_snackbar = true;
            }).finally(() => {
                this.cancel_subscription_dialog = false;
                
                    this.getMembersDetails();
                    this.getUserSubscriptions();
                   
                this.loaders.cancel_subscription_loader = false;
            });

        },  
        genRandomIndex(length) {
            return Math.ceil(Math.random() * (length - 1))
        },
        suspendUser: function () {
            this.loaders.suspend_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/suspenduser`, this.user_basic_information).then((response) => {
                if (response.status == 200) {
                    this.display_message = "User Has Been Suspended";
                    this.show_snackbar = true;
                    this.getMembersDetails();
                    this.suspend_dialog = false;
                }

            }).catch((error) => {
                this.display_message = "Failed To Suspend User.";
                this.show_snackbar = true;
                console.log(error);
            }).finally(() => {

            });
        },
        unlockUser: function () {
            this.loaders.suspend_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/unlockuser`, this.user_basic_information).then((response) => {
                if (response.status == 200) {
                    this.display_message = "User Has Been Unlocked";
                    this.show_snackbar = true;
                    this.getMembersDetails();
                    this.suspend_dialog = false;
                }

            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            });
        },
        deleteUser:function (){
            this.loaders.delete_loader = true;
            axios.post(`${process.env.VUE_APP_API_URL}/deleteuser/`+ this.$route.params.member_id).then((response) => {
                if (response.status == 200) {
                    this.display_message = "User Has Been Deleted";
                    this.show_snackbar = true;
                    this.delete_dialog = false;
                    
                    this.$router.push({ name: "allmembers"});
                   
                }

            }).catch((error) => {
                console.log(error);
                this.display_message = "Failed To Delete User.";
                this.show_snackbar = true;
            }).finally(() => {
                this.delete_dialog = false;
            });
        },
        approveDocument: function (document_id) {
            axios.post(`${process.env.VUE_APP_API_URL}/approvedocument/` + document_id).then((response) => {
                if (response.status == 200) {
                    this.display_message = "Document has been Approved and User has been marked verified";
                    this.show_snackbar = true;
                    this.getMembersDetails();
                }

            }).catch((error) => {
                console.log(error);
                this.display_message = "Failed To Approve document.";
                this.show_snackbar = true;
            }).finally(() => {

            });
        },
        rejectDocument: function (document_id) {
            axios.post(`${process.env.VUE_APP_API_URL}/rejectdocument/` + document_id).then((response) => {
                if (response.status == 200) {
                    this.display_message = "Document has been Rejected Successfully";
                    this.show_snackbar = true;
                    this.getMembersDetails();
                }

            }).catch((error) => {
                console.log(error);
                this.display_message = "Failed To Reject document.";
                this.show_snackbar = true;
            }).finally(() => {

            });
        },
        getMembersDetails: function () {

            axios.get(`${process.env.VUE_APP_API_URL}/getMembersDetails/` + this.$route.params.member_id).then((response) => {
                if (response.status == 200) {

                    if (response.data.user !== null) {

                        this.user_basic_information = response.data.user;
                        this.user = response.data.user;

                    }
                    if (response.data.user.user_personal_information !== null) {
                        this.user_personal_information = response.data.user.user_personal_information;
                    }
                    if (response.data.user.user_family_information !== null) {
                        this.user_family_information = response.data.user.user_family_information;
                    }
                    if (response.data.user.user_employee_information !== null) {
                        this.user_employee_information = response.data.user.user_employee_information;
                    }
                    if (response.data.user.user_social_information !== null) {
                        this.user_social_information = response.data.user.user_social_information;
                    }
                    if (response.data.user.user_religion_information !== null) {
                        this.user_religion_information = response.data.user.user_religion_information;

                    }
                    if (response.data.user.user_astro_information !== null) {
                        this.user_astro_information = response.data.user.user_astro_information;
                    }

                    if (response.data.user.user_preference !== null) {
                        this.user_preferences = response.data.user.user_preference;
                    }
                    if (response.data.images !== null) {
                        this.profile_images = response.data.images
                    }
                    if (response.data.user.user_view_history !== null) {
                        this.user_view_history = response.data.user.user_view_history;
                    }
                    if (response.data.user.user_shortlist !== null) {
                        this.user_shortlist = response.data.user.user_shortlist;
                    }
                    if (response.data.user.user_view_contact !== null) {
                        this.user_view_contacts = response.data.user.user_view_contact;
                    }
                    if (response.data.user.user_interest !== null) {
                        this.user_interest = response.data.user.user_interest;
                    }
                    if (response.data.user.user_document !== null) {
                        this.user_documents = response.data.user.user_document;
                    }
                    if (response.data.user.user_subscription !== null) {
                        this.user_subscription = response.data.user.user_subscription[0];

                    }

                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            });
        },
        getUserSubscriptions: function () {
            this.loaders.list_loader = true;
            axios.get(`${process.env.VUE_APP_API_URL}/getUserSubscriptions/` + this.$route.params.member_id).then((response) => {
                if (response.status == 200) {

                    if (response.data.all_plans !== null) {
                        this.subscriptions = response.data.all_plans;
                    }
                    if (response.data.active_normal_plan !== null) {
                        this.active_normal_plan = response.data.active_normal_plan;
                    }
                    if (response.data.active_bonus_plan !== null) {
                        this.active_bonus_plan = response.data.active_bonus_plan;
                    }
                    if (response.data.active_elite_plan !== null) {
                        this.active_elite_plan = response.data.active_elite_plan;
                    }
                    if (response.data.active_highlight_profile_plan !== null) {
                        this.highlight_profile_plan = response.data.active_highlight_profile_plan;
                    }
                    if (response.data.active_assistedservice_profile_serach_plan !== null) {

                        this.assisted_service_plan = response.data.active_assistedservice_profile_serach_plan;
                    }
                    if (response.data.active_personal_consulting_plan !== null) {
                        this.personal_consulting_plan = response.data.active_personal_consulting_plan;
                    }


                }
            }).catch((error) => {

            }).finally(() => {
                this.loaders.list_loader = false;
            })
        },
        getMembershipPlans: function () {
            axios.get(`${process.env.VUE_APP_API_URL}/getmembershipplans`).then((response) => {
                if (response.status == 200) {
                    // console.log(response.data);
                    this.bonus_plans = response.data.bonus_plans;
                    this.normal_plans = response.data.plans;
                    this.elite_plans = response.data.elite_plans;

                    this.all_plans.push(...this.normal_plans);
                    this.all_plans.push(...this.bonus_plans);
                    this.all_plans.push(...this.elite_plans);



                }
            }).catch((error) => {

            }).finally(() => {

            })
        }


    },

    data: () => ({
        new_plan_form: {
            plan_id: '',
            discount_amount: 0,
            member_id: '',
        },
        dialog: false,
        delete_dialog:false,
        reject_dialog: false,
        subscription_dialog: false,
        bonus_plans: [],
        normal_plans: [],
        all_plans: [],
        elite_plans: [],
        cancel_subscription_dialog:false,
        interested_headers: [
            {
                text: 'Document Type',
                align: 'start',
                sortable: true,
                value: 'type',
            },


            {
                text: 'Link',
                align: 'center',
                sortable: false,
                value: 'uri',
            },
            {
                text: 'Document Status',
                align: 'start',
                sortable: true,
                value: 'status',
            },
            {
                text: 'Actions',
                align: 'center',
                sortable: false,
                value: 'actions',
            },

        ],
        document_types: ['Identity Proof', 'Address Proof', 'Other'],
        slides: [
            'First',
            'Second',
            'Third',
            'Fourth',
            'Fifth',
        ],
        suspend_dialog: false,
        loaders: {
            suspend_loader: false,
            list_loader: false,
            add_subscription_loader: false,
            reject_loader: false,
            delete_loader:false,
            cancel_subscription_loader:false,
        },
        preview_urls: [],
        user: {},
        show_snackbar: false,
        display_message: "",
        user_interest: [],
        user_view_contacts: [],
        user_view_history: [],
        user_shortlist: [],
        user_basic_information: {
            initials: "",
            profile_id: "",
            profile_created_by: "",
            first_name: "",
            middle_name: "",
            last_name: "",
            mother_name: "",
            date_of_birth: "",
            maritial_status: "",
            gender: "",
            dial_code: "",
            about_prospect: "",
            is_verified: "",
            profile_picture: "",
            is_elite: "",
            suspend_reason: "",
            is_suspended: false,
        },
        user_personal_information: {
            height: "",
            health_information: [],
            blood_group: "",
            hobbies: [],
            is_physically_disabled: false,
            body_type: "",
            skin_complexity: '',
        },
        user_family_information: {
            father_living_status: "",
            father_employed_with: "",
            father_employed_as: "",
            father_nature_of_business: "",
            father_retired_from: "",
            father_retired_as: "",
            mother_living_status: "",
            mother_employed_with: "",
            mother_employed_as: "",
            mother_nature_of_business: "",
            mother_retired_from: "",
            mother_retired_as: "",
            family_location: "",
            native_place: "",
            living_with_family: "",
            no_of_married_sisters: 0,
            no_of_married_brothers: 0,
            no_of_unmarried_sisters: 0,
            no_of_unmarried_brothers: 0,
            family_value: "",
            family_affluence: "",
        },
        user_religion_information: {
            religion: "",
            caste: "",
            mother_tongue: "",
            gotra: "",
            is_caste_nobar: "",
        },
        user_employee_information: {
            highest_education: "",
            specialized_in: "",
            college_name: "",
            occupation: "",
            employee_with: "",
            annual_income: "",
            employee_location: "",
            keep_income_private: 0,
        },
        user_social_information: {
            eating_habbit: "",
            smoking_habbit: "",
            drinking_habbit: "",
        },
        user_astro_information: {
            birth_time: "",
            is_manglik: "",
            place_of_birth: "",
            is_doshi: "",
            nakshatra: "",
            rashi: "",
        },
        user_preferences: {
            caste_preferences: [],
            is_manglik: false,
            is_dosh_present: false,
            family_value: 'Any',
            family_affluence: 'Any',
            family_type: 'Any',

            age_from: 18,
            age_upto: 80,
            eating_habbit: 'Any',
            smoking_habbit: 'Any',
            highest_education: 'Any',
            annual_income: 'Any',
            expected_height: 'Any',
        },
        user_subscription: {
            id: '',
            is_normal_plan: false,
            is_elite_plan: false,
            bonus_plan: false,
            is_active: true,
            expire_on: '',
            pending_profileview_allowed: 0,
            pending_contactview_allowed: 0,
            pending_express_interest_allowed: 0,
            highlight_profile: false,
            top_place_on_search: false,
            assistedservice_profile_search: false,
            personal_consulting_completion_status: '',
            personal_consulting_duration_hours: 0,
            membership_plan: {
                title: '',

            },
            transaction: {

            },
        },
        user_documents: [],
        profile_images: [],
        colors: ['#2196F3', '#90CAF9', '#64B5F6', '#42A5F5', '#1E88E5', '#1976D2', '#1565C0', '#0D47A1', '#82B1FF', '#448AFF', '#2979FF', '#2962FF'],
        names: ['Oliver', 'Jake', 'Noah', 'James', 'Jack', 'Connor', 'Liam', 'John', 'Harry', 'Callum', 'Mason', 'Robert', 'Jacob', 'Jacob', 'Jacob', 'Michael', 'Charlie', 'Kyle', 'William', 'William', 'Thomas', 'Joe', 'Ethan', 'David', 'George', 'Reece', 'Michael', 'Richard', 'Oscar', 'Rhys', 'Alexander', 'Joseph', 'James', 'Charlie', 'James', 'Charles', 'William', 'Damian', 'Daniel', 'Thomas', 'Amelia', 'Margaret', 'Emma', 'Mary', 'Olivia', 'Samantha', 'Olivia', 'Patricia', 'Isla', 'Bethany'],
        surnames: ['Smith', 'Anderson', 'Clark', 'Wright', 'Mitchell', 'Johnson', 'Thomas', 'Rodriguez', 'Lopez', 'Perez', 'Williams', 'Jackson', 'Lewis', 'Hill', 'Roberts', 'Jones', 'White', 'Lee', 'Scott', 'Turner', 'Brown', 'Harris', 'Walker', 'Green', 'Phillips', 'Davis', 'Martin', 'Hall', 'Adams', 'Campbell', 'Miller', 'Thompson', 'Allen', 'Baker', 'Parker', 'Wilson', 'Garcia', 'Young', 'Gonzalez', 'Evans', 'Moore', 'Martinez', 'Hernandez', 'Nelson', 'Edwards', 'Taylor', 'Robinson', 'King', 'Carter', 'Collins'],
        current_tab: null,
        bookmark_profiles: [
            {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
                title: 'Brunch this weekend?',
                subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
            },
            {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
                title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
                subtitle: `<span class="text--primary">to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.`,
            },
            {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
                title: 'Oui oui',
                subtitle: '<span class="text--primary">Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?',
            },
            {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
                title: 'Birthday gift',
                subtitle: '<span class="text--primary">Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?',
            },
            {
                avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
                title: 'Recipe to try',
                subtitle: '<span class="text--primary">Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
            },
        ],
        snackbar_message: "",
        display_snackbar: false,
        snackbar_color: "deep-purple accent-4",
        subscriptions: [],
        active_bonus_plan: {

        },
        personal_consulting_plan: {
            max_duration_hours: 0,
        },
        active_elite_plan: {},
        active_normal_plan: {
            pending_contactview_allowed: 0,
            pending_express_interest_allowed: 0,
            expire_on: ""
        },
        highlight_profile_plan: {
            expire_on: ""
        },
        assisted_service_plan: {

        },
        subscription_header: [


            {
                text: 'Plan Name',
                align: 'start',
                sortable: false,
                value: 'plan_name',
            },
            {
                text: 'Plan Type',
                align: 'start',
                sortable: false,
                value: 'plan_type',
            },
            {
                text: 'Status',
                align: 'start',
                sortable: false,
                value: 'is_active',
            },
            {
                text: 'Payment Status',
                align: 'start',
                sortable: false,
                value: 'payment_status',
            },

            {
                text: 'Expire On',
                align: 'center',
                sortable: false,
                value: 'expire_on',
            },
        ],

    }),
}

</script>
  
<style>
.text-sm {
    font-size: .875rem !important;
}

.text-body-2 {
    font-size: .875rem !important;
    font-weight: 400;
    line-height: 1.25rem !important;
    letter-spacing: .0094rem !important;
    font-family: inter, sans-serif, -apple-system, blinkmacsystemfont, Segoe UI, roboto, Helvetica Neue, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol !important;
    text-transform: none !important;
}

.naruto_font {
    font-family: 'Nunito' !important;
}
</style> 

  