<template>
    <v-container fluid>
        <!-- <v-app-bar class="elevation-3 ma-5 rounded-pill" >
          <v-app-bar-nav-icon>
            <v-icon color="black">mdi-refresh</v-icon>
          </v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <v-avatar >
            <v-icon  color="red darken-5">mdi-account-question</v-icon>
          </v-avatar>
          <v-avatar >
            <v-icon  color="orange darken-5">mdi-comment-quote</v-icon>
          </v-avatar>
          <v-avatar >
            <v-icon  color="green darken-5">mdi-bell-badge</v-icon>
          </v-avatar>
          <v-avatar>
            <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="John">
          </v-avatar>
        </v-app-bar> -->
       
      </v-container>
</template>
<script>
export default {
  data: () => ({
   }),
}
</script>