<template>
      <v-navigation-drawer app width="280" class="elevation-1 ba-0" v-model="login_page">
        <!-- Application Logo and Name  -->
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
             <v-img :src="kesari_logo" height="100" width="250" class="ma-auto"></v-img>
            </v-list-item-title>
            <v-list-item-subtitle>
            
             <p class="text-center text-wrap font-weight-bold">Kesari Matrimony Customer Management Portal</p> 
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
  
        <!-- Navigation Menus  -->
        <v-list shaped>
          <div v-for="link, i in links" :key="i">
            <router-link v-if="!link.sub_links" :to="{name:link.route_path}"> 
              <v-list-item  link >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title> {{ link.name }}</v-list-item-title>
            </v-list-item>
            </router-link>
            
  
            
            <v-list-group v-else :key="link.name" no-action>
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ link.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
  
                  <v-list-item-title v-text="link.name"></v-list-item-title>
                </v-list-item-content>
              </template>
              <router-link v-for="sublink in link.sub_links" :key="sublink.name"  :to="{name:sublink.route_path}"> 
              <v-list-item link>
                <v-list-item-icon>
                <v-icon>{{ sublink.icon }}</v-icon>
              </v-list-item-icon>
                  <v-list-item-title v-text="sublink.name"></v-list-item-title>
              </v-list-item>
              </router-link>
            </v-list-group>
  
          </div>
        </v-list>
        <v-divider class="mt-14"></v-divider>
        <v-btn class="red white--text mt-1" block @click="signOut"> Logout</v-btn>
      </v-navigation-drawer>
  </template>
  
  <script>
import { mapActions } from 'vuex';

  export default {
    name:'custom-sidebar',
    computed:{

    },
    methods:{
      ...mapActions('auth',['logout']),
      signOut:function(){
        this.$store.dispatch('auth/logout');
      }
    },
    data: () => ({
      login_page:true,
      kesari_logo: require('@/assets/kesari_logo.png'),
      links: [
        {
          name: 'Dashboard',
          route_path: 'dashboard',
          icon: 'mdi-view-dashboard-variant',
        },
        {
          name: 'Members',
          route_path: '',
          icon: 'mdi-account-circle',
          sub_links: [
            {
              name: 'All Members',
              route_path: 'allmembers',
              icon: '',
            },
            {
              name: 'Paid Members',
              route_path: 'paidmembers',
              icon: '',
            },
            {
              name: 'Elite Members',
              route_path: 'elitemembers',
              icon: '',
            },
          ]
        },
        {
          name: 'Members Activities',
          route_path: '',
          icon: 'mdi-account-tie-voice',
          sub_links: [
            {
              name: 'Shortlisted',
              route_path: 'shortlisted',
              icon: '',
            },
          ],
        },
  
        {
          name: 'Membership Plans',
          route_path: 'membershipplans',
          icon: 'mdi-cash',
        },
        {
          name: 'Blogs',
          route_path: 'blogs',
          icon: 'mdi-post',
        },
        {
          name: 'Add New Details',
          route_path: '',
          icon: 'mdi-plus-box',
          sub_links: [
            {
              name: 'Religion',
              route_path: 'religion',
              icon: '',
            },
            {
              name: 'Caste',
              route_path: 'caste',
              icon: '',
            },
            // {
            //   name: 'Sub caste',
            //   route_path: '/subcaste',
            //   icon: '',
            // },
            // {
            //   name: 'Country',
            //   route_path: '/country',
            //   icon: '',
            // },
            // {
            //   name: 'States',
            //   route_path: '/states',
            //   icon: '',
            // },
            // {
            //   name: 'Cities',
            //   route_path: '/cities',
            //   icon: '',
            // },
            {
              name: 'Education',
              route_path: 'education',
              icon: '',
            },
            {
              name: 'Mother Tongue',
              route_path: 'mothertongue',
              icon: '',
            },
  
          ],
        },
  
        {
          name: 'Emails',
          route_path: '',
          icon: 'mdi-email',
          sub_links: [
            {
              name: 'Email Templates',
              route_path: 'emailtemplates',
              icon: '',
            },
            // {
            //   name: 'Send Email',
            //   route_path: 'sendemail',
            //   icon: '',
            // },
          ]
        },
        // {
        //   name: 'Mobile SMS',
        //   route_path: '',
        //   icon: 'mdi-message-processing',
        //   sub_links: [
        //     {
        //       name: 'SMS Templates',
        //       route_path: 'smstemplates',
        //       icon: '',
        //     },
        //     {
        //       name: 'Send SMS',
        //       route_path: 'sendsms',
        //       icon: '',
        //     },
        //   ]
        // },
        {
          name: 'Queries',
          route_path: '',
          icon: 'mdi-help-rhombus',
          sub_links: [
            {
              name: 'Contact Queries',
              route_path: 'contactqueries',
              icon: '',
            },
            {
              name: 'Elite Queries',
              route_path: 'elitequeries',
              icon: '',
            },
          ]
        },
        {
          name: 'Feedbacks',
          route_path: 'feedback',
          icon: 'mdi-comment-quote',
        },
        {
          name: 'Site Configurations',
          route_path: '',
          icon: 'mdi-cogs',
          sub_links: [
            {
              name: 'Home Page',
              route_path: 'homepage',
              icon: '',
            },
            {
              name: 'About Us Page',
              route_path: 'aboutuspage',
              icon: '',
            },
            {
              name: 'Elite Page',
              route_path: 'elitepage',
              icon: '',
            },
            {
              name: 'Contact Us Page',
              route_path: 'contactus',
              icon: '',
            },
            {
              name: 'Terms & Conditions',
              route_path: 'termsandconditions',
              icon: '',
            },
            {
              name: 'Privacy Policies',
              route_path: 'privacypolicies',
              icon: '',
            },
          ]
        },
        {
          name: 'Discount Vouchers',
          route_path: 'vouchers',
          icon: 'mdi-ticket-percent',
        },
        {
          name: 'Employees',
          route_path: 'staff',
          icon: 'mdi-account-box',
        },
        // {
        //   name: 'Staff',
        //   route_path: '',
        //   icon: 'mdi-account-badge',
        //   sub_links: [
        //     {
        //       name: 'All Staff',
        //       route_path: '/staff',
        //       icon: '',
        //     },
        //     {
        //       name: 'Roles & permissions',
        //       route_path: '/staffpermissions',
        //       icon: '',
        //     },
        //   ]
        // },
        // {
        //   name: 'Site Settings',
        //   route_path: '/sitesettings',
        //   icon: 'mdi-file-cog',
        // },
      ],
    }),
  }
  </script>
  <style scoped>  
  .v-navigation-drawer>>>.v-navigation-drawer__border {
    display: none !important;
  }
  a{
    text-decoration:  none !important;
  }
  </style>