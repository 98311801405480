<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>
    <v-row>
      <v-col lg="12">
        <H2>Memebrship Plans</H2>
      </v-col>
      <!--Filters-->
      <v-col lg="12">

      </v-col>
      <v-col lg="12">
        <v-row justify="end">
          <router-link :to="{ name: 'newplan'}">
            <v-btn color="red darken-5" class="white--text ma-2">
            <v-icon left dark>
              mdi-plus
            </v-icon>
            New Membership Plan
            </v-btn>
          </router-link>
       

            <v-btn color="red darken-5" class="white--text ma-2" @click="exportMembershipPlans"
              :loading="loaders.export_loader">
              <v-icon left dark>
                mdi-file-export
              </v-icon>
              Export
            </v-btn>
        </v-row>
      </v-col>
      <v-col lg="12">
        <v-card>
          <v-data-table :headers="members_headers" :items="membership_plans_list" class="index_header"
            :loading="loaders.list_progress" :server-items-length="total_items" :footer-props="{
                showFirstLastPage: false,
                firstIcon: 'mdi-arrow-collapse-left',
                itemsPerPageOptions: [5, 10, 15, 20, 50, 100],
              }" :options.sync="pagination">

            <template v-slot:[`item.is_active`]="{ item }">
              <v-chip v-if="item.is_active" small color="green" class="white--text font-weight-bold">Active</v-chip>
              <v-chip v-else small color="red" class="white--text font-weight-bold">Disabled</v-chip>
            </template>
            <template v-slot:[`item.plan_type`]="{ item }">
              <span v-if="item.elite_plan">Elite Plan</span>
              <span v-if="item.normal_plan" small color="red">Normal</span>
              <span v-if="item.bonus_plan" small color="red">Bonus</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <router-link :to="{ name: 'editplan', params: { plan_id: item.id } }">
                <v-icon small color="primary" class="ma-2">
                  mdi-pencil
                </v-icon>
              </router-link>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!--Dialogs-->
  </v-container>
</template>
  
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';


export default {
  components: {
    CustomAppbar,
    CustomSidebar
  },
  watch: {
    pagination: {
      handler() {
        this.getAllMembershipPlans();
      }
    }
  },
  mounted() {
    console.log("allmembers")
  },
  data: () => ({

    dialogs: {
      upload_member: false,
    },
    members_headers: [
      {
        text: 'Title',
        align: 'start',
        sortable: true,
        value: 'title'
      },
      {
        text: 'Type',
        align: 'start',
        sortable: false,
        value: 'plan_type'
      },

      {
        text: 'Price',
        align: 'start',
        sortable: true,
        value: 'price'
      },
      {
        text: 'Status',
        align: 'start',
        sortable: true,
        value: 'is_active'
      },
      {
        text: 'Total Contact Views',
        align: 'start',
        sortable: true,
        value: 'contactview_allowed'
      },
      {
        text: 'Total Express Interest',
        align: 'start',
        sortable: true,
        value: 'express_interest_allowed'
      },
      {
        text: 'HighLight Profile',
        align: 'start',
        sortable: true,
        value: 'highlight_profile'
      },
      {
        text: 'Top Place on Search',
        align: 'start',
        sortable: true,
        value: 'top_place_on_search'
      },
      {
        text: 'Allowed Assisted Service',
        align: 'start',
        sortable: true,
        value: 'assistedservice_profile_search'
      },
      {
        text: 'Personal Consulting',
        align: 'start',
        sortable: true,
        value: 'personal_consulting'
      },
      {
        text: 'Personal Consulting Hours',
        align: 'start',
        sortable: true,
        value: 'personal_consulting_duration_hours'
      },

      {
        text: 'Validity (Months)',
        align: 'start',
        sortable: true,
        value: 'maxduration_in_months'
      },

      {
        text: 'Actions',
        align: 'start',
        sortable: false,
        value: 'actions'
      }
    ],
    membership_plans_list: [],
    total_items: 10,
    pagination: {},
    filters: {

    },
    loaders: {
      list_progress: false,

    }

  }),
  methods: {

    getAllMembershipPlans: function () {
      this.loaders.list_progress = true;
      this.pagination.filters = Object.assign({}, this.filters);
      axios.get(`${process.env.VUE_APP_API_URL}/GetAllMembershipPlans`, { params: this.pagination }).then((response) => {
        if (response.status == 200) {
          this.total_items = response.data.meta.total;
          this.membership_plans_list = response.data.data;

        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loaders.list_progress = false;
      });
    },
    filterList: function () {
      this.getAllMembershipPlans();
    },

    exportMembershipPlans: function () {
      this.loaders.export_loader = true;
      axios.get(`${process.env.VUE_APP_API_URL}/exportMembershipPlans`, {
        responseType: 'blob'
      }).then((response) => {
        if (response.status == 200) {
          let fileUrl = window.URL.createObjectURL(response.data);
          let fileLink = document.createElement('a');

          fileLink.href = fileUrl;
          fileLink.setAttribute('download', 'membershipplans.xlsx');
          document.body.appendChild(fileLink)

          fileLink.click();

        }
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loaders.export_loader = false;
      });
    }
  }
}

</script>

 
