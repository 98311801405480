<template>
    <v-container fluid>
      <CustomSidebar></CustomSidebar>
      <CustomAppbar></CustomAppbar>
      <v-row>
        <v-col lg="12">
          <H2>Employees</H2>
        </v-col>
        <v-col lg="12">
          <v-row  justify="end">
            <router-link :to="{ name: 'newstaff' }">
            <v-btn color="red darken-5" class="white--text ma-2">
              <v-icon left dark>
                mdi-account-box
              </v-icon>
              Add New Employee
            </v-btn>
          </router-link>
          </v-row>
        </v-col>
        <v-col lg="12">
          <v-card>
            <v-data-table :headers="employee_headers" :loading="list_loader" :items="employees">
            
              <template v-slot:[`item.name`]="{ item }">
                {{ item.first_name +" "+item.last_name }}
                </template>
              <template v-slot:[`item.actions`]="{ item }">
             
              <router-link :to="{name:'editstaff', params:{staff_id:item.id}}">
                <v-icon small color="primary" class="mr-2">
                  mdi-pencil
                </v-icon>
              </router-link>
              <v-dialog v-model="delete_employee_dialog" width="300">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn link text icon v-on="on" v-bind="attrs" @click="delete_employee_dialog = true">
                    <v-icon small color="red" class="ma-1">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline lighten-2" primary-title>
                    Confirmation
                  </v-card-title>

                  <v-card-text>
                    Are you sure you want to delete Employee. Once deleted it cannot be revert.
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="deleteEmployee(item)" :loading="delete_loader">
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar v-model="display_snackbar" color="primary"> {{ message }}</v-snackbar>
    </v-container>
  
  </template>
    
  <script>
  /* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';

  
  export default {
    components:{
      CustomAppbar,
      CustomSidebar
    },
    mounted() {
      this.getAllEmployees();
    },
    methods:{
      deleteEmployee:function(employee){
        this.delete_loader = true;
        axios.post(`${process.env.VUE_APP_API_URL}/deleteemployee`,employee).then((response)=>{
                if(response.status == 200){
                  this.delete_employee_dialog = false;
                  this.message = 'Employee Deleted Successfully';
                  this.display_snackbar = true;

                  this.getAllEmployees();
                }
            }).catch((error)=>{
                console.log(error);
                this.message = 'Error Occur while deleting employee';
                  this.display_snackbar = true;
            }).finally(()=>{
              this.delete_employee_dialog = false;
                this.delete_loader = false;

            })
      },
      getAllEmployees:function(){
        this.list_loader = true;
        axios.get(`${process.env.VUE_APP_API_URL}/getallemployees`).then((response)=>{
                if(response.status == 200){
                 
                   this.employees = response.data.employees;
                }
            }).catch((error)=>{
                console.log(error);
            }).finally(()=>{
              
                this.list_loader = false;

            })
      }


    },
  
  
    data: () => ({
      delete_employee_dialog:false,
      delete_loader:false,
      list_loader:false,
      display_snackbar:false,
      message:'',
      employees:[],
      employee_headers: [
        {
          text: 'Employee Id',
          filterable: true,
          value: 'profile_id'
        },
        
        {
          text: 'Name',
          filterable: true,
          value: 'name'
        },
        
        {
          text: 'Mobile Number',
          filterable: true,
          value: 'mobile_number'
        },
        {
          text: 'Email Address',
          filterable: true,
          value: 'email'
        },
        {
          text: 'Role',
          filterable: true,
          value: 'role'
        },
        {
          text: 'Actions',
          filterable: false,
          value: 'actions'
        }
      ]
  
  
  
    }),
  }
  
  </script>
  
   
  