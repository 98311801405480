<template>
    <v-container fluid class="ma-lg-2" style="display: block !important;margin-bottom: 20% !important;">
        <CustomAppbar>
        </CustomAppbar>
        <CustomSidebar></CustomSidebar>
        <v-row class="pa-2">
            <v-col lg="4" sm="6" xs="12" md="4">
                <v-card class="pa-4 elevation-3" max-width="450" min-height="450">
                    <VueApexCharts type="bar" height="350" width="400" :options="daily_registered_chart_options"
                        ref="daily_registered_chart" :series="daily_registered_chart_series"></VueApexCharts>
                </v-card>

            </v-col>
            <v-col lg="4" sm="6" xs="12" md="4">
                <v-card class="pa-4 elevation-3" max-width="450" min-height="450">
                    <VueApexCharts type="bar" height="350" :options="daily_renewed_chart_options" ref="daily_renewed_chart"
                        :series="daily_renewed_chart_series"></VueApexCharts>
                </v-card>
            </v-col>
            <v-col lg="4" sm="6" xs="12" md="4">
                <v-card class="pa-4 elevation-3" max-width="450" min-height="450">
                    <VueApexCharts type="pie" height="350" width="400"
                        :options="membership_statistics_monthly_chart_options" ref="membership_statistics_monthly"
                        :series="membership_statistics_monthly_chart_series"></VueApexCharts>
                </v-card>

            </v-col>
            <v-col lg="4" sm="6" xs="12" md="4">
                <v-card class="pa-4 elevation-3" max-width="450" min-height="450">
                    <VueApexCharts type="bar" height="350" width="400" :options="membership_statistics_chart_options"
                        ref="membership_statistics_chart_all" :series="membership_statistics_chart_series"></VueApexCharts>
                </v-card>

            </v-col>
            <v-col lg="4" sm="6" xs="12" md="4">
                <v-card class="elevation-3 " max-width="450" min-height="450">
                    <v-card-title class="white--text red darken-3">
                        New Users Registered <br> (With Subscription)
                        <v-spacer></v-spacer>
                        <v-btn color="white" class="text--primary" fab small>
                            <v-icon>mdi-account-clock</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-divider></v-divider>
                    <v-virtual-scroll :items="registered_user_with_subscription" :item-height="80" height="350">
                        <template v-slot:default="{ item }">
                            <v-list>
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-avatar color="orange darken-3" size="56" class="white--text font-weight-bold">
                                            {{ item.initials }}

                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title> {{ item.initials + " " +
                                            item.first_name + " " + item.middle_name + " " +
                                            item.last_name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{
                                            "Contact : " + item.mobile_number }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>{{
                                            "Membership Plan : " + item.user_subscription[0].membership_plan.title }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="blue darken-1" small :href="'/member/' + item.id">
                                            <v-icon color="" size="18">
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-col>
            <v-col lg="4" sm="6" xs="12" md="4">
                <v-card class="elevation-3 " max-width="450" max-height="450">
                    <v-card-title class="white--text red darken-3">
                        New Users Registered <br> (Without Subscription)
                        <v-spacer></v-spacer>
                        <v-btn color="white" class="text--primary" fab small>
                            <v-icon>mdi-account-clock</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-divider></v-divider>
                    <v-virtual-scroll :items="registered_user_without_subscription" :item-height="90" height="400">
                        <template v-slot:default="{ item }">
                            <v-list>
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-avatar color="orange darken-3" size="56" class="white--text font-weight-bold">
                                            {{ item.initials }}

                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title> {{ item.initials + " " +
                                            item.first_name + " " + item.middle_name + " " +
                                            item.last_name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{
                                            "Contact : " + item.mobile_number }}
                                        </v-list-item-subtitle>

                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="blue darken-1" small :href="'/member/' + item.id">
                                            <v-icon color="" size="18">
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-col>
            <v-col lg="4" sm="6" xs="12" md="4">
                <v-card class="elevation-3 " max-width="450">
                    <v-card-title class="white--text red darken-3">
                        New Users Registered <br> (Partial Registration)
                        <v-spacer></v-spacer>
                        <v-btn color="white" class="text--primary" fab small>
                            <v-icon>mdi-account-clock</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-divider></v-divider>
                    <v-virtual-scroll :items="registered_user_partial_registration" :item-height="90" height="400">
                        <template v-slot:default="{ item }">
                            <v-list>
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-avatar color="orange darken-3" size="56" class="white--text font-weight-bold">
                                            {{ item.initials }}

                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title> {{ item.initials + " " +
                                            item.first_name + " " + item.middle_name + " " +
                                            item.last_name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{
                                            "Contact : " + item.mobile_number }}
                                        </v-list-item-subtitle>

                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="blue darken-1" small :href="'/member/' + item.id">
                                            <v-icon color="" size="18">
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-col>
            <v-col lg="4" sm="6" xs="12" md="4">
                <v-card class="elevation-3 " max-width="450">
                    <v-card-title class="white--text red darken-3">
                        Users Registered <br> (Expiring Membership)
                        <v-spacer></v-spacer>
                        <v-btn color="white" class="text--primary" fab small>
                            <v-icon>mdi-account-clock</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-divider></v-divider>
                    <v-virtual-scroll :items="user_with_expiring_membership" :item-height="90" height="400">
                        <template v-slot:default="{ item }">
                            <v-list>
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-avatar color="orange darken-3" size="56" class="white--text font-weight-bold">
                                            {{ item.initials }}

                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title> {{ item.initials + " " +
                                            item.first_name + " " + item.middle_name + " " +
                                            item.last_name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{
                                            "Contact : " + item.mobile_number }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>{{
                                            "Membership Plan : " + item.user_subscription[0].membership_plan.title }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="blue darken-1" small :href="'/member/' + item.id">
                                            <v-icon color="" size="18">
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-col>

            <v-col lg="4" sm="6" xs="12" md="4">
                <v-card class="elevation-3 " max-width="450">
                    <v-card-title class="white--text red darken-3">
                        Users Registered <br> (Without Photos)
                        <v-spacer></v-spacer>
                        <v-btn color="white" class="text--primary" fab small>
                            <v-icon>mdi-account-clock</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-divider></v-divider>
                    <v-virtual-scroll :items="users_without_photos" dense :item-height="80" height="400">
                        <template v-slot:default="{ item }">
                            <v-list>

                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-avatar color="orange darken-3" size="56" class="white--text font-weight-bold">
                                            {{ item.initials }}

                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title> {{ item.initials + " " +
                                            item.first_name + " " + item.middle_name + " " +
                                            item.last_name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{
                                            "Contact : " + item.mobile_number }}
                                        </v-list-item-subtitle>

                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="blue darken-1" small :href="'/member/' + item.id">
                                            <v-icon color="" size="18">
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-col>
            <v-col lg="4" sm="6" xs="12" md="4">
                <v-card class="elevation-3 " max-width="450">
                    <v-card-title class="white--text red darken-3">
                        Users Registered <br> (Incomplete Verification)
                        <v-spacer></v-spacer>
                        <v-btn color="white" class="text--primary" fab small>
                            <v-icon>mdi-account-clock</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-divider></v-divider>
                    <v-virtual-scroll :items="users_without_documents" :item-height="90" height="400">
                        <template v-slot:default="{ item }">
                            <v-list>
                                <v-list-item two-line>
                                    <v-list-item-avatar>
                                        <v-avatar color="orange darken-3" size="56" class="white--text font-weight-bold">
                                            {{ item.initials }}

                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title> {{ item.initials + " " +
                                            item.first_name + " " + item.middle_name + " " +
                                            item.last_name }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>{{
                                            "Contact : " + item.mobile_number }}
                                        </v-list-item-subtitle>

                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <v-btn icon color="blue darken-1" small :href="'/member/' + item.id">
                                            <v-icon color="" size="18">
                                                mdi-open-in-new
                                            </v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                        </template>
                    </v-virtual-scroll>
                </v-card>
            </v-col>

        </v-row>
    </v-container>
</template>
<script>
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios';


export default {
    components: { CustomAppbar, CustomSidebar, VueApexCharts },
    methods: {
        getDailyRegisteredCount: function () {

            axios.get(`${process.env.VUE_APP_API_URL}/dailyRegisteredCount`).then((response) => {
                if (response.status == 200) {
                    this.daily_registered_chart_series.data = response.data.data;
                    this.$refs.daily_registered_chart.updateSeries([{
                        data: response.data.data,
                    }], false, true);
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            });
        },
        dailyRenewedUsers: function () {
            axios.get(`${process.env.VUE_APP_API_URL}/dailyRenewedUsers`).then((response) => {
                if (response.status == 200) {

                    this.$refs.daily_renewed_chart.updateSeries([{
                        data: response.data.data
                    }]);
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            });
        },
        membershipTypeStatistics: function () {

            axios.get(`${process.env.VUE_APP_API_URL}/membershipTypeStatistics`).then((response) => {
                if (response.status == 200) {

                    this.$refs.membership_statistics_chart_all.updateSeries([{
                        data: response.data.data,
                    }], false, true);
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            });
        },
        membershipStatisticsMonthly: function () {

            axios.get(`${process.env.VUE_APP_API_URL}/membershipStatisticsMonthly`).then((response) => {
                if (response.status == 200) {

                    this.$refs.membership_statistics_monthly.updateSeries([{
                        data: response.data.data,
                    }], false, true);
                    this.membership_statistics_monthly_chart_series = response.data.data;
                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            });
        },
        userRegisteredWithSubscriptions: function () {

            axios.get(`${process.env.VUE_APP_API_URL}/userRegisteredWithSubscriptions`).then((response) => {
                if (response.status == 200) {
                    this.registered_user_with_subscription = response.data.data;

                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            });
        },
        userRegisteredWithOutSubscriptions: function () {

            axios.get(`${process.env.VUE_APP_API_URL}/userRegisteredWithOutSubscriptions`).then((response) => {
                if (response.status == 200) {
                    this.registered_user_without_subscription = response.data.data;

                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            });
        },

        userWithPartialRegistration: function () {

            axios.get(`${process.env.VUE_APP_API_URL}/userWithPartialRegistration`).then((response) => {
                if (response.status == 200) {
                    this.registered_user_partial_registration = response.data.data;

                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            });
        },

        userWithExpiringMembership: function () {
            axios.get(`${process.env.VUE_APP_API_URL}/userWithExpiringMembership`).then((response) => {
                if (response.status == 200) {
                    this.user_with_expiring_membership = response.data.data;

                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            });
        },

        usersWithoutPhotos: function () {
            axios.get(`${process.env.VUE_APP_API_URL}/usersWithoutPhotos`).then((response) => {
                if (response.status == 200) {
                    this.users_without_photos = response.data.data;

                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            });
        },

        usersWithoutVerification: function () {
            axios.get(`${process.env.VUE_APP_API_URL}/usersWithoutPhotos`).then((response) => {
                if (response.status == 200) {
                    this.users_without_documents = response.data.data;

                }
            }).catch((error) => {
                console.log(error);
            }).finally(() => {

            });
        }


    },
    created() {
        this.getDailyRegisteredCount();
        this.userRegisteredWithSubscriptions();
        this.userRegisteredWithOutSubscriptions();
        this.userWithPartialRegistration();
        this.userWithExpiringMembership();
        this.usersWithoutPhotos();
        this.usersWithoutVerification();
        this.membershipTypeStatistics();
        this.dailyRenewedUsers();
        this.membershipStatisticsMonthly();
    },
    mounted() {


    },
    data: () => ({
        registered_user_with_subscription: [],
        registered_user_without_subscription: [],
        registered_user_partial_registration: [],
        user_with_expiring_membership: [],
        users_without_photos: [],
        users_without_documents: [],

        daily_registered_chart_series: [{
            name: "New Users ",
            data: [{
                x: 'Mon',
                y: 0
            }, {
                x: 'Tue',
                y: 0
            }, {
                x: 'Wed',
                y: 0
            }, {
                x: 'Thu',
                y: 0
            }, {
                x: 'Fri',
                y: 0
            }, {
                x: 'Sat',
                y: 0
            }, {
                x: 'Sun',
                y: 0
            },
            ]
        }],
        daily_registered_chart_options: {
            chart: {
                type: 'bar',
                height: 400
            },
            colors: ['#3cb043'],
            xaxis: {
                type: 'category',
                labels: {

                },

            },
            title: {
                text: 'New User Register (Weekly)',
            },
            tooltip: {

            },
        },
        daily_renewed_chart_series: [{
            name: "Renewed Users ",
            data: [{
                x: 'Mon',
                y: 0
            }, {
                x: 'Tue',
                y: 0
            }, {
                x: 'Wed',
                y: 0
            }, {
                x: 'Thu',
                y: 0
            }, {
                x: 'Fri',
                y: 0
            }, {
                x: 'Sat',
                y: 0
            }, {
                x: 'Sun',
                y: 0
            },
            ]
        }],
        daily_renewed_chart_options: {
            chart: {
                type: 'bar',
                height: 400
            },
            colors: ['#FF0000'],
            xaxis: {
                type: 'category',
                labels: {

                },

            },
            title: {
                text: 'Users Renewed Subscription (Weekly)',
            },
            tooltip: {

            },
        },
        membership_statistics_chart_series: [{
            name: "Subscription Count ",
            data: [{
                x: 'Elite Plan',
                y: 0
            }, {
                x: 'Normal Plan',
                y: 0
            }, {
                x: 'Bonus Plan',
                y: 0
            },
            ]
        }],
        membership_statistics_chart_options: {
            chart: {
                type: 'bar',
                height: 400
            },
            colors: ['#FF0000'],
            xaxis: {
                type: 'category',
                labels: {

                },

            },
            title: {
                text: 'Membership Subscription Statistics (Complete)',
            },
            tooltip: {

            },
        },
        membership_statistics_monthly_chart_series: [0, 0, 0,],
        membership_statistics_monthly_chart_options: {
            chart: {
                width: 450,
                type: 'pie',
            },
            labels: ['Elite Plan', 'Normal Plan', 'Bonus Plan'],
            colors: ['#ff6361', '#bc5090', '#003f5c'],
            legend: {
                position: 'bottom'
            },
            responsive: [{
                breakpoint: 580,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'top',
                        onItemClick: {
                            toggleDataSeries: true
                        },
                        onItemHover: {
                            highlightDataSeries: true
                        },
                    }
                }
            }],
            title: {
                text: 'Membership Subscription Statistics (Monthly)',
            },
            tooltip: {

            },
        },


    }),

}

</script>