<template>
  <v-app id="inspire">
    <v-main>
      <router-view></router-view>
      <!--  -->
    </v-main>
  </v-app>
</template>

<script>
import {  mapGetters } from 'vuex';
export default {
  
  mounted() {
      if(this.isLoggedin == 'false'){
        this.$router.push({name:"Home"});
      }
    },
  computed: {
        ...mapGetters({
            userAction: 'auth/userAction',
            isLoggedin:'auth/isLoggedIn',
        })
    },
  data: () => ({
    
  }),
}
</script>
<style>
body #inspire {
  background-color: rgb(244, 245, 250) !important;
}

.v-navigation-drawer>>>.v-navigation-drawer__border {
  display: none !important;
}
</style>