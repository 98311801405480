<template>
    <v-container fluid>
        <CustomAppbar></CustomAppbar>
        <CustomSidebar></CustomSidebar>
        <v-row class="pa-10">
            <v-col lg="9" cols="12">
                <v-card :loading="update_loading">
                    <ValidationObserver ref="editmembership" v-slot="{ handleSubmit }">

                        <v-form @submit.prevent="handleSubmit(addMembershipPlan)">
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12">
                                        <ValidationProvider name="title" rules="required|max:30" v-slot="{ errors }">
                                            <v-text-field name="title" outlined v-model="membership_plan.title"
                                                label="title" :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12">
                                        <ValidationProvider name="Price" rules="required|numeric|min:0" v-slot="{ errors }">
                                            <v-text-field name="Price" outlined v-model="membership_plan.price"
                                                label="Price" :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12">
                                        <ValidationProvider name="Max Duration In Months" rules="required|numeric|min:1"
                                            v-slot="{ errors }">
                                            <v-text-field name="Max Duration In Months" outlined
                                                v-model="membership_plan.maxduration_in_months"
                                                label="Max Duration In Months" :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="6">
                                        <ValidationProvider name="Elite Plan" rules="required" v-slot="{ errors }">
                                            <v-switch label="Elite Plan" v-model="membership_plan.elite_plan"
                                                :error-messages="errors"></v-switch>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="6">
                                        <ValidationProvider name="Bonus Plan" rules="required" v-slot="{ errors }">
                                            <v-switch label="Bonus Plan" v-model="membership_plan.bonus_plan"
                                                :error-messages="errors"></v-switch>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="6" v-if="!membership_plan.elite_plan">
                                        <ValidationProvider name="Contact View Allowed" rules="required|numeric|min:1"
                                            v-slot="{ errors }">
                                            <v-text-field name="Contact View Allowed" outlined
                                                v-model="membership_plan.contactview_allowed" label="Contact View Allowed"
                                                :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="6" v-if="!membership_plan.elite_plan">
                                        <ValidationProvider name="Express Interest Allowed" rules="required|numeric|min:1"
                                            v-slot="{ errors }">
                                            <v-text-field name="Express Interest Allowed" outlined
                                                v-model="membership_plan.express_interest_allowed"
                                                label="Express Interest Allowed" :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    
                                    <v-col cols="3">
                                        <ValidationProvider name="Highlight Profile" rules="required" v-slot="{ errors }">
                                            <v-switch label="Highlight Profile" v-model="membership_plan.highlight_profile"
                                                :error-messages="errors"></v-switch>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3">
                                        <ValidationProvider name="Top Place On Search" rules="required" v-slot="{ errors }">
                                            <v-switch label="Top Place On Search"
                                                v-model="membership_plan.top_place_on_search"
                                                :error-messages="errors"></v-switch>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3">
                                        <ValidationProvider name="Assisted Profile Search" rules="required"
                                            v-slot="{ errors }">
                                            <v-switch label="Assisted Profile Search"
                                                v-model="membership_plan.assistedservice_profile_search"
                                                :error-messages="errors"></v-switch>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3">
                                        <ValidationProvider name="Personal Consulting" rules="required" v-slot="{ errors }">
                                            <v-switch label="Personal Consulting"
                                                v-model="membership_plan.personal_consulting"
                                                :error-messages="errors"></v-switch>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" v-if="membership_plan.personal_consulting">
                                        <ValidationProvider name="Max Duration In Hours" rules="required|numeric|min:1"
                                            v-slot="{ errors }">
                                            <v-text-field name="Max Duration In Hours" outlined
                                                v-model="membership_plan.perssonal_consulting_duration_hours"
                                                label="Max Duration In Hours" :error-messages="errors"></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="3">
                                        <ValidationProvider name="Activate This Plan" rules="required" v-slot="{ errors }">
                                            <v-switch label="Activate This Plan"
                                                v-model="membership_plan.is_active"
                                                :error-messages="errors"></v-switch>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12">
                                        <ValidationProvider name="Features" rules="required|max:100" v-slot="{ errors }">
                                            <v-textarea name="Features" outlined v-model="membership_plan.features"
                                                label="Features" :error-messages="errors"></v-textarea>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn type="submit" class="orange darken-3 white--text font-weight-bold">Submit</v-btn>
                            </v-card-actions>
                        </v-form>
                    </ValidationObserver>
                </v-card>

            </v-col>
        </v-row>
        <v-snackbar v-model="display_snackbar" color="primary"> {{ message }}</v-snackbar>
    </v-container>
</template>
<script>
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
export default {
    mounted() {
        // this.getMembershipPlanDetails();
    },
    data: () => ({
        membership_plan: {
            id:'',
            normal_plan:false,
            elite_plan:false,
            contactview_allowed:0,
            express_interest_allowed:0,
            personal_consulting:false,
            bonus_plan:false,
            assistedservice_profile_search:false,
            highlight_profile:false,
            personal_consulting_duration_hours:1,
            max_duration_months:1,
            features:"",
            title:"",
            price:"",
            is_active:false,
        },
        message:"",
        display_snackbar:false,
        plan_loading: false,
        update_loading: false,
    }),
    methods: {
      
        addMembershipPlan: function () {
            this.update_loading = true;
            axios.post(`${process.env.VUE_APP_API_URL}/addmembershipplan` , this.membership_plan).then((response) => {
                if (response.status == 200) {
                    this.message = "Plan Added Sucessfully";
                    this.display_snackbar = true;
                    this.$router.push({ name:'membershipplans'});

                }
            }).catch((error) => {
                if (error.response) {

                    if (error.response.status == 422) {
                        this.$refs.editmembership.setErrors({
                            'title': error.response.data.errors.title,
                            'Price': error.response.data.errors.price,
                            'Max Durations In Months': error.response.data.errors.max_duration_months,
                            'Elite Plan': error.response.data.errors.elite_plan,
                            'Bonus Plan': error.response.data.errors.bonus_plan,
                            'Contact View Allowed': error.response.data.errors.contactview_allowed,
                            'Express Interest Allowed': error.response.data.errors.express_interest_allowed,
                            'Highlight Profile': error.response.data.errors.highlight_profile,
                            'Top Place On Search': error.response.data.errors.top_place_on_search,
                            'Assisted Profile Search': error.response.data.errors.assistedservice_profile_search,
                            'Personal Consulting': error.response.data.errors.personal_consulting,
                            'Perosnal Consulting Duration Hours': error.response.data.errors.personal_consulting_duration_hours,
                            'features':error.response.data.errors.features,
                            'Activate This Plan':error.response.data.error.is_active,
                        });
                    }
                }
            }).finally(() => {
                this.update_loading = false;
            });
        }
    },
    components: { CustomAppbar, CustomSidebar, ValidationObserver, ValidationProvider }
}

</script>