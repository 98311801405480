<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>
    <v-row>
      <v-col lg="12">
        <H2>SMS Templates</H2>
      </v-col>
      <!-- <v-col lg="7">
        <v-card rounded elevation="1">
          <v-card-title>
            SMS Statistics
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col lg="4">
                <v-list-item>
                  <v-list-avatar>
                    <v-avatar color="red darken-5" size="42" class="elevation-3 me-3 rounded" tile>
                      <v-icon dark>
                        mdi-chart-timeline-variant-shimmer
                      </v-icon>
                    </v-avatar>
                  </v-list-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      Total SMS Templates
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-h6">
                      245K
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col lg="4">
                <v-list-item>
                  <v-list-avatar>
                    <v-avatar color="red darken-5" size="42" class="elevation-3 ma-3 rounded" tile>
                      <v-icon dark>
                        mdi-chart-timeline-variant-shimmer
                      </v-icon>
                    </v-avatar>
                  </v-list-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      Total SMS Sends
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-h6">
                      24K
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col lg="4">
                <v-list-item>
                  <v-list-avatar>
                    <v-avatar color="red darken-5" size="42" class="elevation-3 ma-3 rounded" tile>
                      <v-icon dark>
                        mdi-chart-timeline-variant-shimmer
                      </v-icon>
                    </v-avatar>
                  </v-list-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="text-caption">
                      Active SMS Templates
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-h6">
                      245K
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col lg="12">
        <v-row justify="end">

          <!-- new template dialog-->
          <v-dialog transition="dialog-bottom-transition" max-width="400" max-height="400">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="red darken-5" class="white--text ma-2" v-bind="attrs" v-on="on">
                <v-icon left dark>
                  mdi-account-plus
                </v-icon>
                Add New Template
              </v-btn>
            </template>
            <template v-slot:default="dialog">
              <v-card width="400">
                <v-toolbar color="red darken-5" dark>New Sms Template</v-toolbar>
                <v-card-text>
                  <ValidationObserver v-slot="{ handleSubmit }" ref="newtemplateform">
                    <v-form @submit.prevent="handleSubmit(addNewTemplate)" ref="newtemplateform">
                      <v-row class="pa-10">

                        <v-col cols="12" lg="12" md="12">
                          <ValidationProvider name="Subject" rules="required|max:100" v-slot="{ errors }">
                            <v-text-field name="Subject" solo v-model="sms_template.subject" label="Subject"
                              :error-messages="errors"></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col lg="12" md="12" cols="12" class="mt-0">
                          <ValidationProvider name="template" rules="required|max:100" v-slot="{ errors }">
                            <v-textarea v-model="sms_template.template_content" counter="100" label="template"
                              :error-messages="errors" solo></v-textarea>
                          </ValidationProvider>
                          <v-btn type="submit" class="orange darken-3 white--text font-weight-bold mt-10"
                            :disabled="loader">Submit</v-btn>
                        </v-col>

                      </v-row>
                    </v-form>
                  </ValidationObserver>
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn text @click="dialog.value = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>

         
        </v-row>
      </v-col>
      <v-col lg="12">
        <v-card>
          <v-data-table :headers="template_headers" :items="templates">
            <template v-slot:[`item.template_content`]="{ item }">
              <span v-html="item.template_content"></span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <!-- update template dialog-->
              <v-dialog transition="dialog-bottom-transition" max-width="400" max-height="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn fab text class="white--text ma-2" v-bind="attrs" v-on="on" @click="updatesmsdata(item)">
                    <v-icon medium color="primary" class="">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <template v-slot:default="dialog">
                  <v-card width="400">
                    <v-toolbar color="red darken-5" dark>Update Sms Template</v-toolbar>
                    <v-card-text>
                      <ValidationObserver v-slot="{ handleSubmit }" ref="updatetemplateform">
                        <v-form @submit.prevent="handleSubmit(updateSmsTemplate)" ref="updatetemplateform">
                          <v-row class="pa-10">

                            <v-col cols="12" lg="12" md="12">
                              <ValidationProvider name="Subject" rules="required|max:100" v-slot="{ errors }">
                                <v-text-field name="Subject" solo v-model="edited_template.subject" label="Subject"
                                  :error-messages="errors"></v-text-field>
                              </ValidationProvider>
                            </v-col>
                            <v-col lg="12" md="12" cols="12" class="mt-0">
                              <ValidationProvider name="template" rules="required|max:100" v-slot="{ errors }">
                                <v-textarea v-model="edited_template.template_content" counter="100" label="template"
                                  :error-messages="errors" solo></v-textarea>
                              </ValidationProvider>
                              <v-btn type="submit" class="orange darken-3 white--text font-weight-bold mt-10"
                                :disabled="loader">Submit</v-btn>
                            </v-col>

                          </v-row>
                        </v-form>
                      </ValidationObserver>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn text @click="dialog.value = false">Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" icon fab v-on="on" @click="sendMessageTemplateToMembers(item.id)"
                    :loading="loader" :disabled="loader">
                    <v-icon color="primary" dark>
                      mdi-message-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>Send Message To All Members</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="display_snackbar" color="primary"> {{ message }}</v-snackbar>
  </v-container>
</template>
  
<script>
/* eslint-disable */
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';


export default {
  components: {
    CustomAppbar,
    CustomSidebar,
    ValidationObserver,
    ValidationProvider
  },
  mounted() {
    this.getallsmstemplates();
  },

  methods: {
    getallsmstemplates: function () {


      axios.get(`${process.env.VUE_APP_API_URL}/getallsmstemplates`).then((response) => {
        if (response.status == 200) {
          this.templates = response.data.templates;
        }
      }).catch((error) => {
        console.log(error);
        this.message = "Error Occur While getting Template";
        this.display_snackbar = true

      }).finally(() => {

      });
    },
    sendMessageTemplateToMembers: function (template_id) {
      this.loader = true;
      axios.post(`${process.env.VUE_APP_API_URL}/sendsmstemplatetomembers/` + template_id).then((response) => {
        if (response.status == 200) {
          this.message = "SMS Template Has been added to queue and will deliver shortly";
          this.display_snackbar = true;

        }
      }).catch((error) => {
        console.log(error);
        this.message = "Error Occur While Sending SMS";
        this.display_snackbar = true;
      }).finally(() => {
        this.loader = false;
      })
    },
    addNewTemplate: function () {
      this.loader = true;
      axios.post(`${process.env.VUE_APP_API_URL}/addnewsmstemplate`, this.sms_template).then((response) => {
        if (response.status == 200) {
          this.message = "Template Added Sucessfully";
          this.display_snackbar = true;
        }
      }).catch((error) => {
        if (error.response.status == 422) {
          this.$refs.newtemplateform.setErrors({
            'template': error.response.data.errors.title

          });
          this.message = "Error Occur While Saving Template"
          this.display_snackbar = true
        }
      }).finally(() => {
        this.loader = false;
      });
    },
    updateSmsTemplate: function () {
            axios.post(`${process.env.VUE_APP_API_URL}/updatesmstemplate`, this.email_template).then((response) => {
                if (response.status == 200) {
                    this.message = "Template Updated Sucessfully";
                    this.display_snackbar = true;
                }
            }).catch((error) => {
                if (error.response.status == 422) {
                    this.$refs.updatetemplateform.setErrors({
                        'template': error.response.data.errors.title

                    });
                    this.message = "Error Occur While Saving Template"
                    this.display_snackbar = true
                }
            }).finally(() => {

            });
        },
        updatesmsdata:function(template){
          this.edited_template = template;
        }
  },

  data: () => ({
    template_headers: [
      {
        text: 'Template Name',
        align: 'start',
        filterable: true,
        value: 'subject'
      },
      {
        text: 'Message',
        align: 'start',
        filterable: true,
        value: 'template_content'
      },
      {
        text: 'Actions',
        align: 'start',
        filterable: false,
        value: 'actions'
      }
    ],
    sms_template: {
      template_content: '',
      subject: '',
      id:'',
    },
    templates: [],
    loader: false,
    message: "",
    display_snackbar: false,
    edited_template:{
      template_content:'',
      subject:'',
      id:'',
    }
  }),
}
</script>

 
