<template>
  <v-container fluid>
    <CustomSidebar></CustomSidebar>
    <CustomAppbar></CustomAppbar>

    <v-row>
      <v-col lg="12">
        <H2>Email Templates</H2>
      </v-col>
      <!-- <v-col lg="7">
          <v-card rounded elevation="1">
            <v-card-title>
              Email Statistics
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col lg="4">
                  <v-list-item>
                    <v-list-avatar>
                      <v-avatar color="red darken-5" size="42" class="elevation-3 me-3 rounded" tile>
                        <v-icon dark>
                          mdi-chart-timeline-variant-shimmer
                        </v-icon>
                      </v-avatar>
                    </v-list-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-caption">
                        Total Email Templates
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-h6">
                        245K
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col lg="4">
                  <v-list-item>
                    <v-list-avatar>
                      <v-avatar color="red darken-5" size="42" class="elevation-3 ma-3 rounded" tile>
                        <v-icon dark>
                          mdi-chart-timeline-variant-shimmer
                        </v-icon>
                      </v-avatar>
                    </v-list-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-caption">
                        Total Mail Sends
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-h6">
                        24K
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col lg="4">
                  <v-list-item>
                    <v-list-avatar>
                      <v-avatar color="red darken-5" size="42" class="elevation-3 ma-3 rounded" tile>
                        <v-icon dark>
                          mdi-chart-timeline-variant-shimmer
                        </v-icon>
                      </v-avatar>
                    </v-list-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-caption">
                        Active Email Templates
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-h6">
                        245K
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col> -->
      <v-col lg="12">
        <v-row justify="end">
          <v-btn color="red darken-5" class="white--text ma-2" href="/newemailtemplate">
            <v-icon left dark>
              mdi-email-outline
            </v-icon>
            Add New Template
          </v-btn>

        </v-row>
      </v-col>
      <v-col lg="12">
        <v-card>
          <v-data-table :headers="template_headers" :items="templates">
            <template v-slot:[`item.template_content`]="{ item }">
              <span v-html="item.template_content"></span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn link :href="'/editemailtemplate/' + item.id" text icon>
                <v-icon medium color="primary" class="">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" icon fab v-on="on" @click="sendEmailTemplateToUsers(item.id)" :loading="loader"
                    :disabled="loader">
                    <v-icon color="primary" dark>
                      mdi-mail
                    </v-icon>
                  </v-btn>
                </template>
                <span>Send Mail To All Members</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="display_snackbar" color="primary"> {{ message }}</v-snackbar>
  </v-container>
</template>
    
<script>
import CustomAppbar from '@/layout/CustomAppbar.vue';
import CustomSidebar from '@/layout/CustomSidebar.vue';
import axios from 'axios';
export default {
  mounted() {
    this.getallemailtemplates();
  },
  methods: {
    getallemailtemplates: function () {


      axios.get(`${process.env.VUE_APP_API_URL}/getallemailtemplates`).then((response) => {
        if (response.status == 200) {
          this.templates = response.data.templates;
        }
      }).catch((error) => {
        console.log(error);
        this.message = "Error Occur While getting Template";
        this.display_snackbar = true

      }).finally(() => {

      });
    },
    sendEmailTemplateToUsers: function (template_id) {
      this.loader = true;
      axios.post(`${process.env.VUE_APP_API_URL}/sendemailtomembers/` + template_id).then((response) => {
        if (response.status == 200) {
          this.message = "Email Template Has been added to queue and will deliver shortly";
          this.display_snackbar = true;

        }
      }).catch((error) => {
        console.log(error);
        this.message = "Error Occur While Sending Email Template";
        this.display_snackbar = true;
      }).finally(() => {
        this.loader = false;
      })
    }
  },
  data: () => ({
    template_headers: [

      {
        text: "Template Name",
        align: "start",
        filterable: true,
        value: "subject"
      },
      {
        text: "Template Name",
        align: "start",
        filterable: true,
        value: "template_content"
      },
      {
        text: "Created Date",
        align: "start",
        filterable: true,
        value: "created_at"
      },

      {
        text: "Actions",
        align: "start",
        filterable: false,
        value: "actions"
      }
    ],
    templates: [],
    loader: false,
    message: "",
    display_snackbar: false,
  }),
  components: { CustomSidebar, CustomAppbar }
}

</script>
  
   
  